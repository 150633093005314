import { LinkableCard, LinkableItem, LinkableObjective } from "@/model/link";
import { useUserStore } from "@/store/user";

/**
 Links should be sorted alphabetically by text, but
 prioritizing the following order:
 1. ART work items
 - items which belong to the user team
 2. Dependencies
 - user team is a dependent team
 - user team is a precond team
 3. Team Work Items
 4. Objectives
 - Committed
 - Uncommitted
 5. All Others
 */
export function sortLinkableItems(links: LinkableItem[]): LinkableItem[] {
  const artWorkItems = new Array<LinkableCard>();
  const dependencies = new Array<LinkableCard>();
  const teamWorkItems = new Array<LinkableCard>();
  const objectivesCommitted = new Array<LinkableObjective>();
  const objectivesUncommitted = new Array<LinkableObjective>();
  const others = new Array<LinkableCard>();

  links.forEach((link) => {
    switch (link.kind) {
      case "sticky":
        // ART work item
        if (isARTWorkItem(link)) {
          artWorkItems.push(link);
        }
        // Dependency
        else if (isDependency(link)) {
          dependencies.push(link);
        }
        // Team work item
        else if (isTeamWorkItem(link)) {
          teamWorkItems.push(link);
        }
        // Others
        else if (isOtherItem(link)) {
          others.push(link);
        }
        break;
      case "objective":
        if (link.type === "committed") {
          objectivesCommitted.push(link);
        } else {
          objectivesUncommitted.push(link);
        }
        break;
    }
  });

  // Sort each classification alphabetically by text
  const sortAlphabetically = (a: LinkableItem, b: LinkableItem) =>
    a.text.localeCompare(b.text);

  artWorkItems.sort(sortAlphabetically);
  dependencies.sort(sortAlphabetically);
  teamWorkItems.sort(sortAlphabetically);
  objectivesCommitted.sort(sortAlphabetically);
  objectivesUncommitted.sort(sortAlphabetically);
  others.sort(sortAlphabetically);

  // Further prioritize within the classified groups
  const isUserTeam = (teamId: string) =>
    useUserStore().technicalUser.teams.some(
      (userTeam) => userTeam.id === teamId,
    );

  const userTeamArtWorkItems = artWorkItems.filter(
    (link) => link.team && isUserTeam(link.team.id),
  );
  const restArtWorkItems = artWorkItems.filter(
    (link) => !link.team || !isUserTeam(link.team.id),
  );

  const userDependTeamDependencies = dependencies.filter(
    (link) => link.dependTeam && isUserTeam(link.dependTeam.id),
  );
  const userPrecondTeamDependencies = dependencies.filter(
    (link) => link.precondTeam && isUserTeam(link.precondTeam.id),
  );
  const restDependencies = dependencies.filter(
    (link) =>
      (!link.dependTeam || !isUserTeam(link.dependTeam.id)) &&
      (!link.precondTeam || !isUserTeam(link.precondTeam.id)),
  );

  return [
    ...userTeamArtWorkItems,
    ...restArtWorkItems,
    ...userDependTeamDependencies,
    ...userPrecondTeamDependencies,
    ...restDependencies,
    ...teamWorkItems,
    ...objectivesCommitted,
    ...objectivesUncommitted,
    ...others,
  ];
}

export function isSticky(link: LinkableItem): link is LinkableCard {
  return link.kind === "sticky";
}

export function isARTWorkItem(link: LinkableItem): link is LinkableCard {
  return (
    link.kind === "sticky" &&
    ["backlog", "program", "risk"].includes(link.type.origin) &&
    !isDependency(link)
  );
}

export function isDependency(link: LinkableItem): link is LinkableCard {
  return link.kind === "sticky" && link.type.functionality === "dependency";
}

export function isTeamWorkItem(link: LinkableItem): link is LinkableCard {
  return (
    link.kind === "sticky" && link.type.origin === "team" && !isDependency(link)
  );
}

export function isOtherItem(link: LinkableItem): link is LinkableCard {
  return (
    link.kind === "sticky" &&
    !isARTWorkItem(link) &&
    !isDependency(link) &&
    !isTeamWorkItem(link)
  );
}

export function isObjective(link: LinkableItem): link is LinkableObjective {
  return link.kind === "objective";
}
