<script setup lang="ts">
import { computed, ref } from "vue";

import { useTooltip } from "@/composables/useTooltip";

const props = defineProps<{ text: string }>();

const triggerRef = ref<HTMLElement>();
const tooltip = computed(() => props.text);

useTooltip({ el: triggerRef, tooltip });
</script>

<template>
  <div ref="triggerRef" class="sticky-note-type-text">
    {{ props.text }}
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/mixins/utils";

.sticky-note-type-text {
  @include utils.ellipsis;

  margin-right: auto;
}
</style>
