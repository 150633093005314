/**
 * Values of WheelEvent in different environments.
 * min: a faster movement results in a higher initial value
 * dyn: a faster movement results in higher non-initial values
 *
 *                               delta wheelDelta      delta divisor  result
 * TrackPad       Chrome MacOs     1        3 (dyn)          1
 * TrackPad pinch Chrome MacOs     0.1..  120                1
 * TrackPad       FF     MacOs     1        3 (dyn)          1
 * TrackPad pinch FF     MacOs     0.1..    0 (min)          1
 * Wheel Mouse    Chrome MacOs     4...   120 (dyn)          1           4.. (a little big, but is anybody using this?)
 * Mac Mouse      Chrome MacOs     1        3 (dyn)          1
 * Wheel Mouse    FF     MacOs    16       48 (dyn)          1          16 (ok because less dyn)
 * Mac Mouse      FF     MacOs     1        3 (dyn)          1
 * TrackPad       Chrome Win       1        1 (min)          1
 * TrackPad pinch Chrome Win       0.1..  120                1
 * TrackPad       Edge   Win       1        1 (min)          1
 * TrackPad pinch Edge   Win       0.1..  120                1
 * TrackPad       FF     Win       0.4..    1 (min)          1
 * TrackPad pinch FF     Win       0.1..    0 (min)          1
 * Mouse          Chrome Win     100      120                3          33.33 (because not dyn)
 * Mouse          Edge   Win     250      300                7.5        33.33 (because not dyn)
 * Mouse          FF     Win     108      120                3          36    (because not dyn)
 *
 */

// events following each other in less than this time belong to the same wheel gesture
const EVENT_TIMEOUT = 150;
// delta values for mouses on windows have at least this value
const WIN_MOUSE_DELTA = 100;
const BIG_WHEEL_DELTA = 120;
const WIN_MOUSE_DIVISOR = 40;

let lastTime = 0;
let lastFactor = 1;

export function normalizeWheelEvent(e: WheelEvent): {
  x: number;
  y: number;
} {
  const time = Date.now();
  const factor = time - lastTime < EVENT_TIMEOUT ? lastFactor : calcFactor();
  lastTime = time;
  lastFactor = factor;
  return { x: e.deltaX / factor, y: e.deltaY / factor };

  function calcFactor() {
    if (!("wheelDelta" in e)) {
      return 1;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const wheelDelta = Math.abs((e as any).wheelDelta);
    const integerDelta =
      Number.isInteger(e.deltaX) && Number.isInteger(e.deltaY);
    const maxDelta = Math.max(Math.abs(e.deltaX), Math.abs(e.deltaY));
    // mouse on win
    if (
      wheelDelta >= BIG_WHEEL_DELTA &&
      integerDelta &&
      maxDelta >= WIN_MOUSE_DELTA
    ) {
      return wheelDelta / WIN_MOUSE_DIVISOR;
    }
    return 1;
  }
}
