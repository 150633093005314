import { captureException } from "@sentry/vue";
import { isArray } from "lodash-es";

interface SerDes<T> {
  serialize?: (value: T) => unknown;
  deserialize?: (data: unknown) => T | undefined;
}

export class LocalStore<T> {
  saveTimeout = -1;
  saveDelay: number;
  serialize: (value: T) => unknown;
  deserialize: (data: unknown) => T | undefined;

  constructor(
    private name: string,
    options: { sedes?: SerDes<T>; saveDelay?: number } = {},
  ) {
    this.saveDelay = options.saveDelay ?? 0;
    this.serialize = options.sedes?.serialize ?? ((value: T) => value);
    this.deserialize =
      options.sedes?.deserialize ?? ((data: unknown) => data as T); // unsafe cast
  }

  load(): T | undefined {
    const raw = localStorage.getItem(this.name);
    if (raw === null) {
      return this.deserialize(undefined);
    }
    try {
      return this.deserialize(JSON.parse(raw));
    } catch (e) {
      captureException(e);
      return this.deserialize(undefined);
    }
  }

  save(value: T) {
    if (!this.saveDelay) {
      this.doSave(value);
    } else {
      window.clearTimeout(this.saveTimeout);
      this.saveTimeout = window.setTimeout(
        () => this.doSave(value),
        this.saveDelay,
      );
    }
  }

  doSave(value: T) {
    localStorage.setItem(this.name, JSON.stringify(this.serialize(value)));
  }
}

export function versioned<T>(version: number): SerDes<T> {
  return {
    serialize: (value: T) => [version, value],
    deserialize: (data: unknown) =>
      isArray(data) && data.length === 2 && data[0] === version
        ? data[1]
        : undefined,
  };
}
