<script setup lang="ts">
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import { Color, StatusClass } from "@/model/baseTypes";

export interface StatusClassItem {
  id: StatusClass;
  name: string;
  color: Color | string;
}

const props = defineProps<{
  modelValue?: StatusClassItem | StatusClassItem[];
  statusClasses: StatusClassItem[];
}>();

const emit = defineEmits<{
  "update:model-value": [value: StatusClassItem | StatusClassItem[]];
}>();
</script>

<template>
  <SelectableList
    v-keyboard-nav
    :header="$t('general.statusClasses')"
    :model-value="props.modelValue"
    :values="props.statusClasses"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #default="{ value }">
      <span>
        <TypeDot :color="value.color" />
        {{ $t(value.name) }}
      </span>
    </template>
  </SelectableList>
</template>
