import { Ref, ref, watch } from "vue";

interface Hooks {
  onEnd?: VoidFunction;
  onStart?: VoidFunction;
}

/**
 * Creates an transition utility for a given element.
 *
 * @param el - The element to transition.
 * @returns An object containing the `transition` and `transitionOn` functions.
 */
export function useTransition(el: Ref<HTMLElement | undefined>) {
  const classes = ref<Record<string, true>>({});

  async function transition(cssClass: string, hooks: Hooks = {}) {
    classes.value[cssClass] = true;

    const handleTransitionEnd = () => {
      hooks.onEnd?.();
      delete classes.value[cssClass];
    };
    const handleTransitionStart = () => {
      hooks.onStart?.();
    };

    await new Promise<void>((resolve) => {
      el.value?.addEventListener(
        "transitionend",
        () => {
          handleTransitionEnd();
          resolve();
        },
        {
          once: true,
        },
      );
      el.value?.addEventListener("transitionstart", handleTransitionStart, {
        once: true,
      });
    });
  }

  function transitionOn(value: Ref, cssClass: string, hooks: Hooks = {}) {
    watch(value, () => transition(cssClass, hooks));
  }

  return { transition, transitionOn, classes };
}
