<script setup lang="ts">
import { ref } from "vue";

import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { LabelAlign, Shape } from "@/model/shape";
import { injectStrict } from "@/utils/vue";

import { shapeKey } from "../injectKeys";

const shape = injectStrict(shapeKey);
const dropdown = injectStrict(dropdownKey);

const emit = defineEmits<{ update: [label: Shape["label"]] }>();

const label = ref(
  shape.value!.label ?? { text: "", align: "start" as LabelAlign },
);

function setAlign(align: LabelAlign) {
  label.value.align = align;
  emit("update", label.value);
}

function setText() {
  emit("update", label.value);
  dropdown.close();
}
</script>

<template>
  <div class="shape-label">
    <div class="style">
      <IconButton
        icon="align/left"
        :activated="label.align === 'start'"
        @click="setAlign('start')"
      />
      <IconButton
        icon="align/center"
        :activated="label.align === 'center'"
        @click="setAlign('center')"
      />
      <IconButton
        icon="align/right"
        :activated="label.align === 'end'"
        @click="setAlign('end')"
      />
    </div>
    <BaseInput
      v-model="label.text"
      v-autofocus
      control-type="text"
      @keydown.enter.prevent="setText"
      @blur="setText"
    />
  </div>
</template>

<style lang="scss" scoped>
.shape-label {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 0.5em;
  width: 250px;

  .style {
    display: flex;
    justify-content: center;
  }
}
</style>
