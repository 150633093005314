import { defineStore } from "pinia";
import { ref, watch } from "vue";

import { Art, Team } from "@/model/session";
import { LocalStore, versioned } from "@/utils/LocalStore";

import { useSessionStore } from "./session";

type SessionId = string;
type ArtId = string;
type TeamId = string;
type State = Record<
  SessionId,
  { artId?: ArtId; teamInArt: Record<ArtId, TeamId> }
>;

export const useNavigationStore = defineStore("navigation", () => {
  const store = new LocalStore<State>("navigation", { sedes: versioned(1) });
  const state = ref(store.load() ?? {});

  watch(state, () => store.save(state.value), { deep: true });

  function lastSelectedArtId() {
    const session = sessionState();
    return session.artId;
  }

  function lastSelectedTeamId(artId: string) {
    const session = sessionState();
    return session.teamInArt[artId];
  }

  function artSelected(art: Art) {
    const session = sessionState();
    session.artId = art.id;
  }

  function teamSelected(team: Team) {
    const session = sessionState();
    session.teamInArt[team.artId || ""] = team.id;
  }

  function sessionState() {
    const sessionId = useSessionStore().session.current.id;
    if (!(sessionId in state.value)) {
      state.value[sessionId] = { teamInArt: {} };
    }
    return state.value[sessionId];
  }

  return { lastSelectedArtId, lastSelectedTeamId, artSelected, teamSelected };
});
