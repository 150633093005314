import { Ref, onMounted } from "vue";

import { useNativeEvents } from "@/composables/useNativeEvents";
import {
  WindowCoordinate,
  clientCoord,
  windowCoord,
} from "@/model/coordinates";

const pointerPos: {
  down: WindowCoordinate | null;
  current: WindowCoordinate;
} = {
  down: null,
  current: windowCoord(0, 0),
};

export function usePointerPosition(elem: Ref<HTMLElement | undefined>) {
  const { addEventListener } = useNativeEvents();
  onMounted(() => {
    addEventListener(elem.value, "pointerdown", down);
    addEventListener(elem.value, "pointermove", move);
    addEventListener(elem.value, "pointerup", up);
    addEventListener(elem.value, "pointerout", up);
  });

  function down(e: PointerEvent) {
    pointerPos.down = clientCoord(e);
  }

  function move(e: PointerEvent) {
    pointerPos.current = clientCoord(e);
  }

  function up() {
    pointerPos.down = null;
  }

  return {
    pointerPos,
  };
}
