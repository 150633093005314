import TextMeasurer, { wordDownsize } from "./TextMeasurer";
import {
  FontData,
  RawFontData,
  TextFontData,
  fontSizeCache,
} from "./fontSizeCache";
import { replaceLinks, toHtmlWithLinks } from "./linkParser";

const maxSteps = 7;

export async function optimalFontSize<EDIT extends boolean>(
  el: HTMLElement,
  inputText: string,
  font: string,
  edit: EDIT,
  options: {
    isDependency?: boolean;
    nonStandardCardSize?: boolean; // For rendering cards off-board
  } = {},
): Promise<FontData<EDIT>> {
  let width = 224;
  let height = options.isDependency ? 132 : 160;

  // When rendering cards off-board (eg. plan readout), use the el's actual size
  // instead of the standard card size (performance is less of a concern, because
  // we're only rendering 1 card at a time)
  if (options.nonStandardCardSize) {
    width = el.clientWidth;
    height = el.clientHeight;
  }

  return fontSizeCache(inputText, width, height, edit, font, () => {
    const { text, links } = edit
      ? { text: inputText, links: [] }
      : replaceLinks(inputText);

    const measurer = TextMeasurer.of(el)!;

    const maxFontSize = measurer.maxFontSize();
    const maxFontSizeForText = measurer.maxFontSizeForText();
    const wordSize = wordDownsize(maxFontSizeForText, text);
    let max = maxFontSize * wordSize;

    let min = max / 20;
    for (let i = 0; i < maxSteps; i++) {
      const size = (min + max) / 2;
      const measure = measurer.measureLines(size, text);

      if (measure.height <= el.clientHeight) {
        min = size;
      } else {
        max = size;
      }
    }
    min = Math.round(min * 1000) / 1000;

    measurer.setFontSize(min);
    const minMeasure = measurer.measureLines(min, text);
    //textareaButNotEdit happens for the sticky that has the cursor during zoom
    //this is ok, but we should not cache the result as it's wrong after zooming has finished
    const textareaButNotEdit = !edit && el.nodeName === "TEXTAREA";
    const raw: RawFontData = { size: min, cache: !textareaButNotEdit };
    const res = edit
      ? raw
      : ({
          ...raw,
          htmlLines: toHtmlWithLinks(text, links, minMeasure.lines),
          textLines: minMeasure.lines.map(([startPos, endPos]) =>
            text.substring(startPos, endPos),
          ),
        } as TextFontData);
    return res as FontData<EDIT>;
  });
}
