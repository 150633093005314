<script lang="ts" setup>
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import { injectStrict } from "@/utils/vue";

defineProps<{ mode: "delete" | "remove" }>();
const emit = defineEmits<{ delete: [] }>();

const dropdown = injectStrict(dropdownKey);
</script>

<template>
  <div
    v-focus-trap
    class="delete-sticky"
    role="alertdialog"
    aria-labelledby="delete-sticky-title"
    aria-describedby="delete-sticky-message"
    @keydown.tab.stop
  >
    <h4 id="delete-sticky-title" class="title">
      {{
        mode === "delete"
          ? $t("action.deleteSticky")
          : $t("action.removeSticky")
      }}
    </h4>
    <p id="delete-sticky-message" class="description">
      {{
        mode === "delete"
          ? $t("action.deleteSticky.description")
          : $t("action.removeSticky.description")
      }}
    </p>
    <div class="actions">
      <BaseButton
        v-if="mode === 'delete'"
        full-width
        color="destructive"
        @click="emit('delete')"
      >
        {{ $t("general.delete") }}
      </BaseButton>
      <BaseButton v-else full-width variant="outlined" @click="emit('delete')">
        {{ $t("cardAction.remove") }}
      </BaseButton>
      <BaseButton
        v-autofocus
        full-width
        variant="outlined"
        color="grey"
        @click="dropdown.close"
      >
        {{ $t("general.cancel") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/font";

.delete-sticky {
  color: colors-old.$menu-color;
  width: 284px;
  padding: 20px;

  .actions {
    display: flex;
    gap: 12px;
    margin-top: 20px;
  }

  .title {
    all: unset;
    display: block;
    font-weight: font.$weight-extra-bold;
    font-size: 14px;
  }

  .description {
    all: unset;
    display: block;
    font-weight: font.$weight-medium;
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>
