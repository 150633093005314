<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useLinkStore } from "@/store/link";
import { stickyNoteDuplicated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const handleDuplicate = () => {
  const cardType = card.type.functionality;
  const boardType = board.value.type;
  trackEvent(stickyNoteDuplicated(cardType, boardType, "action-menu"));

  cardActions.duplicate("mouse", "action-menu-duplicate");
  useLinkStore().removeAllMarks();
};
</script>

<template>
  <ActionMenuItem
    name="duplicate"
    :tooltip-text="$t('cardAction.duplicate')"
    :aria-label="$t('label.actionMenu.duplicate')"
    @click="handleDuplicate"
  >
    <SvgIcon name="action/duplicate" width="20" height="20" />
  </ActionMenuItem>
</template>
