import { defineStore } from "pinia";

import { DataError } from "@/backend/DataError";
import { addBreadcrumb, captureException } from "@/error/sentry";
import { Id } from "@/model/baseTypes";
import {
  Art,
  Iteration,
  SelectList,
  Session,
  SessionAlmStatus,
  Team,
  emptySelectList,
} from "@/model/session";
import { goToLogout } from "@/router/navigation";
import { isAfter, isBefore, weekDaysBetween } from "@/utils/date";

import { useArtStore } from "./art";
import { useTeamStore } from "./team";

interface IterationProgress {
  daysInIteration: number;
  daysPassedInIteration: number;
  iterationsPassed: number;
}

export const useSessionStore = defineStore("session", {
  state: () => {
    return {
      isSessionLoading: false,
      session: emptySelectList<Session>(),
      userSync: "unsyncedSession" satisfies
        | "unsyncedSession"
        | "unsynced"
        | "synced",
    };
  },
  getters: {
    iterations: (state) => state.session.current.iterations || [],
    uniqueSessionName: (state) => (session?: Session) => {
      if (!session) {
        session = state.session.current;
      }
      let index = 1;
      for (const ss of state.session.list) {
        if (ss.id === session.id) {
          break;
        }
        if (ss.name === session.name) {
          index++;
        }
      }
      return (index > 1 ? `_${index}_` : "") + session.name;
    },
    findByNameOrId: (state) => (query: string) =>
      state.session.list.find(
        (session) => session.name === query || session.id === query,
      ),
    findByIndexedName: (state) => (sessionName: string) => {
      const indexed = /_(\d+)_(.+)/.exec(sessionName || "");
      if (indexed) {
        return state.session.list.filter(
          (session) => session.name === indexed[2],
        )[+indexed[1] - 1];
      }
    },
    filterArchived: (state) => (archived: boolean) =>
      archived
        ? state.session.list
        : state.session.list.filter((session) => !session.archived),
    currentlyDifferentSession() {
      return (sessionName: string) =>
        !this.session.current ||
        (sessionName !== this.uniqueSessionName() &&
          sessionName !== this.session.current.id);
    },
    iterationProgress:
      (state) =>
      (date: Date, iteration?: Iteration): IterationProgress => {
        const iters = state.session.current.iterations;
        if (isBefore(date, iters[0].start)) {
          return {
            daysInIteration: 0,
            daysPassedInIteration: 0,
            iterationsPassed: 0,
          };
        }
        if (!isBefore(date, iters[iters.length - 1].end)) {
          return {
            daysInIteration: 0,
            daysPassedInIteration: 0,
            iterationsPassed: iters.length,
          };
        }
        let i = iters.length - 1;
        while (i > 0 && isAfter(iters[i].start, date)) {
          i--;
        }

        if (!iteration) {
          iteration = iters[i];
        } else {
          if (iteration.id !== iters[i].id) {
            date = isBefore(date, iteration.start)
              ? iteration.start
              : iteration.end;
          }
          i = 0;
        }

        const daysInIteration = weekDaysBetween(iteration.start, iteration.end);
        const daysPassedInIteration = Math.min(
          daysInIteration,
          weekDaysBetween(iteration.start, date),
        );

        return {
          daysInIteration,
          daysPassedInIteration,
          iterationsPassed: i + daysPassedInIteration / daysInIteration,
        };
      },
    /**
     * Get the name for the given iteration, by id
     *
     * @param id iteration number (0-based index)
     * @returns iteration name
     */
    getIterationName:
      (state) =>
      (id?: number | null): string => {
        if (id === null || id === undefined) return "";
        return (
          state.session.current.iterations.find((i) => i.id === id)?.name || ""
        );
      },
    getIterationById:
      (state) =>
      (id?: number | null): Iteration | undefined => {
        if (id === null || id === undefined) return undefined;
        return (
          state.session.current.iterations.find((i) => i.id === id) || undefined
        );
      },
    currentIteration: (state) => {
      const iterations = state.session.current.iterations;
      if (isBefore(new Date(), iterations[0].start)) {
        return iterations[0];
      }
      if (isAfter(new Date(), iterations[iterations.length - 1].end)) {
        return iterations[1];
      }
      return iterations.find(
        (iter) =>
          isAfter(new Date(), iter.start) && isBefore(new Date(), iter.end),
      )!;
    },
  },
  actions: {
    setSessions(sessions: Session[]) {
      const select: SelectList<Session> = {
        list: sessions,
        current: emptySelectList<Session>().current,
        selected: null,
      };
      // if we are in a session, try to find it in the list
      // set current/selected if session is found or logout if not
      if (this.session.current.id) {
        const currentSession = sessions.find(
          (session) => session.id === this.session.current.id,
        );
        if (!currentSession) {
          void goToLogout();
        } else {
          select.current = select.selected = currentSession;
        }
      }
      this.session = select;
    },
    selectSession(e: Session) {
      this.session.current = emptySelectList<Session>().current;
      this.session.selected = e;
      useArtStore().art.current = emptySelectList<Art>().current;
      useArtStore().art.selected = null;
      useTeamStore().team.current = emptySelectList<Team>().current;
      useTeamStore().team.selected = null;
    },
    setSessionAlmStatus(status: SessionAlmStatus) {
      this.session.current.almStatus = status;
    },
    setSession({ id: sessionId }: Id, load: Promise<void>) {
      void addBreadcrumb("store", {
        message: `Setting current and selected session to ${sessionId}`,
        data: { sessionId },
      });
      this.session.selected =
        this.session.list.find((session) => session.id === sessionId) || null;
      if (!this.session.selected) {
        const sessionIds = this.session.list.map(({ id }) => id);
        void captureException(
          new DataError(
            "Could not find the Session ID in the list of active sessions",
            { sessionId, availableSessions: sessionIds },
          ),
        );
      }
      this.session.current =
        this.session.selected || emptySelectList<Session>().current;
      this.isSessionLoading = true;
      void load.finally(() => {
        this.isSessionLoading = false;
      });
    },
  },
});
