<script setup lang="ts">
import { ref } from "vue";
import { TippyOptions } from "vue-tippy";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { Icon } from "@/model/icon";

const props = defineProps<{
  icon: Icon;
  keyOverride?: string;
  tooltip?: string;
  shortcut?: string;
  position?: TippyOptions["placement"];
  ariaLabel?: string;
  offset?: TippyOptions["offset"];
}>();

defineOptions({ inheritAttrs: false });

// Allow parent to focus the button and call getBoundingClientRect
const buttonRef = ref<HTMLElement | null>(null);
const focus = () => buttonRef.value?.focus();
const blur = () => buttonRef.value?.blur();
const getBoundingClientRect = () => buttonRef.value?.getBoundingClientRect();
defineExpose({ blur, focus, getBoundingClientRect });
</script>

<template>
  <BaseTooltip
    :key="keyOverride ?? tooltip"
    :position="position"
    :offset="offset"
  >
    <template v-if="$slots.tooltip" #content>
      <slot name="tooltip" />
    </template>
    <template v-else-if="tooltip" #content>
      {{ tooltip }}
      <span class="shortcut">{{ shortcut }}</span>
    </template>
    <BaseButton
      ref="buttonRef"
      variant="ghost"
      color="grey"
      v-bind="$attrs"
      :icon-before="props.icon"
      :aria-label="props.ariaLabel || tooltip"
    />
  </BaseTooltip>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.shortcut {
  color: colors-old.$shortcut-color;
}
</style>
