import BoardBase from "@/components/board/BoardBase";
import { applyFluidBoardSize } from "@/components/utils/boardSize";
import { calcBoardSize } from "@/math/coordinate-systems";
import { pushZoomState } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useZoomStore } from "@/store/zoom";
import { ElementState, hideElements, showElements } from "@/utils/dom/dom";
import {
  disconnectObservers,
  reconnectObservers,
} from "@/utils/dom/mutationObserver";
import { nextTicks } from "@/utils/vue";

import { ZoomLayer } from "./ZoomLayer";

// for a small number of stickies, it's faster to NOT use the zoom layer
const useZoomLayerStickyNumber = 100;

export function zoomHandler(
  zoomLayer: ZoomLayer,
  currentBoard: () => BoardBase,
) {
  let zoomFactor = 0;
  let cardNodes: ElementState | null = null;
  let checkZoomTimeout = 0;
  const store = useZoomStore();

  return { startZoom, endZoom };

  async function startZoom() {
    if (useBoardStore().isCurrentBoardFluid) {
      disconnectObservers();
      zoomFactor = store.factor;
      const useLayer =
        Object.keys(useBoardStore().currentBoard().cards).length >
        useZoomLayerStickyNumber;
      if ((store.useZoomLayer = useLayer)) {
        await initZoomLayer();
      }
      checkZoom();
    }
  }

  function endZoom() {
    if (useBoardStore().isCurrentBoardFluid) {
      // Make sure the latest zoom was registered, but do not trigger recursive checks
      checkZoom(true);
      store.updateZoomFactor();
      pushZoomState();
      if (store.useZoomLayer) {
        hideZoomLayer();
      }
      window.cancelAnimationFrame(checkZoomTimeout);
      // 3 is found by trial: check that the last tick takes (nearly) no time
      nextTicks(3, reconnectObservers);
    }
  }

  /**
   * Checks if the zoom factor has changed and updates the board size accordingly
   * By default, called recursively with requestAnimationFrame
   *
   * @param stop if true, stops the recursive call (stop is a number when triggered through requestAnimationFrame)
   */
  function checkZoom(stop?: boolean | number) {
    if (zoomFactor !== store.dynamicFactor) {
      zoomFactor = store.dynamicFactor;
      const size = calcBoardSize(zoomFactor);
      applyFluidBoardSize(size);
      store.scrollToCenter(size);
    }

    if (stop !== true) {
      checkZoomTimeout = window.requestAnimationFrame(checkZoom);
    }
  }

  async function initZoomLayer() {
    await zoomLayer.paintCards(currentBoard(), useBoardStore().currentBoard());
    // removing and adding cards directly using DOM (.1ms, .75ms) is around 2x faster that using vue.js (.2ms, 1.5ms)
    cardNodes = hideElements(document.querySelectorAll(".card,.sticky-note"));
  }

  function hideZoomLayer() {
    if (cardNodes) {
      showElements(cardNodes);
      cardNodes = null;
    }
    zoomLayer.hide();
  }
}
