<template>
  <canvas
    id="zoom-canvas"
    ref="canvas"
    width="9600"
    height="5400"
    aria-hidden="true"
    tabindex="-1"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Ref } from "vue-property-decorator";

import BoardBase from "@/components/board/BoardBase";
import { fluidBoard } from "@/components/board/FluidBoard";
import { fakeZoom } from "@/model/Settings";
import { Color } from "@/model/baseTypes";
import { Board } from "@/model/board";
import color from "@/model/color.module.scss";
import { isFaded } from "@/model/markMode";
import { Size } from "@/model/size";
import { isDependency } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { cardColor } from "@/store/card";
import {
  StickyNoteTextAlignment,
  useClientSettingsStore,
} from "@/store/clientSettings";
import { useZoomStore } from "@/store/zoom";
import { contrastCssColor, toCssColor } from "@/utils/color";
import { optimalFontSize } from "@/utils/text/fontSizeOptimizer";

import { ZoomLayer } from "./ZoomLayer";

interface Font {
  factor: number;
  family: string;
  weight: string;
  lineHeight: number;
}

// TODO: write a vite plugin to import this from the css custom properties file
// https://rentouch.atlassian.net/browse/REN-12514

// black-alpha-20 - hardcoding since the css custom properties are not available in the canvas
const shadowColor = "rgba(0, 0, 0, 0.2)";
const borderColor = "#3234e4";
// Where to draw text in the card, based on user-chosen text alignment
const alignmentFactor = {
  left: 0.037,
  center: 0.5,
  right: 0.963,
};

@Component({})
export default class DefaultZoomLayer extends Vue implements ZoomLayer {
  @Ref("canvas") readonly canvasElem!: HTMLCanvasElement;
  ctx!: CanvasRenderingContext2D;
  textAlignment: StickyNoteTextAlignment = "left";

  mounted() {
    this.ctx = this.canvasElem.getContext("2d")!;
  }

  // paint a simplified version of a board's stickies
  async paintCards(component: BoardBase, board: Board) {
    const width = this.canvasElem.width;
    const height = this.canvasElem.height;
    this.textAlignment = useClientSettingsStore().textAlignment || "left";

    this.ctx.clearRect(0, 0, width, height);
    this.show();

    this.ctx.strokeStyle = color.borderMenuColor;
    this.ctx.lineWidth = 12;
    for (const shape of board.shapes) {
      switch (shape.type) {
        case "line":
          this.ctx.moveTo(shape.p0.x * width, shape.p0.y * height);
          this.ctx.lineTo(shape.p1.x * width, shape.p1.y * height);
          break;
      }
    }
    this.ctx.stroke();

    let font: Font = null!;
    for (const relativeSize of fluidBoard(component).getRelativeCardSizes()) {
      const size = {
        left: relativeSize.left * width,
        top: relativeSize.top * height,
        width: relativeSize.width * width,
        height: relativeSize.height * height,
      };
      const cardElem = document.getElementById(relativeSize.id);
      // in some rare timing occasions, cardElem might be undefined
      if (cardElem) {
        const textElem = cardElem.querySelector<HTMLElement>(
          ".sticky-note-text-wrapper>*",
        )!;

        font = font || this.calcFont(textElem);

        const card = board.cards[relativeSize.id];
        const currentColor = cardColor(
          card.data,
          useBoardStore().currentBoard(),
        );

        const isSelected = useBoardStore().isStickySelected(card.data.id);
        this.drawCard(size, currentColor, isSelected);

        const fontData = await optimalFontSize(
          textElem,
          card.data.text,
          useClientSettingsStore().stickyFont,
          false,
          { isDependency: isDependency(card.data) },
        );
        this.drawText(
          size,
          currentColor,
          fontData.textLines,
          this.applyFont(font, textElem),
          font.lineHeight,
        );

        if (isFaded(card.meta.mark)) {
          this.ctx.fillStyle = color.lowlight;
          this.ctx.fillRect(size.left, size.top, size.width, size.height);
        }
      }
    }
  }

  show() {
    this.canvasElem.style.display = "block";
  }

  hide() {
    this.canvasElem.style.display = "none";
  }

  calcFont(textElem: HTMLElement): Font {
    const style = window.getComputedStyle(textElem);
    const zoomFactor = fakeZoom / useZoomStore().factor;
    const elementScale =
      (zoomFactor * textElem.getBoundingClientRect().width) /
      parseInt(style.width);
    const relativeCanvasSize =
      this.canvasElem.width / this.canvasElem.offsetWidth;
    const relativeFontSize =
      parseFloat(style.fontSize) / parseFloat(textElem.style.fontSize);
    return {
      factor: relativeCanvasSize * relativeFontSize * elementScale,
      family: style.fontFamily,
      weight: style.fontWeight,
      lineHeight: parseFloat(style.lineHeight) / parseFloat(style.fontSize),
    };
  }

  applyFont(font: Font, textElem: HTMLElement): number {
    const fontSize = parseFloat(textElem.style.fontSize) * font.factor;
    this.ctx.font = `${font.weight} ${fontSize}px ${font.family}`;
    return fontSize;
  }

  resetShadow() {
    this.ctx.shadowBlur = 0;
    this.ctx.shadowOffsetX = 0;
    this.ctx.shadowOffsetY = 0;
  }

  drawCard(size: Size, backgroundColor: Color, isSelected: boolean) {
    this.ctx.fillStyle = toCssColor(backgroundColor);

    this.ctx.shadowColor = shadowColor;
    this.ctx.shadowBlur = Math.round(size.height / 30); // this should give us ~8px shadow blur

    this.ctx.fillRect(size.left, size.top, size.width, size.height);

    if (isSelected) {
      // Set the properties for the border
      this.ctx.strokeStyle = borderColor;
      this.ctx.lineWidth = 3;

      // Draw the border around the filled rectangle
      this.ctx.strokeRect(size.left, size.top, size.width, size.height);
    }

    // Reset shadow for other elements
    this.resetShadow();
  }

  drawText(
    size: Size,
    color: Color,
    lines: string[],
    fontSize: number,
    lineHeight: number,
  ) {
    this.ctx.fillStyle = contrastCssColor(color);
    this.ctx.textAlign = this.textAlignment;
    const left = size.left + alignmentFactor[this.textAlignment] * size.width;
    const top = size.top + 0.155 * size.height + 0.95 * fontSize;
    let y = 0;
    for (const line of lines) {
      this.ctx.fillText(line, left, top + y * lineHeight * fontSize);
      y++;
    }
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/z-index";

#zoom-canvas {
  display: none;
  pointer-events: none;
  position: absolute;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  z-index: z-index.$zoom;
}
</style>
