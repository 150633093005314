<script setup lang="ts">
import { computed } from "vue";

import { useLoadingStore } from "@/store/loading";

const progress = computed(() => useLoadingStore().progress);
const showProgress = computed(() => !useLoadingStore().ended);
</script>

<template>
  <div class="app-loading-indicator">
    <div v-if="showProgress" class="progress">
      <div class="total" />
      <div class="started" :style="{ width: progress.started * 100 + '%' }" />
      <div class="finished" :style="{ width: progress.finished * 100 + '%' }" />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;

.app-loading-indicator {
  pointer-events: none;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .progress {
    width: 200px;
    position: relative;

    & > div {
      position: absolute;
      height: 20px;
      border-radius: 10px;
    }

    .total {
      background-color: colors-old.$status-class-todo-color;
      width: 100%;
    }

    .started {
      background-color: colors-old.$status-class-progress-color;
    }

    .finished {
      background-color: colors-old.$status-class-done-color;
    }
  }
}
</style>
