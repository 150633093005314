import { groupBy, sortBy } from "lodash-es";

import { boardTitle } from "@/model/baseTypes";
import { boardTypeOrder } from "@/model/board";
import { BaseStickyInfo, BoardCard } from "@/model/card";
import { SearchResult } from "@/model/search";
import { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { i18n, translate } from "@/translations/i18n";

export type CardSearchResult = SearchResult & { card?: BoardCard };

export interface StickyInfoKey {
  id: string;
  boardId?: string;
  teamId?: string;
}

export function toSearchResult(
  boardCard: BoardCard,
  boardId = useBoardStore().boardId(),
): CardSearchResult {
  const card = boardCard.data;
  return {
    kind: "sticky",
    boardId,
    id: card.id,
    text: card.text,
    typeId: card.type.id,
    iterationId: card.iterationId || undefined,
    teamId: card.teamId || undefined,
    flag: card.flagType,
    almId: card.almId,
    card: boardCard,
    artId: useBoardsStore().boardById(boardId).artId,
    status: card.status,
  };
}

interface SearchResultGroupByArt {
  artId: string;
  artName: string;
  boards: GroupedSearchResults[];
}

export interface GroupedSearchResults {
  boardId: string;
  boardName: string;
  count: string;
  items: BaseStickyInfo[];
}

const kindNames = {
  unknown: /*$t*/ "searchResultsTs.results",
  sticky: /*$t*/ "searchResultsTs.stickies",
};

export function sortSearchResults(results: SearchResult[]): SearchResult[] {
  return sortBy(
    results,
    ({ boardId }) => boardTypeOrder(useBoardsStore().boardById(boardId).type),
    ({ boardId }) => translate(boardTitle(useBoardsStore().boardById(boardId))),
  );
}

export function groupSearchResultsByArt(
  results: SearchResult[],
): SearchResultGroupByArt[] {
  const groups = groupBy(results, (result) => result.artId);

  return Object.entries(groups).map(([artId, items]) => {
    const art = useArtStore().artById(artId);
    const artName = ` ${art?.name || ""}`;
    return {
      artId,
      artName: artName,
      boards: groupSearchResults(items),
    };
  });
}

function mapSearchResult(item: SearchResult): BaseStickyInfo {
  const type = useStickyTypeStore().findStickyType(item.typeId);
  const findTeamById = (teamId: Team["id"] | undefined) =>
    useSearchMenuStore().allTeams.find((team: Team) => team.id === teamId);
  return {
    id: item.id,
    text: item.text,
    type,
    flag: item.flag,
    iteration: useSessionStore().getIterationById(item.iterationId),
    almId: item.almId,
    points: item.points || 0,
    team: findTeamById(item.teamId),
    status: item.status,
  };
}

export function groupSearchResults(
  results: SearchResult[],
): GroupedSearchResults[] {
  const groups = groupBy(results, (result) => result.boardId);
  return Object.entries(groups).map(([boardId, items]) => ({
    boardId,
    boardName: translate(boardTitle(useBoardsStore().boardById(boardId))),
    count: countSearchResults(items),
    items: items.map((item) => mapSearchResult(item)),
  }));
}

export function groupSearchResultsByType(
  items: BaseStickyInfo[],
): Record<string, BaseStickyInfo[]> {
  return groupBy(items, (item) => item.type.name);
}

export function groupByTeamName(
  items: BaseStickyInfo[],
): Record<string, BaseStickyInfo[]> {
  const searchResultsGroupedByTeam = groupBy(items, (item) => {
    const { team } = item;

    if (!team) {
      return i18n.global.t("general.noTeam");
    }

    return team.name;
  });
  return Object.fromEntries(Object.entries(searchResultsGroupedByTeam));
}

function countSearchResults(results: SearchResult[]) {
  const kind = kindNames[results[0]?.kind || "unknown"];
  return i18n.global.t(kind, results.length);
}
