import { rectangle } from "@/math/coordinates";
import {
  Rectangle,
  RelativeCoordinate,
  isCoordinate,
  relativeCoord,
} from "@/model/coordinates";
import { Shape, fixLine } from "@/model/shape";
import { i18n } from "@/translations/i18n";

import { BoardLocation } from "./BoardLocation";

const names = [
  /*$t*/ "risk.resolved",
  /*$t*/ "risk.owned",
  /*$t*/ "risk.accepted",
  /*$t*/ "risk.mitigated",
  /*$t*/ "riskBoardLocation.submissions",
];
const roam = ["R", "O", "A", "M", ""];

export class RiskBoardLocation implements BoardLocation {
  readonly shapes: Shape[] = [
    fixLine("v", relativeCoord(0.5, 0), relativeCoord(0.5, 0.85)),
    fixLine("h1", relativeCoord(0, 0.5), relativeCoord(1, 0.5)),
    fixLine("h2", relativeCoord(0, 0.85), relativeCoord(1, 0.85)),
  ];

  constructor(private regionIndex: number) {}

  static of(value: RelativeCoordinate | number): RiskBoardLocation {
    return isCoordinate(value)
      ? RiskBoardLocation.fromCoordinate(value)
      : new RiskBoardLocation(value);
  }

  static fromCoordinate(coord: RelativeCoordinate): RiskBoardLocation {
    if (coord.y < 0.5) {
      return new RiskBoardLocation(Math.floor(coord.x * 2));
    }
    if (coord.y < 0.85) {
      return new RiskBoardLocation(2 + Math.floor(coord.x * 2));
    }
    return new RiskBoardLocation(4);
  }

  get bounds(): Rectangle<RelativeCoordinate> {
    return rectangle(
      this.topLeft,
      this.regionIndex < 4 ? relativeCoord(0.5, 0.34) : relativeCoord(1, 0.18),
    );
  }

  private get topLeft(): RelativeCoordinate {
    if (this.regionIndex < 2) {
      return relativeCoord(0.5 * (this.regionIndex % 2), 0.17);
    }
    if (this.regionIndex < 4) {
      return relativeCoord(0.5 * (this.regionIndex % 2), 0.52);
    }
    return relativeCoord(0, 0.82);
  }

  matches(coord: RelativeCoordinate) {
    return (
      RiskBoardLocation.fromCoordinate(coord).regionIndex === this.regionIndex
    );
  }

  get roam() {
    return roam[this.regionIndex];
  }

  get name(): string {
    return i18n.global.t(names[this.regionIndex]);
  }

  index() {
    return [this.regionIndex];
  }
}
