<script setup lang="ts">
import { computed } from "vue";

import ObjectiveLinkModal from "@/components/modal/ObjectiveLinkModal.vue";
import { Objective } from "@/model/objective";
import { Team } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { useObjectiveStore } from "@/store/objective";

import ObjectiveLinkButton from "./ObjectiveLinkButton.vue";

const props = defineProps<{
  objective: Objective;
  team: Team;
  index: number;
  selected?: boolean;
}>();

const linkCount = computed(
  () =>
    useObjectiveStore().linkedCardTree(props.objective, {
      teamId: props.team.id,
    }).count,
);

function showLinkModal() {
  useModalStore().open(ObjectiveLinkModal, {
    attrs: {
      boardId: useBoardStore().currentBoard().id,
      objectiveId: props.objective.id,
    },
  });
}

function setLinkingTarget() {
  const linkStore = useLinkStore();
  const isAlreadyLinked = props.objective.cards.some((card) =>
    linkStore.isLinkedFrom(card.id),
  );
  if (linkStore.linking.from && !isAlreadyLinked) {
    linkStore.setLinkingTarget({
      id: props.objective.id,
      type: "objective",
    });
  }
}

function resetLinkingTarget() {
  useLinkStore().resetLinkingTarget();
}
</script>

<template>
  <div
    class="objective-row"
    data-testid="team-objective-row"
    :class="{ selected }"
    role="button"
    tabindex="0"
    @pointerenter="setLinkingTarget"
    @pointerleave="resetLinkingTarget"
  >
    <div class="objective-title">
      <span class="objective-index">{{ index }}</span>
      <span>{{ objective.text }}</span>
    </div>
    <div class="objective-right-content">
      <ObjectiveLinkButton
        class="objective-link-button"
        :link-count="linkCount"
        @click.stop="showLinkModal"
      />
      <span
        class="objective-business-value"
        :aria-label="$t('label.objective.bv', { value: objective.bv })"
        >{{ objective.bv }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.objective-row {
  margin: 0 board.len(12px) board.len(2px) board.len(12px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: board.len(10px);
  border-radius: 1.5 * variables.$border-radius;
  border: board.len(1px) solid colors-old.$hover-color;

  &:hover,
  &:focus {
    border: board.len(1px) solid colors-old.$objective-hover-color;
  }

  &.selected {
    border: board.len(1px) solid colors-old.$objective-highlight-color;
  }

  &:focus-visible {
    outline: board.len(2px) solid colors-old.$objective-highlight-color;
  }
}

.objective-title {
  display: inline-flex;
  font-size: board.len(14px);
  padding-right: board.len(40px);
  word-break: break-word;
  white-space: pre-line;
}

.objective-right-content {
  display: flex;
  text-align: right;
  align-items: center;
  gap: board.len(16px);
}

.objective-link-button {
  font-weight: font.$weight-semi-bold;
}

.objective-business-value {
  display: inline-block;
  font-size: board.len(14px);
  font-weight: font.$weight-semi-bold;
  width: board.len(16px);
  margin-right: board.len(24px);
}

.objective-index {
  margin-right: board.len(22px);
  color: colors-old.$text-secondary-color;
  font-weight: font.$weight-bold;
}
</style>
