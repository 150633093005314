import { groupBy, mapValues, sortBy } from "lodash-es";

import { searchStickies } from "@/backend/Backend";
import {
  ART_BOARDS,
  Board,
  BoardData,
  SOLUTION_BOARDS,
  TEAM_BOARDS,
} from "@/model/board";
import { Card } from "@/model/card";
import { LinkableCard } from "@/model/link";
import { SearchResult } from "@/model/search";
import { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardsStore } from "@/store/boards";
import { linkBetween } from "@/store/link";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";

import {
  GroupedLinkableItems,
  LinkableItemGroup,
  LinkableItemGroups,
  NO_ART,
  SearchContext,
} from "../types";

type LinkableCardWithBoardId = LinkableCard & { boardId: string };

export async function groupedLinkableStickies(
  targetCards: Card[],
  searchContext: SearchContext,
): Promise<GroupedLinkableItems> {
  const linkableStickies = (await getStickies())
    .filter(isNotTargetGroup)
    .map(makeLinkableCard);
  return groupStickies(linkableStickies);

  async function getStickies() {
    if (searchContext.query) {
      return await searchStickies({ text: searchContext.query });
    }
    const boards = getCurrentLevelBoards();
    return boards.length ? await searchStickies({ boards }) : [];
  }

  function groupStickies(
    stickies: LinkableCardWithBoardId[],
  ): GroupedLinkableItems {
    const groupedStickiesByBoardGroups = groupStickiesByBoardGroups(stickies);

    return {
      currentLevel: extractCurrentLevel(groupedStickiesByBoardGroups),
      otherLevels: groupedStickiesByBoardGroups,
    };
  }

  function extractCurrentLevel(
    groupedStickiesByBoardGroups: LinkableItemGroups,
  ): LinkableItemGroup {
    if (searchContext.level === "none") {
      return { level: "none", items: [] };
    }

    const id =
      searchContext.level === "solution-train"
        ? "solution-train"
        : searchContext.id || NO_ART;
    const currentLevelItems = groupedStickiesByBoardGroups[id]?.items ?? [];
    delete groupedStickiesByBoardGroups[id];
    return { level: searchContext.level, items: currentLevelItems };
  }

  function groupStickiesByBoardGroups(
    stickies: LinkableCardWithBoardId[],
  ): LinkableItemGroups {
    const boardGroups = {
      team: Object.values(useBoardsStore().boards)
        .filter((board) => TEAM_BOARDS.includes(board.type))
        .map((board) => board.id),
      art: Object.values(useBoardsStore().boards)
        .filter((board) => ART_BOARDS.includes(board.type))
        .map((board) => board.id),
      solution: Object.values(useBoardsStore().boards)
        .filter((board) => SOLUTION_BOARDS.includes(board.type))
        .map((board) => board.id),
    };

    const teamStickies = stickies.filter((sticky) =>
      boardGroups.team.includes(sticky.boardId),
    );

    const artStickies = stickies.filter((sticky) =>
      boardGroups.art.includes(sticky.boardId),
    );

    const solutionStickies = sortStickies(
      stickies.filter((sticky) =>
        boardGroups.solution.includes(sticky.boardId),
      ),
    );

    const groupedByTeam = groupBy(sortStickies(teamStickies), (sticky) => {
      const board = useBoardsStore().boardById(
        sticky.boardId,
      ) as BoardData<"team">;
      return board.team.id;
    });

    const groupedByART = groupBy(sortStickies(artStickies), (sticky) => {
      const board = useBoardsStore().boardById(sticky.boardId);
      return board.artId ?? NO_ART;
    });

    return {
      ...mapValues(groupedByTeam, (group) => ({
        items: removeDuplicatedStickies(group),
        level: "team",
      })),
      ...mapValues(groupedByART, (group) => ({
        items: removeDuplicatedStickies(group),
        level: "art",
      })),
      ...(solutionStickies.length
        ? {
            "solution-train": {
              items: removeDuplicatedStickies(solutionStickies),
              level: "solution-train",
            },
          }
        : undefined),
    };
  }

  function sortStickies(stickies: LinkableCardWithBoardId[]) {
    return sortBy(stickies, [sameTeam, sameArt, teamName, artName, text]);

    function sameTeam(card: LinkableCardWithBoardId) {
      return card.team?.id === useTeamStore().currentTeam.id ? 0 : 1;
    }

    function sameArt(card: LinkableCardWithBoardId) {
      return card.artId === useArtStore().currentArt.id ? 0 : 1;
    }

    function artName(card: LinkableCardWithBoardId) {
      return useArtStore().artById(card.artId)?.name;
    }

    function teamName(card: LinkableCardWithBoardId) {
      return useTeamStore().findTeam({ id: card.team?.id })?.name;
    }

    function text(card: LinkableCardWithBoardId) {
      return card.text.toLowerCase();
    }
  }

  function getCurrentLevelBoards(): Board[] {
    switch (searchContext.level) {
      case "solution-train":
        return solutionLevelBoards();
      case "art":
        return artLevelBoards(searchContext.id);
      case "team":
        return teamLevelBoards(searchContext.id);
      case "none":
        return [];
    }
  }

  function solutionLevelBoards(): Board[] {
    return useBoardsStore().hasSolutionBacklogBoard
      ? SOLUTION_BOARDS.map((type) => useBoardsStore().boardByType(type))
      : [];
  }

  function artLevelBoards(artId: string): Board[] {
    return ART_BOARDS.map((type) =>
      useBoardsStore().boardByType(type, { artId }),
    );
  }

  function teamLevelBoards(teamId: string): Board[] {
    return TEAM_BOARDS.map((type) =>
      useBoardsStore().boardByType(type, { teamId }),
    );
  }

  function removeDuplicatedStickies(
    stickies: LinkableCardWithBoardId[],
  ): LinkableCardWithBoardId[] {
    const groupTracker = new Map<string, LinkableCardWithBoardId>();

    stickies.forEach((sticky) => {
      const { groupId, id } = sticky;

      if (!groupId) {
        // Stickies without groupId should be kept as they are unique.
        groupTracker.set(id, sticky);
        return;
      }

      const existingSticky = groupTracker.get(groupId);
      if (
        !existingSticky ||
        (id === groupId && existingSticky.id !== groupId)
      ) {
        groupTracker.set(groupId, sticky);
      }
    });

    return Array.from(groupTracker.values());
  }

  function isNotTargetGroup(card: SearchResult) {
    return !targetCards.some(
      (target) => (target.groupId ?? target.id) === (card.groupId ?? card.id),
    );
  }

  function makeLinkableCard(card: SearchResult): LinkableCardWithBoardId {
    const type = useStickyTypeStore().findStickyType(card.typeId);
    const getTeam = (id: string | undefined): Team | undefined =>
      id ? useTeamStore().findTeam({ id }) : undefined;
    const linked = targetCards.every((c) => !!linkBetween(c, card));
    return {
      kind: "sticky",
      linked,
      id: card.id,
      text: card.text,
      boardId: card.boardId,
      groupId: card.groupId,
      type: type,
      flag: card.flag,
      iteration: useSessionStore().getIterationById(card.iterationId),
      team: getTeam(card.teamId),
      artId: card.artId ?? undefined,
      almId: card.almId,
      status: card.status,
      points: card.points || 0,
      precondTeam: getTeam(card.precondTeamId),
      dependTeam: getTeam(card.dependTeamId),
    };
  }
}
