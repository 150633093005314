import { isArray } from "lodash-es";
import { IToggle } from "unleash-proxy-client";

import { featureToggles } from "./featureToggles";

interface Feature {
  id: string;
  description: string;
  active?: boolean;
  fromGitlab?: boolean;
}

let features: Feature[] = [
  {
    id: "log-less",
    description: "Log less description",
  },
  {
    id: "faro",
    description: "Send events to faro",
  },
  {
    id: "faro-sample-all",
    description: "Faro sample all description",
  },
  {
    id: "status-page",
    description: "Show the 'Connection Status' link in the user menu",
  },
  {
    id: "switch-session",
    description: "Allow to switch between sessions on every board",
  },
] as const;

export type FeatureId = (typeof features)[number]["id"];

export function isFeatureEnabled(
  route: { query: Record<string, unknown> },
  featureId: FeatureId,
): boolean {
  const found = features.find((f) => f.id === featureId);
  if (found?.active !== undefined) return found.active;

  return (
    !isFeatureEnabledInQuery(route, "!" + featureId) &&
    (isFeatureEnabledInQuery(route, featureId) ||
      featureToggles.isEnabled(featureId))
  );
}

export function parseUrlWithoutRouter() {
  const hash = document.location.hash;
  const queryStart = hash.indexOf("?");
  if (queryStart < 0) {
    return {};
  }
  const params = new URLSearchParams(hash.substring(queryStart));
  const extractedParams: Record<string, string[]> = {};
  for (const [key, value] of params) {
    if (extractedParams[key]) {
      extractedParams[key].push(value);
    } else {
      extractedParams[key] = [value];
    }
  }
  return extractedParams;
}

function isFeatureEnabledInQuery(
  route: { query: Record<string, unknown> },
  feature: string,
): boolean {
  if (!route?.query?.feature) {
    return false;
  }
  if (isArray(route.query.feature)) {
    return route.query.feature.includes(feature);
  }
  return route.query.feature === feature;
}

function broadcastAvailableFeatures(features: Feature[]) {
  window.postMessage({ type: "SET_AVAILABLE_FEATURES", features }, "*");
}

export function broadcastFeatures() {
  window.addEventListener(
    "extensionAvailable",
    async () => {
      broadcastAvailableFeatures(features);

      window.addEventListener("message", (event) => {
        if (event.source !== window) return;

        if (event.data.type === "FEATURES_UPDATED") {
          features = event.data.features;

          triggerSoftRefresh();
        }
      });
    },
    { once: true },
  );
}

export function setGitlabToggles(toggles: IToggle[]) {
  window.postMessage({ type: "SET_GITLAB_TOGGLED_FEATURES", toggles }, "*");
}

async function triggerSoftRefresh() {
  const { router } = await import("@/router/router");
  const currentRoute = router?.currentRoute?.value;
  if (currentRoute && currentRoute.fullPath !== "/") {
    router.replace({
      ...currentRoute,
      query: { ...currentRoute.query, _t: Date.now() },
    });
  }
}
