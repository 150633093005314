<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import { cardKey } from "@/components/card/injectKeys";
import BaseList from "@/components/ui/BaseList/BaseList.vue";
import BaseListItem from "@/components/ui/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components/ui/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components/ui/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { RiskType, riskIcon, riskTitle, riskTypes } from "@/model/baseTypes";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const setRisk = (risk: RiskType) => {
  cardActions.setRisk("card", card.id, risk);
  dropdown.close();
};
</script>

<template>
  <BaseList class="roam-sticky">
    <BaseListTitle v-autofocus>{{ $t("risk.roam") }}</BaseListTitle>
    <BaseListItems :aria-label="$t('risk.roam')">
      <BaseListItem
        v-for="risk in riskTypes"
        :key="risk"
        :data-testid="`roam-list-item-${risk}`"
        class="roam-list-item"
        :active="card.risk === risk"
        @click="setRisk(risk)"
      >
        <template #before>
          <SvgIcon
            :name="riskIcon(risk)"
            width="20"
            height="20"
            class="roam-icon"
          />
        </template>
        {{ $t(riskTitle(risk)) }}
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.roam-sticky {
  max-height: 350px;

  .roam-list-item:not(:active) {
    .roam-icon {
      color: colors-old.$text-secondary-color;
    }
  }
}
</style>
