import { defineStore } from "pinia";

import { WorkingMode } from "@/model/baseTypes";
import { isSolutionBoard } from "@/model/board";

import { useBoardStore } from "./board";

interface State {
  workingMode: WorkingMode;
}

export const useWorkModeStore = defineStore("workMode", {
  state: (): State => ({
    workingMode: "planning",
  }),
  getters: {
    isExecutionMode(): boolean {
      return this.workingMode === "execution";
    },
    isPlanningMode(): boolean {
      return this.workingMode === "planning";
    },
    showExecutionMode(): boolean {
      return !isSolutionBoard(useBoardStore().board?.type);
    },
  },
});
