<script setup lang="ts">
import { computed } from "vue";

import { useSearchMenuStore } from "@/store/searchMenu";
import { useWorkModeStore } from "@/store/workMode";

import TopLeftMenu from "./TopLeftMenu.vue";
import TopRightMenu from "./TopRightMenu.vue";
import BreadCrumb from "./components/BreadCrumb/BreadCrumb.vue";

const searchMenuStore = useSearchMenuStore();

const darkMode = computed(() => {
  return (
    !searchMenuStore.isSearchSidebarOpen &&
    useWorkModeStore().showExecutionMode &&
    useWorkModeStore().isExecutionMode
  );
});
</script>

<template>
  <header
    v-scrollable-on-board
    class="top-menu"
    :class="{ 'dark-mode': darkMode }"
    :aria-label="$t('label.menuTop')"
  >
    <TopLeftMenu />
    <BreadCrumb class="shrinkable" />
    <TopRightMenu />
  </header>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.top-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--back-color);
  border-bottom: 2px solid colors-a11y.$board-border;
  height: variables.$top-header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3em;
  padding: 0 3px;
  transition: 0.15s ease-in-out background-color;
  z-index: z-index.$menu;

  & > div {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &.shrinkable {
      overflow: hidden;
      flex-shrink: 1;
    }
  }
}
</style>
