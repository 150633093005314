<script setup lang="ts">
import { ref } from "vue";

const modelValue = defineModel<string>({ required: true });

const inputElem = ref<HTMLInputElement | null>(null);

const focus = () => inputElem.value?.focus();

const blur = () => inputElem.value?.blur();

defineExpose({ focus, blur });
</script>

<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <input
    ref="inputElem"
    v-model="modelValue"
    class="base-input-text"
    type="text"
  />
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/variables";

.base-input-text {
  all: unset;

  &::placeholder {
    all: unset;
    color: colors-old.$placeholder-color;
  }

  &:focus {
    outline: none;
  }

  // Override for Chrome when an autofillable input is autofilled
  &:-webkit-autofill {
    box-shadow: 0 0 0 30px colors-old.$back-color inset; // check-style-disable
  }
}
</style>
