import { defineStore } from "pinia";

import { errorDialogSeen } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

export const useGlobalErrorStore = defineStore("globalError", {
  state: () => ({
    globalError: null as {
      error: any;
      timestamp: number;
      message?: string;
    } | null,
  }),
  actions: {
    setGlobalError(error: unknown, message?: string) {
      this.globalError = { error, timestamp: Date.now(), message };
      trackEvent(errorDialogSeen());
    },
  },
  getters: {
    errorDetails: (state) => {
      if (!state.globalError) return null;

      const e = state.globalError;
      // Extract the error message (can be nested in different places, depending on the shape of error)
      const errorMessage = e.error instanceof Error && e.error.toString();
      const shortMessage =
        errorMessage || e.error?.message || e.error?.name || e.message || "";

      // Extract the correlation ID (it can also be in different places)
      const correlationId =
        e.error?.contexts?.request?.correlationId ||
        e.error?.contexts?.cause?.config?.headers?.["correlation-id"] ||
        "";

      return {
        time: new Date(e.timestamp).toISOString(),
        shortMessage,
        correlationId: correlationId?.toString?.(),
      };
    },
  },
});
