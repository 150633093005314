<script setup lang="ts">
import { ref } from "vue";

import { BaseStickyInfo } from "@/model/card";

import { StickyInfoKey } from "./SearchResult";
import SearchResultGroup from "./SearchResultGroup.vue";
import SearchResultList from "./SearchResultList.vue";
import SearchResultSubgroup from "./SearchResultSubgroup.vue";
import {
  groupResults,
  groupSearchResultsByDependencyTeamFilter,
  showDependencyGroupingHeader,
} from "./groupSearchResults";

const emit = defineEmits<{
  select: [item: StickyInfoKey, focus?: boolean];
}>();

function selectItem(item: StickyInfoKey, focus?: boolean) {
  emit("select", item, focus);
}

defineProps<{
  items: BaseStickyInfo[];
  boardId: string;
  selected?: StickyInfoKey;
}>();

const currentItem = ref<StickyInfoKey | null>(null);

function setCurrent(result: BaseStickyInfo) {
  currentItem.value = result;
}

function resetCurrent(result: BaseStickyInfo) {
  if (currentItem.value === result) {
    currentItem.value = null;
  }
}
</script>

<template>
  <div v-for="(chunk, type) in groupResults(items)" :key="type">
    <SearchResultGroup
      :heading="type"
      :right="chunk.length"
      class="search-result-group"
    >
      <SearchResultSubgroup
        v-for="(
          dependencyTeamFilterChunk, dependencyTeamFilter
        ) in groupSearchResultsByDependencyTeamFilter(chunk)"
        :key="dependencyTeamFilter"
        :show-header="showDependencyGroupingHeader(dependencyTeamFilterChunk)"
        :left="dependencyTeamFilter"
      >
        <SearchResultList
          :results="dependencyTeamFilterChunk"
          :current="currentItem"
          :selected="selected"
          :board-id="boardId"
          @select="selectItem"
          @pointerenter="setCurrent"
          @pointerleave="resetCurrent"
        />
      </SearchResultSubgroup>
    </SearchResultGroup>
  </div>
</template>

<style lang="scss" scoped>
.search-result-group {
  margin-top: 16px;
}
</style>
