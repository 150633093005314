<script setup lang="ts">
import { ref, toRefs } from "vue";

import { TextAlign, useTextElement } from "./useTextElement";

// an SVG rect with optional text inside (splitting the rect in two parts)

const props = defineProps<{
  x: number;
  y: number;
  width: number;
  height: number;
  text?: string;
  align?: TextAlign;
}>();

const textElem = ref<SVGTextElement>();

const { hasText, textAttrs, horizontal, firstEnd, secondStart } =
  useTextElement(textElem, toRefs(props));
</script>

<template>
  <text v-if="text" ref="textElem" v-bind="textAttrs"></text>
  <rect v-if="!hasText" v-bind="{ ...$attrs, x, y, width, height }" />
  <template v-else-if="horizontal">
    <rect v-bind="{ ...$attrs, x, y, height }" :width="firstEnd" />
    <rect
      v-bind="{ ...$attrs, y, height }"
      :x="x + secondStart"
      :width="width - secondStart"
    />
  </template>
  <template v-else>
    <rect v-bind="{ ...$attrs, x, y, width }" :height="firstEnd" />
    <rect
      v-bind="{ ...$attrs, x, width }"
      :y="y + secondStart"
      :height="height - secondStart"
    />
  </template>
</template>
