import { IdMap } from "./baseTypes";

export class Counter {
  ok = 0;
  fail = 0;
  okDetail: IdMap<number> = {};
  failDetail: IdMap<number> = {};

  constructor(counter?: Counter) {
    if (counter) {
      this.ok = counter.ok;
      this.fail = counter.fail;
      this.okDetail = { ...counter.okDetail };
      this.failDetail = { ...counter.failDetail };
    }
  }

  addOk(detail?: string) {
    this.ok++;
    if (detail) {
      if (!(detail in this.okDetail)) {
        this.okDetail[detail] = 0;
      }
      this.okDetail[detail]++;
    }
  }

  addFail(detail?: any) {
    this.fail++;
    if (detail) {
      const key = detail.code || detail.message || detail.toString();
      if (!(key in this.failDetail)) {
        this.failDetail[key] = 0;
      }
      this.failDetail[key]++;
    }
  }
}
