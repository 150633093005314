import { Line, RelativeCoordinate } from "./coordinates";

export type LabelAlign = "start" | "center" | "end";

export interface Shape extends Line<RelativeCoordinate> {
  id: string;
  type: ShapeType;
  fixed: boolean;
  label?: {
    text: string;
    align: LabelAlign;
  };
}

export type ShapeType = "line";

export function fixLine(
  id: string,
  p0: RelativeCoordinate,
  p1: RelativeCoordinate,
): Shape {
  return { id, type: "line", p0, p1, fixed: true };
}
