import { Vue } from "vue-class-component";

import { Action } from "@/action/actions";
import {
  Key,
  ShortcutOptions,
  accept,
  registerShortcut,
  unregisterShortcuts,
} from "@/components/utils/Shortcuts";
import { useModalStore } from "@/store/modal";

// TODO a stack of focuses instead?
let focus: ShortcutUser | null = null;

export default class ShortcutUser extends Vue {
  private focusObj = {};

  globalShortcut(
    key: Key,
    handler: (e: KeyboardEvent) => void,
    options?: ShortcutOptions<ShortcutUser>,
  ) {
    registerShortcut(
      this,
      (e) => !useModalStore().isModalOpen() && accept(key)(e),
      handler,
      options,
    );
  }

  globalActionShortcut(
    action: Action<any[]>,
    options?: ShortcutOptions<ShortcutUser> & { withEvent?: boolean },
  ) {
    if (action.data.shortcut) {
      this.globalShortcut(
        action.data.shortcut.key,
        (e: KeyboardEvent) =>
          options?.withEvent ? action("keyboard", e) : action("keyboard"),
        options,
      );
    }
  }

  shortcut(
    accept: Key,
    handler: (e: KeyboardEvent) => void,
    options?: ShortcutOptions<unknown>,
  ) {
    if (focus && focus !== this) {
      unregisterShortcuts(focus.focusObj);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    focus = this;
    registerShortcut(this.focusObj, accept, handler, options);
  }

  unmounted() {
    this.unregisterShortcuts();
  }

  unregisterShortcuts() {
    unregisterShortcuts(this);
    unregisterShortcuts(this.focusObj);
    if (focus === this) {
      focus = null;
    }
  }
}
