// NB: in order to be consistent, only card properties should be used
// that are the same across all mirrored instances of a card
// e.g. using teamId on a dependency card is not ok because it can be mirrored to multiple team boards
import { BoardType, InfoLevel } from "@/model/baseTypes";
import { Card } from "@/model/card";
import { isDependency, isWorkitem } from "@/model/stickyType";

export function calcLinkState(from: Card, to: Card): InfoLevel {
  // states for backlog sticky linked to
  // a team sticky in earlier/same/later iteration
  const backlogToTeamLinkStates: InfoLevel[] = ["ok", "ok", "error"];

  // states for a dependency sticky linked to
  // a team/backlog sticky of same/different team in earlier/same/later iteration
  const dependencyLinkStates: Record<string, InfoLevel[]> = {
    teamStickyOfSameGroup: ["ok", "ok", "error"],
    teamStickyOfDifferentGroup: ["ok", "ok", "ok"],
    backlogStickyOfSameGroup: ["ok", "ok", "error"],
    backlogStickyOfDifferentGroup: ["error", "warn", "ok"],
    solution_backlogStickyOfSameGroup: ["ok", "ok", "error"],
    solution_backlogStickyOfDifferentGroup: ["error", "warn", "ok"],
  };

  const cards = [from, to];
  if (cards.some((card) => card.iterationId === null)) {
    return "default";
  }

  const backlog = workitemFrom(cards, "backlog");
  const team = workitemFrom(cards, "team");
  if (backlog?.teamId && team?.teamId) {
    const order = iterationOrder(backlog, team);
    return backlogToTeamLinkStates[order];
  }

  const solutionBacklog = workitemFrom(cards, "solution_backlog");
  const dependency = cards.find(isDependency);
  const nonDependency = solutionBacklog || backlog || team;
  if (dependency && nonDependency) {
    const isArtLevel = !solutionBacklog;
    const dependencyGroup = groupOf(dependency, isArtLevel);
    const nonDependencyGroup = groupOf(nonDependency, isArtLevel);
    if (dependencyGroup && nonDependencyGroup) {
      const situation =
        nonDependency.type.origin +
        "StickyOf" +
        (dependencyGroup === nonDependencyGroup ? "Same" : "Different") +
        "Group";
      const order = iterationOrder(dependency, nonDependency);
      return dependencyLinkStates[situation][order];
    }
  }

  return "default";

  function groupOf(card: Card, artLevel: boolean) {
    if (isDependency(card)) {
      return artLevel ? card?.dependTeam?.id : card?.dependTeam?.artId;
    }
    return artLevel ? card?.teamId : card?.artId;
  }

  function workitemFrom(cards: Card[], origin: BoardType) {
    return cards.find(
      (card) => isWorkitem(card) && card.type.origin === origin,
    );
  }

  function iterationOrder(a: Card, b: Card) {
    const iterDiff = b.iterationId! - a.iterationId!;
    return iterDiff < 0 ? 0 : iterDiff > 0 ? 2 : 1;
  }
}
