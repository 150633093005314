<script setup lang="ts">
import { computed } from "vue";

import { useBoardsStore } from "@/store/boards";
import { i18n } from "@/translations/i18n";

import MetricsModalCapacityAllocationBody, {
  CapacityAllocationBodyItem,
} from "./MetricsModalCapacityAllocationBody.vue";
import { CapacityAllocation, calcCapacityAllocation } from "./metrics";

const title = i18n.global.t("metricsModal.allTeams");

const boards = computed(() => useBoardsStore().artTeamBoards());

const items = computed((): CapacityAllocationBodyItem[] =>
  boards.value.map((board) => ({
    name: board.team.name,
  })),
);

const iterData = (iterationIndex?: number): CapacityAllocation =>
  calcCapacityAllocation(
    iterationIndex !== undefined
      ? [boards.value[iterationIndex]]
      : boards.value,
  );
</script>

<template>
  <MetricsModalCapacityAllocationBody :overrides="{ title, items, iterData }" />
</template>
