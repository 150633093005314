<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import { selectionActions } from "@/action/selectionActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import BoardBase from "@/components/board/BoardBase";
import { fluidBoard } from "@/components/board/FluidBoard";
import { useNativeEvents } from "@/composables/useNativeEvents";
import { useSelectionStore } from "@/store/selection";

import MenuItemDelimiter from "../../MenuItemDelimiter.vue";

const props = defineProps<{ board?: BoardBase }>();

const actions = selectionActions;

const context = computed(
  () =>
    (props.board && fluidBoard(props.board).contextActions().selection) || {
      stickyMove: false,
      link: false,
      mirror: false,
      team: false,
    },
);

const { t } = useI18n();

const undoText = computed(() => {
  const lastEntry = useSelectionStore().lastHistoryEntry;
  return lastEntry
    ? t("action.undoSelectionAction", {
        action: lastEntry.action.name,
        count: lastEntry.stateBeforeAction.length,
      })
    : "";
});

function canMirror() {
  return useSelectionStore().mirrorTargets.length > 0;
}

const handleUndo = (e: KeyboardEvent) => {
  if (!undoText.value) return;
  if (e.key.toLowerCase() === "z" && (e.metaKey || e.ctrlKey)) {
    actions.undo("keyboard");
  }
};

const { addEventListener } = useNativeEvents();
onMounted(() => {
  addEventListener(document, "keydown", handleUndo);
});
</script>

<template>
  <ActionButton v-if="context.stickyMove" :action="actions.alignHorizontal" />
  <ActionButton
    v-if="context.stickyMove"
    :action="actions.distributeHorizontal"
  />
  <ActionButton v-if="context.stickyMove" :action="actions.alignVertical" />
  <ActionButton
    v-if="context.stickyMove"
    :action="actions.distributeVertical"
  />
  <ActionButton v-if="context.stickyMove" :action="actions.lineup" />

  <MenuItemDelimiter />

  <ActionButton
    v-if="context.mirror"
    :action="actions.mirror"
    :disabled="!canMirror()"
  />
  <ActionButton v-if="context.team" :action="actions.setTeam" />
  <ActionButton v-if="context.link" :action="actions.link" />

  <MenuItemDelimiter />

  <ActionButton
    :action="actions.undo"
    :tooltip="undoText"
    :disabled="!undoText"
  />
</template>
