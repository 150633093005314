<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  endSoon: boolean;
  isActive: boolean;
  ratio: number;
}>();

const strokeDasharray = computed(
  () => `${props.ratio * 100} ${100 - props.ratio * 100} `,
);
</script>

<template>
  <div class="timer-icon-progress" :class="{ isActive }">
    <svg
      viewBox="0 0 64 64"
      :class="{ 'end-soon': endSoon }"
      aria-hidden="true"
    >
      <circle
        r="16"
        cx="50%"
        cy="50%"
        class="inner"
        :style="{ strokeDasharray }"
      />
    </svg>
  </div>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.timer-icon-progress {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: colors-old.$primary-back-color;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 17px;
    height: 17px;
    border-radius: 50%;

    .inner {
      stroke-dashoffset: 25;
      stroke-width: 32;
      fill: none;
      stroke: colors-old.$status-class-progress-color;
    }

    &.end-soon {
      background-color: colors-old.$error-back-color;

      .inner {
        stroke: colors-old.$error-color;
      }
    }
  }
}
</style>
