<script setup lang="ts">
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { useModalStore } from "@/store/modal";

import BaseModal from "./BaseModal.vue";

const props = defineProps<{
  minHeight?: string;
  question: string;
  actionLabel: string;
  cancelLabel?: string;
  action: () => void;
  cancel?: () => void;
  error?: string;
}>();

function doCancel() {
  if (props.cancel) {
    props.cancel();
  } else {
    useModalStore().hide();
  }
}
</script>

<template>
  <BaseModal
    :title="question"
    width="500"
    :min-height="minHeight"
    class="confirm-modal"
    :description="null"
  >
    <slot />
    <p class="error">{{ error }}</p>
    <div class="bottom">
      <BaseButton @click="action">
        {{ actionLabel }}
      </BaseButton>
      <BaseButton variant="outlined" @click="doCancel">
        {{ cancelLabel || $t("general.cancel") }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.confirm-modal {
  .bottom {
    display: flex;
    justify-content: center;
    flex-grow: 1; // Align the buttons to the bottom of the modal
    align-items: flex-end;
    gap: 1em;

    .base-button {
      flex-basis: 150px;
    }
  }

  .error {
    margin-top: 1em;
    max-height: 2.5em;
    overflow: auto;
    color: colors-old.$error-color;
  }
}
</style>
