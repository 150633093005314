<script setup lang="ts">
import { computed } from "vue";

import { drawActions } from "@/action/drawActions";
import { toggleActions } from "@/action/toggleActions";
import drawLines from "@/assets/draw-lines.gif";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { useDrawStore } from "@/store/draw";
import { useToastStore } from "@/store/toast";
import {
  drawLineModeSelected,
  drawModeClosed,
  drawMoveModeSelected,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import MenuItemDelimiter from "../../MenuItemDelimiter.vue";

const tool = computed(() => useDrawStore().tool);

function onLineMode() {
  trackEvent(drawLineModeSelected());
  useToastStore().show(/*$t*/ "message.lineTool", {
    titleKey: /*$t*/ "action.lineTool",
    imageUrl: drawLines,
    closeable: true,
  });
}

function onMoveMode() {
  trackEvent(drawMoveModeSelected());
}

function hideDraw() {
  trackEvent(drawModeClosed("board-actions-menu"));
  toggleActions.showDraw("mainMenu");
}
</script>

<template>
  <ActionButton
    :action="drawActions.moveTool"
    :activated="tool === 'move'"
    @click="onMoveMode"
  />
  <ActionButton
    :action="drawActions.lineTool"
    :activated="tool === 'line'"
    @click="onLineMode"
  />
  <MenuItemDelimiter />
  <BaseButton variant="ghost" @click="hideDraw">
    {{ $t("contextMenu.drawModeDone") }}
  </BaseButton>
</template>
