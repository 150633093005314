import { Board } from "@/model/board";
import { Card } from "@/model/card";

import { ActionMenuAction } from "./actions";
import * as actions from "./actions";

export const toElipsis = (value: string) => (value.length > 2 ? "..." : value);

/**
 *  Determines if specific action should be visible in the action menu
 * @param action
 */
export const isActionVisible = (
  action: ActionMenuAction,
  card: Card,
  board: Board,
): boolean => {
  if (!card || !board) return false;
  return actions.getActionsByBoardType(card, board).includes(action);
};
