import { event } from "vue-gtag";

import { NamedKey, noKey } from "@/components/utils/Shortcuts";
import { Icon } from "@/model/icon";
import { useBoardStore } from "@/store/board";
import { actionEvent } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

export type Action<
  TParams extends unknown[] = [],
  TReturn = void,
  TState = Record<string, never>,
> = ((source: ActionSource, ...params: TParams) => TReturn) & {
  data: {
    id: string;
    name: string;
    history?: {
      merge?: boolean;
      saveState: (...params: TParams) => TState;
    };
    shortcut?: NamedKey;
    icon?: Icon;
    startPreview?: () => void;
    stopPreview?: () => void;
  };
};

export type ActionSource =
  | "mainMenu"
  | "contextMenu"
  | "modal"
  | "keyboard"
  | "mouse"
  | "board"
  | "card"
  | "dragDrop"
  | "keyboardDrag"
  | "internal";

const ignoreActions: string[] = [];
const lastActionId: Record<string, string> = {};

function trackAction(
  action: Action<any, any, any>,
  source: ActionSource,
  ...params: any
) {
  if (source !== "internal" && !ignoreActions.includes(action.data.id)) {
    if (action.data.history?.merge) {
      const id = action.data.history.saveState(...params).id;
      if (id !== lastActionId[action.data.id]) {
        lastActionId[action.data.id] = id;
        sendEvent();
      }
    } else {
      sendEvent();
    }
  }

  function sendEvent() {
    // For google analytics
    event(action.data.id, {
      page_title: useBoardStore().board?.type,
      source,
    });

    // For amplitude
    trackEvent(
      actionEvent({
        eventName: action.data.id,
        source,
        board: useBoardStore().board?.type,
      }),
    );
  }
}

export function action<TParams extends unknown[], TReturn, TState>(
  execute: ((...params: TParams) => TReturn) & {
    trigger?: ActionTrigger;
  },
  data: {
    name?: string;
    history?: {
      merge?: boolean;
      saveState: (...params: TParams) => TState;
    };
    shortcut?: NamedKey;
    icon?: Icon;
    startPreview?: (action: Action<TParams, TReturn, TState>) => void;
    stopPreview?: (action: Action<TParams, TReturn, TState>) => void;
  } = {},
): Action<TParams, TReturn, TState> {
  const action = ((source: ActionSource, ...params: TParams) => {
    if (params[0] !== "preview") {
      trackAction(action, source, ...params);
    }

    if (source === "keyboard") {
      execute.trigger = "keyboard-shortcut";
    }

    return execute(...params);
  }) as Action<TParams, TReturn, TState>;
  action.data = {
    id: "",
    name: data.name || "",
    history: data.history,
    shortcut: data.shortcut || noKey,
    icon: data.icon,
  };
  if (data.startPreview) {
    action.data.startPreview = () => data.startPreview!(action);
  }
  if (data.stopPreview) {
    action.data.stopPreview = () => data.stopPreview!(action);
  }
  return action;
}

export function defineActions<
  T extends { [id in string]: Action<any, any, any> },
>(name: string, actions: T): { [id in keyof T]: T[id] } {
  Object.entries(actions).forEach(([id, action]) => {
    action.data.id = name + "." + id;
  });
  return actions as unknown as { [id in keyof T]: T[id] };
}

const actionTriggers = ["keyboard-shortcut"] as const;
type ActionTrigger = (typeof actionTriggers)[number];

// Workaround so we can know that an action was called by a specific trigger. Ex. keyboard shortcut
export function getActionTrigger(
  action: (...params: any[]) => void,
): ActionTrigger | undefined {
  if ("trigger" in action && isValidTrigger(action.trigger)) {
    return action.trigger;
  }
}

const isValidTrigger = (trigger: unknown): trigger is ActionTrigger => {
  return actionTriggers.includes(trigger as ActionTrigger);
};
