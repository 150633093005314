<script lang="ts" setup>
import { drawActions } from "@/action/drawActions";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { drawShapeDeleted } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

import { shapeKey } from "../injectKeys";

const shape = injectStrict(shapeKey);

async function remove() {
  drawActions.removeShape("contextMenu", shape.value!.id);
  trackEvent(drawShapeDeleted("action-menu"));
}
</script>

<template>
  <ActionMenuItem
    width="auto"
    name="delete"
    :tooltip-text="$t('general.delete')"
    :aria-label="$t('label.actionMenu.delete')"
    @click="remove"
  >
    <SvgIcon name="action/delete" width="20" height="20" />
  </ActionMenuItem>
</template>
