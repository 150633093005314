<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import FlashIcon from "@/components/ui/FlashIcon/FlashIcon.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { InfoLevel } from "@/model/baseTypes";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

import RiskyLinksItemTooltip from "../tooltips/RiskyLinksItemTooltip.vue";
import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();

const { t } = useI18n();

const riskyLinks = computed(() => {
  const { critical, risky } = useLinkStore().riskyLinkCount;
  return [
    {
      state: "error",
      label: t("linkState.criticalLink.count", critical),
      id: "critical-links",
    },
    {
      state: "warn",
      label: t("linkState.riskyLink.count", risky),
      id: "risky-links",
    },
  ] as Array<{ state: InfoLevel; label: string; id: string }>;
});

const selection = computed({
  get: () =>
    riskyLinks.value.filter((item) => store.selectedLinkStates.has(item.state)),
  set: (values: Array<{ state: InfoLevel }>) => {
    store.selectedLinkStates = new Set(values.map((val) => val.state));
  },
});
</script>

<template>
  <DropdownMenu
    class="search-select"
    width="sm"
    stay-open-on-content-click
    @open="trackFilterOpen('risky-links')"
  >
    <template #trigger="{ isOpen }">
      <BaseTooltip position="bottom" class="flash-icons">
        <BaseButton
          variant="ghost"
          color="grey"
          class="flash-icons-button"
          data-dropdown-trigger
          :aria-label="
            $t('label.search.filterButton', { value: $t('linkState.title') })
          "
          :aria-expanded="isOpen"
          aria-haspopup="listbox"
          :activated="isOpen"
        >
          <FlashIcon
            v-if="store.selectedRiskyLinks.length === 0"
            link-state="default"
          />
          <div
            v-else
            class="flash-icons-wrapper"
            :class="{
              'flash-icons-multiple': store.selectedRiskyLinks.length > 1,
            }"
          >
            <FlashIcon
              v-for="state in store.selectedRiskyLinks"
              :key="state"
              :link-state="state"
            />
          </div>
          <InfoMarker
            v-if="store.selectedRiskyLinks.length"
            :top="4"
            :right="4"
          />
        </BaseButton>
        <template #content>
          <RiskyLinksItemTooltip :states="store.selectedRiskyLinks" />
        </template>
      </BaseTooltip>
    </template>
    <SelectableList
      v-model="selection"
      v-keyboard-nav
      :values="riskyLinks"
      :header="$t('linkState.riskyLinks')"
    >
      <template #default="{ value }">
        <span>
          <FlashIcon :link-state="value.state" />
          {{ value.label }}
        </span>
      </template>
    </SelectableList>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/variables";
@use "@/styles/z-index";

.flash-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
}

.flash-icons-button.flash-icons-button {
  display: block;
  line-height: 12px; // Keep icons centered vertically, with or without the status dot
}

.flash-icons-wrapper {
  display: flex;
  align-items: center;

  .flash-icon {
    &:first-child {
      position: relative;
    }

    &:nth-child(2) {
      margin: 0 -0.7em;
    }
  }
}

.flash-icons-multiple {
  text-align: left;
  width: 30px;
}
</style>
