import { ApplicationError } from "@/error/ApplicationError";

export class NoSuccessError extends ApplicationError {
  constructor(
    endpoint: string,
    contexts: { request: Record<string, unknown>; response: NoSuccessResponse },
  ) {
    super(
      "NoSuccessError",
      `Server call unsuccessful to ${endpoint}`,
      contexts,
    );
  }

  get response(): NoSuccessResponse {
    return this.contexts.response as NoSuccessResponse;
  }
}

interface NoSuccessResponse extends Record<string, unknown> {
  message: string;
  code?: string;
  details?: unknown;
}
