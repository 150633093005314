<script setup lang="ts">
import { computed } from "vue";

import { toggleActions } from "@/action/toggleActions";
import RunningTimers from "@/components/Timer/RunningTimers.vue";
import UserActions from "@/components/UserActions/UserActions.vue";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";

import MenuItemDelimiter from "../MenuItemDelimiter.vue";
import AlmStatus from "./components/AlmStatus.vue";
import AppZoom from "./components/AppZoom.vue";
import BoardUsers from "./components/BoardUsers.vue";
import FacilitationMenu from "./components/FacilitationMenu.vue";
import RiskyLinks from "./components/RiskyLinks.vue";

const searchMenuStore = useSearchMenuStore();
const isZoomEnabled = computed(() => useBoardStore().isCurrentBoardFluid);
const showRiskyLinks = computed(() => useBoardStore().showRiskyLinks);
const links = computed(() => useLinkStore().hasRiskyLinks);
const activeTimers = computed(() => {
  const allTimers = [
    ...useTimerStore().boardTimers,
    ...useTimerStore().artTimers,
  ];
  return allTimers.some((timer) => timer.data.state === "running");
});

function toggleTimer() {
  toggleActions.showTimer("mainMenu");
}
</script>

<template>
  <div>
    <AlmStatus />

    <template
      v-if="links && showRiskyLinks && !searchMenuStore.isSearchSidebarOpen"
    >
      <RiskyLinks />
      <MenuItemDelimiter />
    </template>

    <BoardUsers />
    <FacilitationMenu />
    <RunningTimers v-if="activeTimers" @click.stop="toggleTimer" />
    <MenuItemDelimiter />

    <template v-if="isZoomEnabled">
      <AppZoom />
      <MenuItemDelimiter />
    </template>

    <UserActions is-user-on-board />
  </div>
</template>
