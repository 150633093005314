<script setup lang="ts">
import { sortBy } from "lodash-es";
import { onMounted, ref, watch } from "vue";

import ComboBox from "@/components/ComboBox/ComboBox.vue";
import { AuthUser } from "@/model/user";
import {
  UserWithTeams,
  searchUsers,
  usersInTeam,
} from "@/services/userAdmin.service";
import { addReactiveAlmMapping } from "@/services/userAlmMapping.service";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";

import NoUserEntry from "./components/NoUserEntry.vue";
import UserEntry, { User } from "./components/UserEntry.vue";

const props = defineProps<{ selected: AuthUser | null }>();

const currentTeam = useTeamStore().team.current;

const emit = defineEmits<{ select: [user: AuthUser | null] }>();
const users = ref<User[]>([]);

const searchVal = ref<string>("");
const teamUsers = ref<User[]>([]);

const selected = props.selected
  ? addReactiveAlmMapping(makeUser(props.selected))
  : null;

const current = makeUser(useUserStore().currentUser!);

onMounted(async () => {
  const rawUsers = await usersInTeam(currentTeam);
  teamUsers.value = rawUsers.map((raw) => makeUser(raw));
});

watch(
  [searchVal, teamUsers],
  async () => {
    const searchUsers =
      searchVal.value.length < 2
        ? currentAndTeamUsers()
        : [...filteredTeamUsers(), ...(await searchNonTeamUsers())];

    const currentUserFirst = (user: User) => user.id !== current.id;

    users.value = sortBy(searchUsers.filter(notSelected), [
      currentUserFirst,
      "foreign",
      "name",
    ]);
  },
  { immediate: true },
);

/**
 * Returns the team users, with the current user added if they aren't already in the team.
 */
function currentAndTeamUsers() {
  const userOnCurrentTeam = teamUsers.value.some((u) => u.id === current.id);
  return userOnCurrentTeam ? teamUsers.value : [current, ...teamUsers.value];
}

function filteredTeamUsers() {
  return teamUsers.value.filter((user) =>
    user.name.toLowerCase().includes(searchVal.value.toLowerCase()),
  );
}

async function searchNonTeamUsers() {
  return (await searchUsers(searchVal.value))
    .filter((user) => !filteredTeamUsers().find((tu) => tu.id === user.id))
    .map((user) => makeUser(user, isForeign(user)));
}

function notSelected(user: AuthUser) {
  return user.id !== props.selected?.id;
}

function isForeign(user: UserWithTeams) {
  return !user.teams.find((team) => team.id === currentTeam.id);
}

function makeUser(user: AuthUser, foreign = false): User {
  return { ...user, foreign };
}

function select(user: AuthUser | null) {
  emit("select", user);
}
</script>

<template>
  <ComboBox
    v-model="searchVal"
    :title="$t('general.assignee')"
    :placeholder="$t('action.searchUsers')"
    :icon-title="$t('label.userSelector.searchTitle')"
    :list-len="users?.length"
  >
    <UserEntry v-if="selected" :user="selected" active static />
    <NoUserEntry v-if="selected" @select="select" />
    <UserEntry
      v-for="user in users"
      :key="user.id"
      :user="user"
      @select="select"
    />
  </ComboBox>
</template>
