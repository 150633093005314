export function utcDateToLocalMidnight(timestamp: number): Date {
  const date = new Date(timestamp * 1000);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function isBefore(d1: Date, d2: Date) {
  return d1.getTime() < d2.getTime();
}

export function isAfter(d1: Date, d2: Date) {
  return d1.getTime() > d2.getTime();
}

export function plusDays(date: Date, days: number) {
  const d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
}

export function plusHours(date: Date, hours: number) {
  const d = new Date(date);
  d.setHours(d.getHours() + hours);
  return d;
}

export function lastWeekDay(d: Date) {
  switch (d.getDay()) {
    case 0: //sunday
      return plusDays(d, -2);
    case 1: //monday
      return plusDays(d, -3);
    default:
      return plusDays(d, -1);
  }
}

export function weekDaysBetween(d1: Date, d2: Date) {
  let weekdays = 0;
  const d = new Date(d1);
  while (isBefore(d, atDayStart(d2))) {
    if (isWeekday(d)) {
      weekdays++;
    }
    d.setDate(d.getDate() + 1);
  }
  if (isWeekday(d2)) {
    weekdays += dayFraction(d2);
  }
  return weekdays;
}

function dayFraction(date: Date) {
  return (
    (date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600) / 24
  );
}

function isWeekday(date: Date) {
  return date.getDay() !== 0 && date.getDay() !== 6;
}

export function atDayStart(d: Date) {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function minuteDiff(d1: Date, d2: Date) {
  return (d2.getTime() - d1.getTime()) / (1000 * 60);
}

export function dayDiff(d1: Date, d2: Date) {
  return minuteDiff(d1, d2) / (60 * 24);
}
