/**
 * Returns the current time in the format HH:MM:SS.mmm
 */
export function now(): string {
  const date = new Date();
  return (
    ("" + date.getUTCHours()).padStart(2, "0") +
    ":" +
    ("" + date.getUTCMinutes()).padStart(2, "0") +
    ":" +
    ("" + date.getUTCSeconds()).padStart(2, "0") +
    "." +
    ("" + date.getUTCMilliseconds()).padStart(3, "0")
  );
}

/**
 * Returns the current time, rounted to the minute (HH:MM)
 */
export function nowMinutes(): string {
  const date = new Date();
  return (
    ("" + date.getUTCHours()).padStart(2, "0") +
    ":" +
    ("" + date.getUTCMinutes()).padStart(2, "0")
  );
}

/**
 * Fetches the Google favicon and returns the time it took to load it
 *
 * @param timeout Maximum time to wait for the image to load
 */
export function fetchGoogleIcon(timeout = 5000): Promise<number> {
  return new Promise((resolve, reject) => {
    const start = performance.now();
    const t = window.setTimeout(
      () => reject(`Timeout: ${timeout} ms`),
      timeout,
    );
    const img = new Image();
    img.onload = () => {
      resolve(performance.now() - start);
      clearTimeout(t);
    };
    img.onerror = () => {
      reject("Error");
      clearTimeout(t);
    };
    const random = start.toString(16);
    // add a random query parameter to avoid caching
    img.src = `https://www.google.com/favicon.ico?no-cache=${random}`;
  });
}
