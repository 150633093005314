<script setup lang="ts">
import { ref } from "vue";

withDefaults(
  defineProps<{
    show: boolean;
    points?: number[];
    current: number | undefined;
  }>(),
  {
    points: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
);
const emit = defineEmits<{ input: [value?: number] }>();

const rootRef = ref<HTMLElement>();

function select(i?: number) {
  emit("input", i);
}
</script>

<template>
  <div
    ref="rootRef"
    class="points-select action"
    tabindex="-1"
    @blur="select()"
  >
    <div v-if="show" ref="popup" v-focus-trap class="items">
      <button
        v-for="point in points"
        :key="point"
        class="points-button"
        :class="{ current: point === current }"
        @click.stop="select(point)"
      >
        {{ point }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "sass:color";
@use "@/styles/z-index";

.points-select {
  z-index: z-index.$low + 1;
  position: absolute;
  text-align: right;
  display: inline-block;
  font-size: font.$size-small;

  &:focus {
    outline: none;
  }

  .items {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    background-color: colors-old.$modal-background-color;
    width: 102px;
    padding: 2px;
    border-radius: 6px;
    border: 1px solid colors-old.$border-menu-color;

    .points-button {
      flex-grow: 1;
      background-color: colors-old.$light-background-color;
      border-radius: 4px;
      margin: 2px;
      height: 1.75rem;
      line-height: 1.75rem;
      width: 1.75rem;
      max-width: 1.75rem;
      vertical-align: middle;

      &:focus,
      &:focus-within,
      &:hover {
        background-color: color.scale(colors-old.$back-color, $lightness: -15%);
        outline: revert;
      }

      &.current {
        color: colors-old.$primary-color;
        background-color: rgba(
          colors-old.$primary-color,
          colors-old.$background-color-transparency
        );
      }
    }
  }
}
</style>
