<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { linkActions } from "@/action/linkActions";
import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import {
  isARTWorkItem,
  isDependency,
  isObjective,
  isOtherItem,
  isSticky,
  isTeamWorkItem,
} from "@/components/utils/linkableItems";
import { LinkableCard, LinkableItem } from "@/model/link";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { linkId, useLinkStore } from "@/store/link";
import { useSelectionStore } from "@/store/selection";
import { useWorkModeStore } from "@/store/workMode";
import {
  LinkRemoveTrigger,
  stickyLinkCreated,
  stickyLinkRemoved,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import FlagInfo from "./components/FlagInfo.vue";
import RowWrapper from "./components/RowWrapper.vue";
import StatusInfo from "./components/StatusInfo.vue";

const { t } = useI18n();

const emit = defineEmits(["unlink"]);

const props = defineProps<{
  targetCardIds: string[];
  link: LinkableItem;
  trigger: Extract<LinkRemoveTrigger, "linking-modal" | "linking-chip">;
}>();

const linkAnnouncement = ref("");

const isExecutionMode = computed(() => useWorkModeStore().isExecutionMode);

function toggleLink(item: LinkableItem) {
  if (useBoardStore().areMultipleStickiesSelected) {
    useSelectionStore().addLinkingToHistory();
  }

  if (item.linked) {
    item.linked = false;
    props.targetCardIds.forEach((id) => {
      emit("unlink");
      if (isObjective(item)) {
        linkActions.removeObjective(
          "modal",
          id,
          item.boardId,
          item.id,
          props.trigger,
        );
      } else if (isSticky(item)) {
        const linkId = getLinkId(id, item);
        if (!linkId) {
          throw new Error(
            `Unable to find a link between: ${id} and ${item.id}`,
          );
        }

        const { fromId, fromType, fromBoardType, toId, toType } =
          getLinkStickyData(id, item);
        trackEvent(
          stickyLinkRemoved(
            fromId,
            fromType,
            fromBoardType,
            toId,
            toType,
            props.trigger,
          ),
        );
        linkActions.remove("modal", linkId);
      }
    });
  } else {
    item.linked = true;
    props.targetCardIds.forEach((id) => {
      if (isObjective(item)) {
        linkActions.addObjective(
          "modal",
          id,
          item.boardId,
          item.id,
          "linking-modal", // objective can only be added in the Link Modal
        );
      } else if (isSticky(item)) {
        const { fromId, fromType, fromBoardType, toId, toType } =
          getLinkStickyData(id, item);
        trackEvent(
          stickyLinkCreated(
            fromId,
            fromType,
            fromBoardType,
            toId,
            toType,
            props.trigger,
          ),
        );
        linkActions.add("modal", { fromId: id, toId: item.id });
      }
    });
  }

  linkAnnouncement.value = t("linkModal.linkAnnouncement", {
    kind: isObjective(item) ? t("linkModal.objective") : t("linkModal.sticky"),
    text: item.text,
    action: item.linked ? t("linkModal.linked") : t("linkModal.unlinked"),
  });

  setTimeout(() => {
    linkAnnouncement.value = "";
  }, 1000);
}

function getLinkStickyData(cardId: string, item: LinkableCard) {
  const fromCard = useCardStore().cards[cardId];
  return {
    fromId: fromCard.id,
    fromType: fromCard.type.functionality,
    fromBoardType: useBoardStore().currentBoard().type,
    toId: item.id,
    toType: item.type.functionality,
  };
}

function getLinkId(fromId: string, to: LinkableItem) {
  const from = useCardStore().cards[fromId];
  const link = useLinkStore().links.find(
    (link) =>
      (link.from === linkId(from) || link.from === linkId(to)) &&
      (link.to === linkId(from) || link.to === linkId(to)),
  );
  return link?.id;
}

const objectiveArtOrTeam = (boardId: string) => {
  const board = useBoardsStore().boardById(boardId);
  if (board.type === "program") {
    return useArtStore().artById(board.artId)?.name ?? t("general.noArt");
  } else if (board.type === "team") {
    return board.team.name;
  }
};

const tooltip = computed(() =>
  props.link.linked
    ? t("linkModal.removeLink", { text: props.link.text })
    : t("linkModal.addLink", { text: props.link.text }),
);
</script>

<template>
  <RowWrapper>
    <template #first-row>
      <SvgIcon
        v-if="isObjective(link)"
        name="objective/team-objectives"
        class="objective-icon"
        height="16"
        width="16"
      />
      <TypeDot v-else size="medium" :color="link.type.color" />
      <div class="text-wrapper">
        <BaseTooltip position="bottom">
          <div class="text">{{ link.text }}</div>
          <template #content>{{ link.text }}</template>
        </BaseTooltip>
      </div>
    </template>
    <template #second-row>
      <!-- ART work item -->
      <template v-if="isARTWorkItem(link)">
        <StatusInfo
          v-if="link.status"
          :status="link.status"
          data-testid="art-work-item-status"
        />
        <div>{{ link.type.name }}</div>
        <div v-if="link.almId">
          {{ link.almId }}
        </div>
        <div v-if="link.team">
          {{ link.team.name }}
        </div>
        <div v-if="link.iteration">
          {{ link.iteration.name }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>

      <!-- Dependency -->
      <template v-if="isDependency(link)">
        <div>{{ link.type.name }}</div>
        <div>
          <span v-if="link.precondTeam">
            {{ link.precondTeam.name }}
          </span>
          <span v-else class="no-team">
            {{ $t("linkChip.noDependentTeam") }}
          </span>
          <SvgIcon
            name="arrow/right-thin"
            class="arrow-icon"
            width="10"
            height="100%"
          />
          <span v-if="link.dependTeam">
            {{ link.dependTeam.name }}
          </span>
          <span v-else class="no-team">
            {{ $t("linkChip.noPrecondTeam") }}
          </span>
        </div>
        <div v-if="link.iteration">
          {{ link.iteration.name }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>

      <!-- Team work item -->
      <template v-if="isTeamWorkItem(link)">
        <StatusInfo
          v-if="link.status"
          :status="link.status"
          data-testid="team-work-item-status"
        />
        <div>{{ link.type.name }}</div>
        <div v-if="!isExecutionMode && link.points">
          {{ $t("linkChip.storyPoints", { points: link.points }) }}
        </div>
        <div v-if="link.almId">
          {{ link.almId }}
        </div>
        <div v-if="link.team">
          {{ link.team.name }}
        </div>
        <div v-if="link.iteration">
          {{ link.iteration.name }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>

      <!-- Objective -->
      <template v-if="isObjective(link)">
        <div v-if="link.type === 'committed'">
          {{ $t("objectives.committed") }}
        </div>
        <div v-else>
          {{ $t("objectives.uncommitted") }}
        </div>
        <div>
          {{ objectiveArtOrTeam(link.boardId) }}
        </div>
      </template>

      <!-- Other -->
      <template v-if="isOtherItem(link)">
        <div>{{ link.type.name }}</div>
        <div v-if="link.team">
          {{ link.team.name }}
        </div>
        <div v-if="link.iteration">
          {{ link.iteration.name }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>
    </template>
    <template #button>
      <IconButton
        :id="'link-button-' + link.id"
        :key-override="'link-button-' + link.id"
        variant="ghost"
        :activated="link.linked"
        icon="action/unlink"
        data-testid="toggle-link-button"
        :aria-label="tooltip"
        :tooltip="tooltip"
        @click="toggleLink(link)"
      />
      <ScreenReaderMessage aria-live="polite">
        {{ linkAnnouncement }}
      </ScreenReaderMessage>
    </template>
  </RowWrapper>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;

.no-team {
  border: 1px dashed colors-old.$text-secondary-color;
  border-radius: 8px;
  padding: 0 4px;
}

.arrow-icon {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
