<script async setup lang="ts">
import { computed } from "vue";

import { Icon } from "@/model/icon";

import { getIconComponent } from "./loader";

interface Props {
  name: Icon;
}
const props = defineProps<Props>();

const IconComponent = computed(() => getIconComponent(props.name));
</script>

<template>
  <IconComponent />
</template>
