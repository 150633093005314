<template>
  <input
    ref="input"
    :placeholder="placeholder"
    :maxlength="maxLength + 1"
    :value="value"
    :readonly="readOnly"
    class="input-field"
    :class="{ edit, underline, error }"
    @click="init"
    @blur="edit = false"
    @input="update"
    @change="change"
    @keydown="down"
  />
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import LabeledInput from "@/mixins/LabeledInput";

const allowedChars: Record<string, string> = {
  int: "0123456789",
  float: ".0123456789",
};

@Component({ emits: ["input", "change"] })
export default class InputField extends mixins(LabeledInput) {
  @Prop() readonly value!: string;
  @Prop(Number) readonly maxLength!: number;
  @Prop(Boolean) readonly readOnly: boolean | undefined;
  @Prop(String) readonly placeholder: string | undefined;
  @Prop(Boolean) readonly underline: boolean | undefined;
  @Prop(String) readonly type: string | undefined;

  @Ref("input") readonly inputElem!: HTMLInputElement;

  edit = false;
  initVal = "";

  mounted() {
    this.setForId(this.$attrs.id as string);
  }

  init() {
    if (this.readOnly !== true) {
      this.edit = true;
      this.initVal = this.value;
      this.inputElem.focus();
      this.inputElem.setSelectionRange(0, ("" + this.value).length);
    }
  }

  down(e: KeyboardEvent) {
    // key can be undefined when using autofill
    if (!e.key) {
      return;
    }
    if (e.key === "Escape") {
      this.inputElem.value = this.initVal;
      this.inputElem.blur();
    }
    if (e.key === "Enter") {
      this.inputElem.blur();
      e.stopPropagation();
    }
    if (e.key.length === 1) {
      const allowed = allowedChars[this.type || ""];
      if (allowed && !allowed.includes(e.key)) {
        e.preventDefault();
      }
      const hasSelection =
        (this.inputElem.selectionEnd || 0) -
          (this.inputElem.selectionStart || 0) >
        0;
      const noMoreInput =
        this.inputElem.value.length >= this.maxLength && !hasSelection;
      if (this.type === "float") {
        const hasDot = this.inputElem.value.includes(".");
        if (
          (e.key === "." && hasDot) ||
          (e.key !== "." && !hasDot && noMoreInput)
        ) {
          e.preventDefault();
        }
      } else {
        if (noMoreInput) {
          e.preventDefault();
        }
      }
    }
  }

  update() {
    this.$emit("input", this.inputElem.value);
  }

  change() {
    this.$emit("change", this.inputElem.value);
  }

  // Expose focus to parent
  focus() {
    this.inputElem?.focus();
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.input-field {
  width: 100%;
  background-color: colors-old.$back-color;
  color: inherit;
  border: 0.1em solid colors-old.$input-border-color;
  border-radius: variables.$border-radius;
  padding: 0.7em 1em;

  &.underline {
    background-color: transparent;
    border: none;
    border-bottom: 0.1em solid colors-old.$input-border-color;
    border-radius: 0;
    padding: 0.5em 0;
  }

  &.edit {
    background-color: colors-old.$back-color;
  }

  &.error {
    border-color: colors-old.$error-color;
    color: colors-old.$error-color;
  }

  &:focus {
    outline: none;
  }
}
</style>
