<template>
  <div>
    <div ref="committedHeader" class="objectives-header header-committed-group">
      <h3>
        <button
          ref="committedTrigger"
          class="collapsable-area"
          data-testid="collapsable-area"
          :aria-expanded="committedIsExpanded"
          :aria-controls="`committed-group-${board.id}`"
          @click.stop="committedIsExpanded = !committedIsExpanded"
        >
          <SvgIcon
            v-if="committedIsExpanded"
            name="chevron/down"
            width="20"
            height="20"
          />
          <SvgIcon v-else name="chevron/right" width="20" height="20" />
          <div>
            <p class="objectives-header-title">
              {{ $t("objectives.committed") }}
            </p>
            <p class="objective-count">
              {{ $t("objectives.count", objectives.length) }}
            </p>
          </div>
        </button>
      </h3>
      <div class="objectives-actions">
        <BaseButton
          variant="ghost"
          color="grey"
          icon-before="objective/description"
          @click="toggleGlobalDescriptions"
          >{{
            expandDescriptions
              ? $t("objectives.hideDescriptionButton")
              : $t("objectives.showDescriptionButton")
          }}
        </BaseButton>
        <template v-if="!readonly">
          <MenuItemDelimiter />
          <AddObjectiveButton
            ref="addCommittedObjectiveButton"
            class="objective-btn"
            @click="
              addCommittedObjectiveIsActive = true;
              committedIsExpanded = true;
            "
          />
        </template>
      </div>
    </div>
    <div
      v-if="committedIsExpanded"
      :id="`committed-group-${board.id}`"
      data-testid="committed-group-container"
      class="objectives-container"
    >
      <AddObjectiveCard
        v-if="addCommittedObjectiveIsActive"
        type="committed"
        :board="board"
        @cancel="cancelAddCommitted"
        @added="focusOnObjective"
      />
      <draggable
        :list="objectives"
        item-key="id"
        ghost-class="objective-drag-state"
        class="committed-group"
        :group="group"
        :move="onMove"
        :disabled="readonly"
        animation="100"
        @start="isDragging = true"
        @end="onEndDragging"
      >
        <template #item="{ index, element: objective }">
          <ObjectiveCard
            :index="index + 1"
            :objective="objective"
            :board="board"
            :is-dragging="isDragging"
            :expand-descriptions="expandDescriptions"
            :has-status-distribution="hasStatusDistribution"
            :can-move-up="index > 0"
            :can-move-down="index < objectives.length - 1"
            type="committed"
            @removed="onObjectiveRemoved('committed')"
          />
        </template>
      </draggable>
      <div v-if="showNoObjectives" class="no-objective-placeholder">
        <p>{{ $t("objectives.none") }}</p>
      </div>
    </div>

    <div
      ref="uncommittedHeader"
      class="objectives-header header-uncommitted-group"
    >
      <h3>
        <button
          ref="uncommittedTrigger"
          class="collapsable-area"
          data-testid="collapsable-area"
          :aria-expanded="uncommittedIsExpanded"
          :aria-controls="`uncommitted-group-${board.id}`"
          @click.stop="uncommittedIsExpanded = !uncommittedIsExpanded"
        >
          <SvgIcon
            v-if="uncommittedIsExpanded"
            name="chevron/down"
            width="20"
            height="20"
          />
          <SvgIcon v-else name="chevron/right" width="20" height="20" />
          <div>
            <p class="objectives-header-title">
              {{ $t("objectives.uncommitted") }}
            </p>
            <p class="objective-count">
              {{ $t("objectives.count", stretchObjectives.length) }}
            </p>
          </div>
        </button>
      </h3>
      <AddObjectiveButton
        v-if="!readonly"
        ref="addUncommittedObjectiveButton"
        class="objective-btn"
        @click="
          addUncommittedObjectiveIsActive = true;
          uncommittedIsExpanded = true;
        "
      />
    </div>
    <div
      v-if="uncommittedIsExpanded"
      :id="`uncommitted-group-${board.id}`"
      data-testid="uncommitted-group-container"
      class="objectives-container"
    >
      <AddObjectiveCard
        v-if="addUncommittedObjectiveIsActive"
        type="uncommitted"
        :board="board"
        @cancel="cancelAddUncommitted"
        @added="focusOnObjective"
      />

      <draggable
        :list="stretchObjectives"
        item-key="id"
        ghost-class="objective-drag-state"
        draggable=".objective-card"
        class="uncommitted-group"
        :group="group"
        :move="onMove"
        :disabled="readonly"
        animation="100"
        @start="isDragging = true"
        @end="onEndDragging"
      >
        <template #item="{ index, element: objective }">
          <ObjectiveCard
            :index="index + 1"
            :objective="objective"
            :board="board"
            :is-dragging="isDragging"
            :expand-descriptions="expandDescriptions"
            :has-status-distribution="hasStatusDistribution"
            :can-move-up="index > 0"
            :can-move-down="index < stretchObjectives.length - 1"
            type="uncommitted"
            @removed="onObjectiveRemoved('uncommitted')"
          />
        </template>
      </draggable>
      <div v-if="showNoStretchObjectives" class="no-objective-placeholder">
        <p>{{ $t("objectives.none") }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { nextTick } from "vue";
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import draggable from "vuedraggable";

import { objectiveActions } from "@/action/objectiveActions";
import MenuItemDelimiter from "@/components/menu/MenuItemDelimiter.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import ShortcutUser from "@/mixins/ShortcutUser";
import { BoardWithObjectives } from "@/model/board";
import { Objective } from "@/model/objective";
import { useUserStore } from "@/store/user";
import { i18n } from "@/translations/i18n";

import AddObjectiveButton from "./AddObjectiveButton.vue";
import AddObjectiveCard from "./AddObjectiveCard.vue";
import ObjectiveCard from "./ObjectiveCard.vue";

@Component({
  components: {
    SvgIcon,
    ObjectiveCard,
    AddObjectiveButton,
    AddObjectiveCard,
    draggable,
    MenuItemDelimiter,
    BaseButton,
  },
})
export default class ObjectivesList extends mixins(ShortcutUser) {
  @Prop({ required: true, type: Object }) readonly board!: BoardWithObjectives;
  @Prop({ type: Boolean, default: true })
  readonly addObjectiveByKeyBoard!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly isExpendable!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly hasStatusDistribution!: boolean;
  @Ref("committedHeader") readonly committedHeaderElm!: HTMLInputElement;
  @Ref("uncommittedHeader") readonly uncommittedHeaderElm!: HTMLInputElement;
  @Ref("addCommittedObjectiveButton")
  readonly addCommittedObjectiveButtonElm!: HTMLInputElement;
  @Ref("addUncommittedObjectiveButton")
  readonly addUncommittedObjectiveButtonElm!: HTMLInputElement;
  @Ref("committedTrigger") readonly committedTriggerElm!: HTMLInputElement;
  @Ref("uncommittedTrigger") readonly uncommittedTriggerElm!: HTMLInputElement;
  addCommittedObjectiveIsActive = false;
  addUncommittedObjectiveIsActive = false;
  isDragging = false;
  committedIsExpanded = true;
  uncommittedIsExpanded = true;
  @Prop({ type: String, default: "objectives" }) readonly group!: string;

  expandDescriptions = false;

  created() {
    if (this.addObjectiveByKeyBoard) {
      this.shortcut("KeyN", () => {
        this.addUncommittedObjectiveIsActive = true;
        this.uncommittedIsExpanded = true;
      });
    }
  }

  toggleGlobalDescriptions() {
    this.expandDescriptions = !this.expandDescriptions;
  }

  get title() {
    return this.board.type === "team"
      ? i18n.global.t("teamObjectivesModal.objectives", {
          teamName: this.board.team.name,
        })
      : i18n.global.t("objectives.programObjectives");
  }

  get objectives() {
    return this.board.objectives;
  }

  get stretchObjectives() {
    return this.board.stretchObjectives;
  }

  get showNoObjectives() {
    return this.objectives.length === 0;
  }

  get showNoStretchObjectives() {
    return this.stretchObjectives.length === 0;
  }

  get readonly() {
    return !useUserStore().isAllowed("edit");
  }

  addDragStateToCommittedHeader() {
    this.committedHeaderElm.classList.add("drag-state");
  }

  removeDragStateFromCommittedHeader() {
    this.committedHeaderElm.classList.remove("drag-state");
  }

  addDragStateToUncommittedHeader() {
    this.uncommittedHeaderElm.classList.add("drag-state");
  }

  removeDragStateFromUncommittedHeader() {
    this.uncommittedHeaderElm.classList.remove("drag-state");
  }

  hasDraggedToCommittedHeader(element: Element) {
    return element.classList
      ? element.classList.contains("header-committed-group")
      : false;
  }

  hasDraggedToUncommittedHeader(element: Element) {
    return element.classList
      ? element.classList.contains("header-uncommitted-group")
      : false;
  }

  onEndDragging(event: any) {
    /**
     * updates the commitment state for the objective that was dragged
     */
    this.isDragging = false;
    const hasDraggedBetweenUncommittedObjectives =
      event.to.classList.contains("uncommitted-group");

    const isStretch =
      hasDraggedBetweenUncommittedObjectives ||
      this.hasDraggedToUncommittedHeader(event.to);

    const hasDraggedToHeader =
      this.hasDraggedToCommittedHeader(event.to) ||
      this.hasDraggedToUncommittedHeader(event.to);

    let index = event.newIndex;

    if (hasDraggedToHeader) {
      index = isStretch ? 0 : this.objectives.length;
    }

    objectiveActions.move(
      "dragDrop",
      this.board.id,
      event.item.__draggable_context.element.id,
      {
        stretch: isStretch,
        rank: index ? index : 0,
      },
    );
    this.removeDragStateFromUncommittedHeader();
    this.removeDragStateFromCommittedHeader();
  }

  onMove(event: any, _originalEvent: any) {
    if (
      this.hasDraggedToCommittedHeader(event.to) &&
      !this.committedIsExpanded
    ) {
      this.addDragStateToCommittedHeader();
      this.removeDragStateFromUncommittedHeader();
    } else if (
      this.hasDraggedToUncommittedHeader(event.to) &&
      !this.uncommittedIsExpanded
    ) {
      this.addDragStateToUncommittedHeader();
      this.removeDragStateFromCommittedHeader();
    } else {
      this.removeDragStateFromCommittedHeader();
      this.removeDragStateFromUncommittedHeader();
    }
  }

  cancelAddCommitted() {
    this.addCommittedObjectiveIsActive = false;
    this.addCommittedObjectiveButtonElm?.focus();
  }

  cancelAddUncommitted() {
    this.addUncommittedObjectiveIsActive = false;
    this.addUncommittedObjectiveButtonElm?.focus();
  }

  /**
   * Focus on the objective card after adding it (by id)
   */
  async focusOnObjective(objective: Objective) {
    await nextTick();
    const objectiveCard = document.getElementById(
      `objective-card-${objective.id}`,
    );
    objectiveCard?.focus();
  }

  // Move focus to the section header (trigger) when an objective is removed
  async onObjectiveRemoved(type: "committed" | "uncommitted") {
    await nextTick(); // Wait for focus-trap to deactivate
    if (type === "committed") {
      this.committedTriggerElm?.focus();
    } else {
      this.uncommittedTriggerElm?.focus();
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.header-committed-group,
.header-uncommitted-group {
  position: sticky;
  top: 0;
  background-color: colors-old.$modal-background-color;
  z-index: z-index.$low;
}

.objectives-container {
  margin: 0 20px;
}

.objectives-header {
  margin: 40px 0 20px;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 7px;
  vertical-align: center;

  .objectives-header-title {
    font-weight: font.$weight-extra-bold;
    font-size: font.$size-normal;
    margin: 0;
  }

  .objectives-actions {
    display: flex;
    align-items: center;
  }

  .collapsable-area {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    outline: revert;
  }
}

.objective-drag-state {
  background: colors-old.$light-background-color;
  cursor: grabbing;
}

.drag-state {
  background: colors-old.$light-background-color;
  cursor: grabbing;

  & ~ .header-drag-state {
    display: none;
  }
}

.no-objective-placeholder {
  border: 1px solid colors-old.$objective-border-color;
  padding: 1.2rem;
  margin-bottom: 1.2rem;
  border-radius: variables.$objective-card-border-radius;

  p {
    color: colors-old.$placeholder-color;
    font-size: font.$size-small;
    text-align: center;
  }
}

.objective-count {
  font-size: font.$size-small;
  margin: 4px 0 0;
  color: colors-old.$text-secondary-color;
}
</style>
