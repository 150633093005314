import { InjectionKey } from "vue";

import { Card } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";

export const cardKey: InjectionKey<Card> = Symbol("card");
export const cardMetaKey: InjectionKey<Readonly<CardMetaInjected>> =
  Symbol("cardMeta");
export const cardMethodsKey: InjectionKey<CardMethods> = Symbol("cardMethods");

type Animation = "mirroring" | "deleting" | "moving" | "zooming";

export interface CardMethods {
  animate?: (animation: Animation) => Promise<void>;
}

// provided from the StickyNote down to each child
export type CardMetaInjected = Readonly<{
  rootEl: HTMLDivElement | undefined;
  pos: RelativeCoordinate;
  isLinkingTarget: boolean;
  isSelected: boolean;
  isEnlarged: boolean;
  pin: (event?: Event) => void;
  unpin: (event?: Event) => void;
  isPinned: boolean;
  isPinningDisabled: boolean;
  levelOfDetails: 0 | 1 | 2;
  isReadonly: boolean;
  wasActivatedByKeyboard?: boolean;
}>;
