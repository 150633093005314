export class EventInfo {
  company: string;
  userId: string;
  sessionId: string;
  boardId: string;

  constructor(public topic: string) {
    // TODO how to config eslint to accept this??
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_app, _module, _version, company, userId, sessionId, boardId, ..._] =
      topic.split(".");
    this.company = company;
    this.userId = userId;
    this.sessionId = sessionId;
    this.boardId = boardId;
  }
}
