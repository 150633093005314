import { defineStore } from "pinia";

import { Size } from "@/model/size";
import { getScrollbarSize } from "@/utils/dom/dom";

import { useBoardStore } from "./board";

const DEFAULT_PADDING = 68;

export const useAppSizeStore = defineStore("appSize", {
  state: () => ({
    appSize: {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      padding: {
        top: 0,
        left: DEFAULT_PADDING,
        bottom: 60,
        right: DEFAULT_PADDING,
      },
      margin: {
        top: 48,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
    scrollbarSize: getScrollbarSize(),
  }),
  getters: {},
  actions: {
    setAppSize(e: Size) {
      this.appSize = {
        top: e.top,
        left: e.left,
        width: e.width,
        height: e.height,
        padding: { ...this.appSize.padding },
        margin: { ...this.appSize.margin },
      };
    },
    setLeft(padding: number, margin: number) {
      if (useBoardStore().isCurrentBoardFluid) {
        this.appSize.padding.left = padding;
        this.appSize.margin.left = margin;
      }
    },
    setMarginLeft(margin: number) {
      if (margin === 0) {
        this.setLeft(DEFAULT_PADDING, 0);
      } else {
        this.setLeft(0, margin);
      }
    },
  },
});
