import { defineStore } from "pinia";

import { RingBuffer } from "@/utils/RingBuffer";

export const useConnectionStore = defineStore("connection", {
  state: () => {
    return {
      netWs: true,
      netOnline: true,
      alm: true,
      lastConnect: 0,
      lastDisconnect: 0,
      reconnects: 0,
      wsStates: new RingBuffer<{ timestamp: number; state: string }>(20),
    };
  },
  getters: {
    isConnected(state) {
      return state.netWs && state.netOnline;
    },
  },
  actions: {
    addWebsocketState(state: string) {
      this.wsStates.add({ timestamp: Date.now(), state });
    },
    setOffline() {
      this.lastDisconnect = Date.now();
      this.netOnline = false;
    },
    setOnline() {
      this.lastConnect = Date.now();
      this.netOnline = true;
    },
    disconnectWebsocket() {
      this.netWs = false;
    },
    reconnectWebsocket() {
      this.reconnects++;
      this.netWs = true;
    },
  },
});
