import { defineStore } from "pinia";

import { userHeartbeatInterval } from "@/model/Settings";
import { AuthUser } from "@/model/user";

export type BoardUser = AuthUser & {
  boardVisitTimestamp: number;
};

export const useUsersOnBoardStore = defineStore("usersOnBoard", {
  state: () => ({
    usersOnBoard: [] as BoardUser[],
  }),
  getters: {},
  actions: {
    addUserOnBoard(user: BoardUser) {
      this.usersOnBoard = [
        user,
        ...this.usersOnBoard.filter(({ id }) => id !== user.id),
      ];
    },
    removeUserFromBoard(userId: string) {
      this.usersOnBoard = this.usersOnBoard.filter(
        (user) => user.id !== userId,
      );
    },
    findUserOnBoard(userId: string): BoardUser | undefined {
      return this.usersOnBoard.find((user) => user.id === userId);
    },
    expireUsersOnBoard() {
      this.usersOnBoard = this.usersOnBoard.filter((user) => {
        if (user.boardVisitTimestamp) {
          const now = Date.now();
          const diff = now - user.boardVisitTimestamp;
          return diff <= userHeartbeatInterval * 2;
        } else {
          return false;
        }
      });
    },
  },
});
