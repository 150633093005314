<script setup lang="ts">
import { computed } from "vue";

import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import StatusDot from "@/components/ui/StatusDot/StatusDot.vue";
import { type Status } from "@/model/status";
import { useWorkModeStore } from "@/store/workMode";

defineProps<{ status: Status }>();

const isExecutionMode = computed(() => useWorkModeStore().isExecutionMode);
</script>

<template>
  <div v-if="isExecutionMode && status">
    <BaseTooltip position="bottom">
      <StatusDot :status-class="status.statusClass" />
      <template #content>{{ status.name }}</template>
    </BaseTooltip>
  </div>
</template>

<style lang="scss" scoped>
:deep(.status-dot) {
  height: 14px;
  width: 14px;
}
</style>
