<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <div
    v-focus-trap="{ setReturnFocus }"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-header-legacy-title"
    :aria-description="description"
    class="modal-legacy no-wheel"
    :style="backStyle"
    @click="hide"
  >
    <div :style="frontStyle" @click.stop>
      <div class="header">
        <IconButton
          icon="base/close"
          class="close-button"
          :aria-label="$t('label.modalLegacy.close')"
          @click="hide"
          @keydown.enter.stop
          @keydown.space.stop
        />
      </div>
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ShortcutUser from "@/mixins/ShortcutUser";
import { useModalStore } from "@/store/modal";

import IconButton from "../ui/IconButton/IconButton.vue";

/** @deprecated Will be removed in the future. Only kept for backwards compatibility.
 * Will be replaced by redesigned version of Modal.
 */
@Component({ components: { IconButton } })
export default class ModalLegacy extends mixins(ShortcutUser) {
  @Prop(Number) readonly width: number | undefined;
  @Prop(Number) readonly height: number | undefined;
  @Prop(String) readonly description: string | undefined;
  /**
   * Query selector that determines which element should receive focus when the modal closes
   * (if not given, focus returns to wherever it was when the modal was opened, but this does
   * not work eg. when the page is refreshed while the modal is open)
   */
  @Prop(String) readonly setReturnFocus: string | undefined;

  get backStyle() {
    const docElem = document.documentElement;
    return {
      width: docElem.scrollWidth + "px",
      height: docElem.scrollHeight + "px",
    };
  }

  get frontStyle() {
    const width = this.width ? this.width + "px" : "75vw";
    const height = this.height ? this.height + "px" : "85vh";
    return {
      width: width,
      height: height,
      top: `calc((100vh - ${height}) / 2)`,
      left: `calc((100vw - ${width}) / 2)`,
    };
  }

  hide() {
    // on touch screens when a modal is opened using pointerup, the modal also receives a click event
    // to avoid immediate closing of the modal, add this check
    if (!useModalStore().wasModalJustOpened) {
      useModalStore().hide();
    }
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/modal";
@use "@/styles/z-index";

.modal-legacy {
  @include modal.background;

  font-size: 14px;

  & > div {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: colors-old.$back-color;
    border: 3px solid colors-old.$menu-color;
  }

  .scrollable {
    &.scrollable-relative {
      position: absolute;
    }

    &.scrollable-content {
      height: auto;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    margin: 0;
    text-align: center;
    overflow: hidden;
  }

  h1,
  .h1 {
    font-size: 200%;
    font-weight: font.$weight-black;
  }

  h2,
  .h2 {
    font-size: 125%;
    font-weight: font.$weight-black;
    color: colors-old.$primary-color;
  }

  h3,
  .h3 {
    font-size: 100%;
    font-weight: font.$weight-black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    &.scroll-header {
      border-collapse: separate;
    }

    td {
      padding: 0;
      overflow: hidden;
    }

    thead td {
      z-index: z-index.$low;
      font-weight: font.$weight-black;
      position: sticky;
      top: 0;
      background-color: colors-old.$back-color;
      height: 2em;
    }

    th {
      text-align: left;
      font-weight: font.$weight-black;
    }
  }

  .head {
    margin-top: 1em;
    width: 100%;
    border-bottom: 3px solid colors-old.$menu-color;
    display: flex;
    font-weight: font.$weight-black;
    height: 1.6em;
  }

  .header {
    position: absolute;
    width: 100%;
    height: 24px;
    line-height: 18px;
    padding: 0 1em;
    background-color: colors-old.$menu-color;
    color: colors-old.$back-color;
    font-size: 75%;

    .close-button {
      cursor: pointer;
      position: absolute;
      top: 1px;
      right: 1px;
      padding: 0;
      color: colors.$text-inverse !important; // Color the icon against the black header

      &:hover {
        color: colors.$text !important;
      }
    }
  }

  .modal-content {
    position: absolute;
    top: 1.5em;
    bottom: 0;
    width: 100%;
    padding: 1em;
  }

  .row {
    text-align: center;
    display: inline-block;
    margin: 0.5em 20% 0;
    width: 40%;
  }

  .bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3em;
    background-color: colors-old.$menu-color;
    color: colors-old.$back-color;
    line-height: 2.3em;
    font-weight: font.$weight-black;

    & > div,
    & > .item {
      display: inline-block;
      position: relative;
      height: 100%;
      border-top: 0.5em solid colors-old.$menu-color;
      border-bottom: 0.5em solid colors-old.$menu-color;
      padding: 0 1em;
      outline: revert;

      &.line::after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        border-right: 1px solid colors-old.$back-color;
      }

      &.line-left::after {
        left: 0;
      }

      &.line-right::after {
        right: 0;
      }
    }
  }
}
</style>
