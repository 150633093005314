<script setup lang="ts">
import { computed, ref } from "vue";

import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";

import { TreeNodeData, TreeNodeInstance } from "../types";

const props = defineProps<{
  node: TreeNodeData;
  indentation: number;
  isFirstNode: boolean;
  selectedNodeId: string | null;
  setNodeRef: (id: string, ref: TreeNodeInstance | null) => void;
}>();

const emit = defineEmits<(e: "selectNode", node: TreeNodeData) => void>();

const isExpanded = ref(false);
const hasChildren = computed(() => props.node.children.length > 0);
const isSelected = computed(() => props.node.id === props.selectedNodeId);
const isDisabled = computed(() => props.node.disabled);
const isFirstNode = computed(() => props.isFirstNode);

const treeNodeRef = ref<HTMLElement | null>(null);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};
const expand = () => {
  isExpanded.value = true;
};
defineExpose({ expand });

const arrow = computed(() =>
  isExpanded.value ? "chevron/down-thin" : "chevron/right-thin",
);

const selectNode = () => {
  if (!isDisabled.value) {
    emit("selectNode", props.node);
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (!treeNodeRef.value) return;

  const currentNode = event.target as HTMLElement;
  const focusableNodes = Array.from(
    document.querySelectorAll('[role="treeitem"]'),
  ) as HTMLElement[];
  const currentIndex = focusableNodes.indexOf(currentNode);

  switch (event.key) {
    case "ArrowRight":
      if (hasChildren.value && !isExpanded.value) {
        toggleExpand();
      } else if (hasChildren.value && isExpanded.value) {
        focusableNodes[currentIndex + 1]?.focus();
      }
      break;

    case "ArrowLeft":
      if (hasChildren.value && isExpanded.value) {
        toggleExpand();
      } else if (currentIndex > 0) {
        focusableNodes[currentIndex - 1]?.focus();
      }
      break;

    case "ArrowUp":
      if (currentIndex > 0) {
        focusableNodes[currentIndex - 1]?.focus();
      }
      break;

    case "ArrowDown":
      if (currentIndex < focusableNodes.length - 1) {
        focusableNodes[currentIndex + 1]?.focus();
      }
      break;

    case "Enter":
    case " ":
      event.preventDefault();
      selectNode();
      break;

    case "Home":
      focusableNodes[0]?.focus();
      break;

    case "End":
      focusableNodes[focusableNodes.length - 1]?.focus();
      break;
  }
};

const tabindex = computed(() =>
  isSelected.value || isFirstNode.value ? 0 : -1,
);

const INDENTATION_STEP = 28; // 20px (icon) + 8px (padding)
</script>

<template>
  <!-- eslint does not recognize dynamic tabindex assignment -->
  <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
  <div
    ref="treeNodeRef"
    :class="['tree-node', { 'tree-node-selected': isSelected }]"
    role="treeitem"
    v-bind="hasChildren && { 'aria-expanded': isExpanded }"
    :aria-selected="isSelected"
    :aria-disabled="isDisabled"
    :data-testid="`tree-node-${node.label}`"
    :tabindex="tabindex"
    @click="selectNode"
    @keydown="handleKeydown"
  >
    <div class="tree-node-content" :style="{ paddingLeft: `${indentation}px` }">
      <button
        v-if="hasChildren"
        class="tree-node-expand-button"
        :aria-label="isExpanded ? 'Collapse' : 'Expand'"
        :data-testid="`expand-button-${node.label}`"
        tabindex="-1"
        @click.stop="toggleExpand"
      >
        <SvgIcon
          :name="arrow"
          class="collapse-icon"
          :class="{ 'icon-selected': isSelected }"
          width="20"
          height="20"
        />
      </button>
      <div v-else class="collapse-placeholder"></div>

      <SvgIcon
        v-if="node.icon"
        :name="node.icon"
        class="tree-node-icon"
        :class="{ 'icon-selected': isSelected }"
        role="presentation"
        width="20"
        height="20"
      />

      <span class="tree-node-label">
        {{ node.label }}
      </span>
    </div>
  </div>

  <!-- Children Nodes -->
  <div v-if="hasChildren && isExpanded" role="group">
    <TreeNode
      v-for="child in node.children"
      :ref="(ref) => setNodeRef(child.id, ref as TreeNodeInstance)"
      :key="child.id"
      :node="child"
      :selected-node-id="selectedNodeId"
      :indentation="indentation + INDENTATION_STEP"
      :is-first-node="false"
      :set-node-ref="setNodeRef"
      @select-node="emit('selectNode', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/utils";

.tree-node {
  cursor: pointer;
  width: 100%;
  outline: revert;

  &-selected {
    color: colors-old.$primary-color;
    border-radius: 4px;
    background-color: rgba(
      colors-old.$primary-color,
      colors-old.$background-color-transparency
    );
  }

  &:not(.tree-node-selected):hover {
    background-color: colors-old.$light-background-color;
    border-radius: 4px;
  }

  .tree-node-content {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;

    .collapse-placeholder {
      flex-shrink: 0;
      width: 20px;
    }
  }

  .tree-node-expand-button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;

    .collapse-icon {
      color: colors-old.$text-secondary-color;

      &.icon-selected {
        color: colors-old.$primary-color;
      }
    }
  }

  .tree-node-icon {
    flex-shrink: 0;
    color: colors-old.$text-secondary-color;

    &.icon-selected {
      color: colors-old.$primary-color;
    }
  }

  .tree-node-label {
    @include utils.ellipsis;

    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    flex-grow: 1;
  }
}
</style>
