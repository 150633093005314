<template>
  <BaseInput
    ref="input"
    v-model="query"
    control-type="text"
    icon-before="general/search"
    type="search"
    :icon-before-title="title"
    @input="update"
    @keypress.enter="$emit('select')"
    @keydown.esc="$emit('close')"
    @keydown.up.prevent="$emit('mark', rowStep(-1))"
    @keydown.down.prevent="$emit('mark', rowStep(1))"
    @keydown.left.prevent="$emit('mark', -1)"
    @keydown.right.prevent="$emit('mark', 1)"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";
import { eventTarget } from "@/utils/dom/dom";

/** @deprecated Will be removed in the future.
 * Only kept for backwards compatibility with legacy modals.
 * Has both input and some key capture logic (arrow keys
 * for navigating between results in the parent component)
 */
@Component({
  components: { BaseInput },
  emits: ["select", "close", "mark", "search"],
})
export default class SearchInputLegacy extends Vue {
  @Prop(Number) readonly itemsPerRow?: number;
  @Prop(String) readonly title?: string;

  @Ref("input") readonly inputElem!: HTMLInputElement;

  query = "";

  created() {
    document.addEventListener("keydown", this.keydown);
  }

  unmounted() {
    document.removeEventListener("keydown", this.keydown);
  }

  keydown(e: KeyboardEvent) {
    if (e.key.length === 1 && eventTarget(e)!.nodeName !== "INPUT") {
      this.inputElem.focus();
    }
  }

  update() {
    this.$emit("search", this.query);
  }

  rowStep(offset: number) {
    return offset * (this.itemsPerRow || 1);
  }
}
</script>
