import { reactive } from "vue";

import { getAlmInfo } from "@/backend/Backend";
import { mapUser, mapUserName } from "@/backend/mapper/mapBackendData";
import { CancelError } from "@/error/CancelError";
import { UnauthenticatedError } from "@/error/UnauthenticatedError";
import { handleError, isAxiosError } from "@/error/errorHandler";
import { MINUTES } from "@/model/Settings";
import {
  AlmMapping,
  AlmUser,
  AuthUser,
  TechnicalUser,
  almUser,
  backendUser,
  baseUser,
  isBackendUserId,
} from "@/model/user";
import { LocalStore, versioned } from "@/utils/LocalStore";
import { SimpleCache } from "@/utils/SimpleCache";
import * as Environment from "@/utils/env/Environment";

import { createApiClient, modernInterceptor } from "./api.config";

const api = modernInterceptor(createApiClient(Environment.authAPIUrl));

const users = new SimpleCache<AuthUser>({
  store: new LocalStore("users", { sedes: versioned(1) }),
  timeout: 24 * 60 * MINUTES,
});

type UserIdentifier = { id: string } | TechnicalUser;

export function loadReactiveUser(
  user: UserIdentifier & { name?: string },
  options: { almMapping?: AlmMapping; mapBackendToAlm?: boolean } = {},
): AuthUser {
  const res = reactive(uninitedUser(user, "loading"));
  loadUser(user, options)
    .then((loaded) => {
      res.type = loaded.type || "regular";
      res.id = loaded.id;
      res.name = loaded.name;
      res.email = loaded.email;
      res.imageUrl = loaded.imageUrl;
      res.color = loaded.color;
      res.preferredLanguage = loaded.preferredLanguage;
      res.hash = loaded.hash;
      res.almMapping = options.almMapping;
      if ("iconName" in loaded) {
        (res as AlmUser).iconName = loaded.iconName;
      }
    })
    .catch(() => {
      res.type = "unknown";
    });
  return res;
}

export async function loadUser(
  user: UserIdentifier,
  options?: { useCache?: boolean; mapBackendToAlm?: boolean },
): Promise<AuthUser> {
  if (isBackendUserId(user.id)) {
    return options?.mapBackendToAlm ? almUser(getAlmInfo()) : backendUser();
  }
  return await users.loadItem(user.id, () => getUser(user), {
    lookup: options?.useCache ?? true,
  });
}

export function clearUserCache() {
  users.clear();
}

async function getUser(user: UserIdentifier): Promise<AuthUser> {
  try {
    const res = await api.get("/v1/users/" + user.id);
    return mapUser(res.data);
  } catch (e) {
    handleUserError(e);
  }
  return uninitedUser(user, "unknown");
}

function handleUserError(e: unknown) {
  if (e instanceof CancelError || e instanceof UnauthenticatedError) {
    // CancelError: most likely refreshing the token failed
    // don't log as we are already redirected to log out
    // UnauthenticatedError: let the errorHandler do the correct thing
    throw e;
  }
  // 404 response is legitimate (user can be deleted), don't log
  if (isAxiosError(e) && e.response?.status === 404) {
    return;
  }
  handleError(e, "Could not get user");
}

function uninitedUser(
  user: { id: string } & Partial<AuthUser>,
  type: "unknown" | "loading",
): AuthUser {
  return {
    ...baseUser,
    ...user,
    type,
    name: mapUserName(user),
  };
}
