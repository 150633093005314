import { PieData } from "@/components/PieChart/PieChart";
import { Color } from "@/model/baseTypes";
import { Board, BoardIteration } from "@/model/board";
import { useSessionStore } from "@/store/session";

import { LoadCapacityItem } from "./MetricsModalLoadCapacityBody.vue";

export interface CapacityAllocation {
  total: number;
  values: PieData[];
}

export function calcCapacityAllocation(
  boards: Board[],
  iteration?: number,
): CapacityAllocation {
  const loads: Record<string, { value: number; color: Color }> = {};
  let total = 0;
  for (const board of boards) {
    for (const id in board.cards) {
      const card = board.cards[id].data;
      if (iteration === undefined || card.iterationId === iteration) {
        const type = card.type;
        if (!loads[type.name]) {
          loads[type.name] = { value: 0, color: type.color };
        }
        const value = card.points;
        loads[type.name].value += value;
        total += value;
      }
    }
  }
  const data = [];
  for (const id in loads) {
    data.push({ name: id, value: loads[id].value, color: loads[id].color });
  }
  data.sort((a, b) => b.value - a.value);
  return { total, values: data };
}

export function toLoadCapacityItem(
  teamIterations: BoardIteration[],
): LoadCapacityItem[] {
  return teamIterations.map((teamIteration, i) => {
    return {
      load: teamIteration.load,
      capacity: teamIteration.velocity,
      title: useSessionStore().iterations[i].name,
      id: useSessionStore().iterations[i].id,
    };
  });
}
