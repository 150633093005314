export function stickyNotes() {
  return document.querySelectorAll<HTMLElement>(".sticky-note");
}

export function svgElementsOnBoard() {
  return document.querySelectorAll<SVGSVGElement>(".board svg, .draw-layer");
}

export function removeBoxShadows(el: HTMLElement) {
  el.setAttribute("old-box-shadow", el.style.boxShadow);
  el.style.boxShadow = "none";
}

export function resetBoxShadows(el: HTMLElement) {
  el.style.boxShadow = el.getAttribute("old-box-shadow") || "";
  el.removeAttribute("old-box-shadow");
}

export function setExplicitCurrentColor(svg: SVGSVGElement) {
  setAll("stroke");
  setAll("fill");

  function setAll(attribute: "stroke" | "fill") {
    if (svg.getAttribute(attribute) === "currentColor") {
      set(svg, attribute);
    }
    svg
      .querySelectorAll(`[${attribute}=currentColor]`)
      .forEach((el) => set(el, attribute));
  }

  function set(el: Element, attribute: "stroke" | "fill") {
    el.setAttribute(`${attribute}-current-color`, "");
    el.setAttribute(attribute, window.getComputedStyle(el)[attribute]);
  }
}

export function resetExplicitCurrentColor(svg: SVGSVGElement) {
  resetAll("stroke");
  resetAll("fill");

  function resetAll(attribute: "stroke" | "fill") {
    if (svg.getAttribute(`${attribute}-current-color`) !== null) {
      reset(svg, attribute);
    }
    svg
      .querySelectorAll(`[${attribute}-current-color]`)
      .forEach((el) => reset(el, attribute));
  }

  function reset(el: Element, attribute: "stroke" | "fill") {
    el.removeAttribute(`${attribute}-current-color`);
    el.setAttribute(attribute, "currentColor");
  }
}

export function setExplicitSize(svg: SVGSVGElement) {
  svg.setAttribute(
    "old-values",
    JSON.stringify({
      width: svg.getAttribute("width"),
      height: svg.getAttribute("height"),
    } satisfies SavedSvgAttributes),
  );
  setAllAttributes(svg, {
    width: "" + svg.clientWidth,
    height: "" + svg.clientHeight,
  });
}

export function resetExplicitSize(svg: SVGSVGElement) {
  const oldValues = JSON.parse(
    svg.getAttribute("old-values")!,
  ) as SavedSvgAttributes;
  svg.removeAttribute("old-values");
  setAllAttributes(svg, oldValues);
}

interface SavedSvgAttributes {
  width: string | null;
  height: string | null;
}

function setAllAttributes(svg: SVGSVGElement, attrs: SavedSvgAttributes) {
  setAttribute(svg, "width", attrs.width);
  setAttribute(svg, "height", attrs.height);
}

function setAttribute(svg: SVGSVGElement, name: string, value: string | null) {
  if (value) {
    svg.setAttribute(name, value);
  } else {
    svg.removeAttribute(name);
  }
}
