<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/ui/DropdownMenu/DropdownTrigger.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import { isSticky } from "@/components/utils/linkableItems";
import { Functionality, StickyType } from "@/model/stickyType";
import { useStickyTypeStore } from "@/store/stickyType";
import {
  linkModalFilterOpened,
  linkModalFilterTypeDeselected,
  linkModalFilterTypeSelected,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { FilterItem, GroupedLinkableItems } from "../types";

const { t } = useI18n();

const props = defineProps<{ linkableItems: GroupedLinkableItems }>();

const disabled = computed(() => !allLinkableItems.value.length);

const allLinkableItems = computed(() => [
  ...props.linkableItems.currentLevel.items,
  ...Object.values(props.linkableItems.otherLevels).flatMap(
    (group) => group.items,
  ),
]);

const allLinkableCards = computed(() =>
  allLinkableItems.value.filter((item) => isSticky(item)),
);

const isObjectiveTypePresent = computed(
  () => allLinkableItems.value.length > allLinkableCards.value.length,
);

const types = computed(() => {
  const uniqueTypes = new Map<string, FilterItem>();
  if (isObjectiveTypePresent.value) {
    uniqueTypes.set("objective", {
      id: "objective",
      kind: "objective",
      name: t("linkModal.objective"),
    });
  }
  allLinkableCards.value.forEach((card) => {
    uniqueTypes.set(card.type.id, stickyTypeToFilterItem(card.type));
  });
  return Array.from(uniqueTypes.values());
});

const stickyTypeToFilterItem = (type: StickyType): FilterItem => {
  return {
    id: type.id,
    kind: "sticky",
    name: type.name,
    color: type.color,
  };
};

const model = defineModel<FilterItem[]>({ default: [] });

const selection = computed({
  get: () => model.value,
  set: (values: FilterItem[]) => (model.value = values),
});

const triggerHeader = computed(() => {
  const length = model.value.length;
  return t("linkModal.selectedItems", { item: model.value[0]?.name }, length);
});

const open = () => {
  trackEvent(linkModalFilterOpened());
};

watch(
  () => model.value,
  (newModel, oldModel) => {
    if (newModel && oldModel) {
      const addedItems = newModel.filter(
        (item) => !oldModel.some((oldItem) => oldItem.id === item.id),
      );
      const removedItems = oldModel.filter(
        (item) => !newModel.some((newItem) => newItem.id === item.id),
      );

      const getFunctionality = (
        item: FilterItem,
      ): Functionality | "objective" => {
        if (item.kind === "objective") return "objective";
        return useStickyTypeStore().findStickyType(item.id).functionality;
      };

      addedItems.forEach((item) => {
        trackEvent(linkModalFilterTypeSelected(getFunctionality(item)));
      });
      removedItems.forEach((item) => {
        trackEvent(linkModalFilterTypeDeselected(getFunctionality(item)));
      });
    }
  },
  { deep: true },
);
</script>

<template>
  <div class="date-filter">
    <DropdownMenu width="lg" @open="open">
      <template #trigger="{ isOpen }">
        <DropdownTrigger
          :color="selection.length ? 'primary' : 'grey'"
          :value="triggerHeader"
          :disabled="disabled"
          :activated="isOpen"
          :aria-expanded="isOpen"
          aria-controls="filter-dropdown-menu"
          aria-haspopup="listbox"
          :aria-label="t('linkModal.filterItems')"
        />
      </template>
      <SelectableList
        id="filter-dropdown-menu"
        v-model="selection"
        v-keyboard-nav
        :values="types"
      >
        <template #default="{ value }">
          <span>
            <SvgIcon
              v-if="value.kind === 'objective'"
              name="objective/team-objectives"
              class="objective-icon"
              height="16"
              width="16"
            />
            <TypeDot v-else size="medium" :color="value.color" />
            {{ value.name }}
          </span>
        </template>
      </SelectableList>
    </DropdownMenu>
  </div>
</template>

<style lang="scss">
@use "@/styles/mixins/typography";

.date-filter {
  .dropdown-menu {
    .dropdown-trigger {
      padding: 5px 12px;

      @include typography.small("semi-bold");
    }
  }
}
</style>
