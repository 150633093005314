import { handleError } from "@/error/errorHandler";

export default class Scheduler {
  tasks = new Set<number>();

  schedule(action: () => void, timeout: number) {
    const task = window.setTimeout(() => {
      this.tasks.delete(task);
      try {
        action();
      } catch (e) {
        handleError(e);
      }
    }, timeout);
    this.tasks.add(task);
  }

  cancelAll() {
    this.tasks.forEach((task) => window.clearTimeout(task));
    this.tasks.clear();
  }
}
