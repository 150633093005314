<script lang="ts" setup></script>

<template>
  <div class="base-list">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.base-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  gap: 2px;
  height: 100%;
}
</style>
