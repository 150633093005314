<script setup lang="ts">
import { computed } from "vue";

import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/vue";

import MirrorToArtBoardMenuItem from "./MirrorToArtBoardMenuItem/MirrorToArtBoardMenuItem.vue";
import ActivityMenuItem from "./components/ActivityMenuItem/ActivityMenuItem.vue";
import AlmMenuItem from "./components/AlmMenuItem.vue";
import ChangeArtMenuItem from "./components/ChangeArtMenuItem/ChangeArtMenuItem.vue";
import ChangeProjectMenuItem from "./components/ChangeProjectMenuItem/ChangeProjectMenuItem.vue";
import DeleteMenuItem from "./components/DeleteMenuItem/DeleteMenuItem.vue";
import DependOnTeamMenuItem from "./components/DependOnTeamMenuItem/DependOnTeamMenuItem.vue";
import DuplicateMenuItem from "./components/DuplicateMenuItem/DuplicateMenuItem.vue";
import LinkMenuItem from "./components/LinkMenuItem/LinkMenuItem.vue";
import MirrorMenuItem from "./components/MirrorMenuItem/MirrorMenuItem.vue";
import MoveMenuItem from "./components/MoveMenuItem/MoveMenuItem.vue";
import PinMenuItem from "./components/PinMenuItem/PinMenuItem.vue";
import ShareMenuItem from "./components/ShareMenuItem/ShareMenuItem.vue";
import StickyStatusMenuItem from "./components/StickyStatusMenuItem/StickyStatusMenuItem.vue";
import StickyTypeMenuItem from "./components/StickyTypeMenuItem/StickyTypeMenuItem.vue";
import StoryPointsMenuItem from "./components/StoryPointsMenuItem/StoryPointsMenuItem.vue";
import TeamMenuItem from "./components/TeamMenuItem/TeamMenuItem.vue";
import WsjfMenuItem from "./components/WsjfMenuItem/WsjfMenuItem.vue";

const card = injectStrict(cardKey);

// Focus trap should contain the card as well as the action menu
const extraFocusTrapElements = computed(() => {
  return [document.getElementById(card.id)];
});
</script>

<template>
  <div
    :id="'action-menu-' + card.id"
    v-focus-trap="{
      extraElements: extraFocusTrapElements,
      returnFocusOnDeactivate: false,
      fallbackFocus: `[id='${card.id}']`, // Fixes bug when card+dialog close at the same time (eg. when deleting a card)
    }"
    style="display: contents"
  >
    <StickyTypeMenuItem />
    <StickyStatusMenuItem />
    <WsjfMenuItem />
    <StoryPointsMenuItem />
    <ChangeProjectMenuItem />
    <ChangeArtMenuItem />
    <AlmMenuItem />
    <LinkMenuItem />
    <MoveMenuItem />
    <MirrorMenuItem />
    <MirrorToArtBoardMenuItem />
    <TeamMenuItem />
    <DependOnTeamMenuItem />
    <DeleteMenuItem />
    <PinMenuItem />
    <DuplicateMenuItem />
    <ShareMenuItem />
    <ActivityMenuItem />
  </div>
</template>
