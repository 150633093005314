<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { markActions } from "@/action/mark";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey, cardMethodsKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useServerSettingsStore } from "@/store/serverSettings";
import { injectStrict } from "@/utils/vue";

import * as actions from "../../actions";
import { isActionVisible } from "../../utils";
import DeleteSticky from "./DeleteSticky.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMethods = injectStrict(cardMethodsKey);

const isDeleteVisible = computed(() => {
  return (
    isActionVisible("delete", card, board.value) &&
    !actions.cardWasCreatedOnDifferentBoard(card, board.value)
  );
});
const isDisabled = computed(
  () => !actions.isStickyDeletable(card, board.value),
);
const confirm = computed(
  () => useServerSettingsStore().confirmDelete && card.text,
);

async function remove(confirmed: boolean) {
  if (!confirmed && confirm.value) {
    return;
  }
  markActions.hide(useBoardStore().currentBoard().cards[card.id]);

  useLinkStore().removeCardLinkedMarks(card.id);
  useBoardStore().activeCardId = null;
  sendStickyNoteAction(card.id, { action: "changed" }); // Re-highlights search results if needed

  await cardMethods.animate?.("deleting");
  cardActions.delete("card", card.id, board.value.id);
}
</script>

<template>
  <ActionMenuItem
    v-if="isDeleteVisible"
    width="auto"
    name="delete"
    :disabled="isDisabled"
    :tooltip-text="
      isDisabled
        ? $t('stickyNote.deleteStickyNoteDisabled')
        : $t('cardAction.delete')
    "
    :aria-label="$t('label.actionMenu.delete')"
    @click="remove(false)"
  >
    <SvgIcon name="action/delete" width="20" height="20" />
    <template v-if="confirm" #dropdown>
      <DeleteSticky mode="delete" @delete="remove(true)" />
    </template>
  </ActionMenuItem>

  <ActionMenuItem
    v-else
    width="auto"
    name="remove"
    :tooltip-text="$t('cardAction.remove')"
    :aria-label="$t('label.actionMenu.remove')"
    @click="remove(false)"
  >
    <SvgIcon name="action/remove" width="20" height="20" />
    <template v-if="confirm" #dropdown>
      <DeleteSticky mode="remove" @delete="remove(true)" />
    </template>
  </ActionMenuItem>
</template>
