<script setup lang="ts">
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/ui/DropdownMenu/DropdownTrigger.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import { fonts } from "@/store/clientSettings";

const props = defineProps<{ modelValue: string; triggerLabel?: string }>();
const emit = defineEmits<{ "update:modelValue": [font: string] }>();

function select(font: string) {
  emit("update:modelValue", font);
}
</script>

<template>
  <DropdownMenu
    class="settings-font-dropdown"
    :offset="{ top: 15 }"
    :disable-teleport="true"
  >
    <template #trigger="{ isOpen }">
      <DropdownTrigger
        :aria-label="props.triggerLabel"
        :value="modelValue"
        :style="{ fontFamily: modelValue }"
        :aria-expanded="isOpen"
        aria-haspopup="listbox"
        aria-controls="settings-font-listbox"
      />
    </template>
    <div
      id="settings-font-listbox"
      v-keyboard-nav
      role="listbox"
      @keydown.escape.stop
    >
      <ListItem
        v-for="font in fonts"
        :key="font"
        role="option"
        :aria-selected="props.modelValue === font"
        :style="{ fontFamily: font }"
        @click="select(font)"
      >
        {{ font }}
      </ListItem>
    </div>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
.settings-font-dropdown {
  .dropdown-trigger > :deep(span:first-child) {
    width: 80px;
    font-family: inherit;
  }
}
</style>
