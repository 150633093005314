import { onMounted, onUnmounted, ref } from "vue";

/**
 * Returns the online status of the client, based on "offline" and "online" events.
 *
 * @returns {
 *  online: Ref<boolean> - True when the client is online
 * }
 */
export function useOnlineStatus() {
  const online = ref(navigator.onLine);
  let listenerController: AbortController | null = null;

  onMounted(() => {
    listenerController = new AbortController();

    window.addEventListener("offline", () => (online.value = false), {
      signal: listenerController?.signal,
    });
    window.addEventListener("online", () => (online.value = true), {
      signal: listenerController?.signal,
    });
  });

  onUnmounted(() => {
    listenerController?.abort();
  });

  return { online };
}
