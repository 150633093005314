import { ServerTime, TimerData } from "@/model/timer";
import { pad } from "@/utils/general";

export default class Duration {
  readonly value: number;

  constructor(value: number) {
    this.value = value;
  }

  static fromEnd(end: ServerTime, now: ServerTime) {
    return new Duration(Math.round((end - now) / 1000));
  }

  static fromTimer(timer: TimerData, now: ServerTime) {
    if (timer.state === "running") {
      return timer.end < now
        ? new Duration(0)
        : Duration.fromEnd(timer.end, now);
    }
    return new Duration(timer.duration);
  }

  nonNegative() {
    return this.value < 0 ? new Duration(0) : this;
  }

  hasEnded(lessThan: number) {
    return this.value <= 0 && this.value >= -lessThan / 1000;
  }

  withHours(n: number) {
    return new Duration(this.value + (n - this.hours) * 3600);
  }

  get hours() {
    return Math.floor(this.value / 3600);
  }

  get hourStr() {
    return pad(this.hours, 2);
  }

  get hasHours() {
    return !!this.hours;
  }

  withMinutes(n: number) {
    return this.plusMinutes(n - this.minutes);
  }

  plusMinutes(n: number) {
    return new Duration(this.value + n * 60);
  }

  get minutes() {
    return Math.floor(this.value / 60) - this.hours * 60;
  }

  get minuteStr() {
    return pad(this.minutes, 2);
  }

  get hasMinutes() {
    return this.hasHours || !!this.minutes;
  }

  withSeconds(n: number) {
    return new Duration(this.value + n - this.seconds);
  }

  get seconds() {
    return this.value % 60;
  }

  get secondStr() {
    return pad(this.seconds, 2);
  }

  get hasSeconds() {
    return this.hasMinutes || !!this.seconds;
  }

  end(now: ServerTime): ServerTime {
    return (now + this.value * 1000) as ServerTime;
  }

  get hourFraction() {
    return Math.min(1, this.value / 3600);
  }
}
