import { toHex } from "./general";

/* eslint-disable no-bitwise */
const countMax = (1 << 24) - 1;
const rndMax = 1 << 20;
let count = rndInt(countMax);

export function objectId(): string {
  count = (count + 1) & countMax;
  const rnd = toHex(rndInt(rndMax), 5) + toHex(rndInt(rndMax), 5);
  return toHex(Math.floor(Date.now() / 1000), 8) + rnd + toHex(count, 6);
}

function rndInt(max: number) {
  return Math.floor(Math.random() * max);
}
