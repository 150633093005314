import { defineStore } from "pinia";

import { Line, LineComponent, RelativeCoordinate } from "@/model/coordinates";
import { Shape } from "@/model/shape";

type Tool = "move" | "line";

interface DrawState {
  active: boolean;
  tool: Tool;
  selected: SelectedShape | null;
}

type SelectedShape = { shape: Shape } & Selected;
export type Selected =
  | { anchor: LineComponent | null }
  | { anchor: "line"; offset: Line<RelativeCoordinate> };

export const useDrawStore = defineStore("draw", {
  state: (): DrawState => ({
    active: false,
    tool: "move",
    selected: null,
  }),
  actions: {
    setActive(active: boolean) {
      this.active = active;
      this.updateSelected();
    },
    toggleActive() {
      this.active = !this.active;
      this.updateSelected();
    },
    updateSelected() {
      if (!this.active) {
        this.selected = null;
      }
    },
  },
});
