import { defineStore } from "pinia";

interface CompanySettings {
  autoLogoutAfter?: number;
}

export const useCompanySettingsStore = defineStore("companySettings", {
  state: () => ({
    autoLogoutAfter: 0,
  }),

  actions: {
    setCompanySettings(settings: CompanySettings) {
      this.$patch(settings);
    },
  },
});
