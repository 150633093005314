import { defineStore } from "pinia";

import { loadAlmItemTypeOfCard } from "@/backend/Backend";
import { AlmSourceId, BoardType, IdMap, StatusClass } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { AlmItemStatus, AlmItemType, AlmItemTypeMap } from "@/model/status";
import { StickyType } from "@/model/stickyType";

import {
  defaultStatuses,
  dynamicStatus,
  fallbackStatus,
  initialDefaultStatus,
} from "./helpers/almItemStatus";

const emptyAlmItemType = {
  statuses: [],
  globalTargetStatuses: [],
  dynamic: false,
};

export const useAlmItemTypeStore = defineStore("almItemTypes", {
  state: () => ({
    almItemTypes: {} as IdMap<AlmItemTypeMap>,
  }),
  getters: {},
  actions: {
    findAlmItemType(
      type: StickyType,
      teamId: string | null | undefined,
      artId: string | null | undefined,
      almSourceId: AlmSourceId | null,
    ): AlmItemType {
      const boardKey = this.getBoardKey(
        type.origin,
        artId ?? undefined,
        teamId ?? undefined,
      );
      const almItemTypes = this.almItemTypes[type.id] || {};
      const mappingAlmItemType =
        almItemTypes[boardKey ?? ""] || almItemTypes[""];
      return (
        mappingAlmItemType?.[almSourceId ?? ""] ||
        mappingAlmItemType?.[""] ||
        emptyAlmItemType
      );
    },
    getBoardKey(
      boardType: BoardType,
      artId: string | undefined,
      teamId: string | undefined,
    ): string | undefined {
      switch (boardType) {
        case "backlog":
          return artId;
        case "team":
          return teamId;
      }
    },
    calcStatus(
      status: string | undefined,
      type: StickyType,
      teamId: string | null | undefined,
      artId: string | null | undefined,
      almSourceId: AlmSourceId | null,
      statusClass?: StatusClass,
    ): AlmItemStatus | undefined {
      if (type.functionality !== "workitem") {
        return;
      }
      const almItemType = this.findAlmItemType(
        type,
        teamId,
        artId,
        almSourceId,
      );
      return status
        ? this.findStatus(almItemType, status, statusClass)
        : this.findInitialStatus(almItemType);
    },
    findStatusForCard(
      status: string,
      card: Card,
      board: Board,
      statusClass?: StatusClass,
    ): AlmItemStatus {
      const almItemType = this.findAlmItemType(
        card.type,
        card.teamId,
        board.artId ?? card.artId,
        card.almSourceId,
      );
      return this.findStatus(almItemType, status, statusClass);
    },
    findStatus(
      almItemType: AlmItemType,
      status: string,
      statusClass?: StatusClass,
    ): AlmItemStatus {
      // if no ALM tool -> default statuses
      if (almItemType.statuses.length === 0) {
        if (almItemType.dynamic) {
          return dynamicStatus(status, statusClass);
        }
        const defaultStatus = defaultStatuses.find((s) => s.name === status);
        if (!defaultStatus) {
          return initialDefaultStatus;
        }
        return defaultStatus;
      }
      const almItemStatus = almItemType.statuses.find((s) => s.name === status);
      if (almItemStatus) {
        return almItemStatus;
      }
      // if alm tool & no status found -> undefined
      return fallbackStatus(almItemType, status);
    },
    findInitialStatus(almItemType: AlmItemType): AlmItemStatus {
      const initial = almItemType.statuses.find((s) => s.initial);
      if (initial) {
        return initial;
      }
      return initialDefaultStatus;
    },
    async updateAlmItemType(card: Card, board: Board) {
      if (!card.almId) {
        return;
      }
      const updated = await loadAlmItemTypeOfCard(card.almId);
      const existing = this.findAlmItemType(
        card.type,
        card.teamId,
        board.artId,
        card.almSourceId,
      );
      existing.globalTargetStatuses = updated.globalTargetStatuses;
      existing.statuses = updated.statuses;
    },
  },
});
