<script setup lang="ts">
import { computed } from "vue";

import { TOP_MENU_OFFSET } from "@/components/menu/TopMenu/constants";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import { dependencyLinkItems, useSearchMenuStore } from "@/store/searchMenu";

import DependencyLinkItemTooltip from "../tooltips/DependencyLinkItemTooltip.vue";
import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedDependencyLinkItems,
  set: (values: Array<{ id: string }>) =>
    (store.selectedDependencyLink = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu
    class="search-select"
    width="sm"
    stay-open-on-content-click
    @open="trackFilterOpen('dependency-link')"
  >
    <template #trigger="{ isOpen }">
      <IconButton
        icon="action/link"
        data-dropdown-trigger
        :activated="isOpen"
        :aria-label="
          $t('label.search.filterButton', { value: $t('dependency.stickies') })
        "
        :offset="TOP_MENU_OFFSET"
        :aria-expanded="isOpen"
        aria-haspopup="listbox"
      >
        <template #tooltip>
          <DependencyLinkItemTooltip :items="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectNamedValue
      v-model="selection"
      :values="dependencyLinkItems"
      :header="$t('dependency.stickies')"
      translate
    />
  </DropdownMenu>
</template>
