import { throttle } from "lodash-es";

export const LAST_ACTIVITY_KEY = "lastActivityTimestamp";
const LOGOUT_CHANNEL_NAME = "inactivity-logout-channel";
const USER_EVENTS = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
];

interface InactivityTimeoutOptions {
  timeoutMinutes: number;
  onTimeout: () => void;
}

export function setupInactivityLogout({
  timeoutMinutes,
  onTimeout,
}: InactivityTimeoutOptions) {
  if (!timeoutMinutes) return;

  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  const channel = new BroadcastChannel(LOGOUT_CHANNEL_NAME);

  const throttledEventHandler = throttle(() => resetTimer(), 1000, {
    leading: true,
    trailing: false,
  });

  function cleanup() {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    window.removeEventListener("storage", handleStorageChange);
    USER_EVENTS.forEach((event) => {
      window.removeEventListener(event, throttledEventHandler);
    });
    localStorage.removeItem(LAST_ACTIVITY_KEY);
    channel.close();
  }

  function handleTimeout() {
    cleanup();
    const channel = new BroadcastChannel(LOGOUT_CHANNEL_NAME);
    channel.postMessage({ type: "LOGOUT" });
    channel.close();
    onTimeout();
  }

  function checkIfTimedOut() {
    const lastActivity = Number(
      localStorage.getItem(LAST_ACTIVITY_KEY) || Date.now(),
    );
    const timeSinceLastActivity = Date.now() - lastActivity;
    const timeoutDuration = timeoutMinutes * 60 * 1000;

    if (timeSinceLastActivity >= timeoutDuration) {
      handleTimeout();
      return true;
    }
    return false;
  }

  function resetTimer() {
    if (timeoutId) clearTimeout(timeoutId);

    localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());

    if (timeoutMinutes > 0)
      timeoutId = setTimeout(handleTimeout, timeoutMinutes * 60 * 1000);
  }

  function handleStorageChange(event: StorageEvent) {
    if (event.key === LAST_ACTIVITY_KEY && !checkIfTimedOut()) resetTimer();
  }

  function handleBroadcastMessage(event: MessageEvent) {
    if (event.data.type === "LOGOUT") {
      cleanup();
      onTimeout();
    }
  }

  if (!checkIfTimedOut() && timeoutMinutes > 0) {
    resetTimer();
    window.addEventListener("storage", handleStorageChange);
    channel.addEventListener("message", handleBroadcastMessage);
    USER_EVENTS.forEach((event) => {
      window.addEventListener(event, throttledEventHandler);
    });
  }
}
