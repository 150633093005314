export const logPerformance =
  window.LOG_PERFORMANCE === "true" ||
  import.meta.env.VITE_LOG_PERFORMANCE === "true";
export const natsUrl = window.NATS_URL || import.meta.env.VITE_NATS_URL;
export const authAPIUrl =
  window.AUTH_API_URL || import.meta.env.VITE_AUTH_API_URL;
export const piserverAPIUrl =
  window.PISERVER_API_URL || import.meta.env.VITE_PISERVER_API_URL;
export const adminUrl = window.ADMIN_URL || import.meta.env.VITE_ADMIN_URL;
export const isCloud =
  "IS_CLOUD" in window
    ? window.IS_CLOUD === "true"
    : import.meta.env.VITE_IS_CLOUD === "true";

export const sentryDsn = window.SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN;
export const gaKey = window.GA_KEY || import.meta.env.VITE_GA_KEY;
export const featureProxyUrl =
  window.FEATURE_PROXY_URL || import.meta.env.VITE_FEATURE_PROXY_URL;
export const featureProxySecret =
  window.FEATURE_PROXY_SECRET || import.meta.env.VITE_FEATURE_PROXY_SECRET;
export const environment =
  window.ENVIRONMENT || import.meta.env.VITE_ENVIRONMENT;
export const intercomId =
  window.INTERCOM_ID || import.meta.env.VITE_INTERCOM_ID;
export const intercomApi =
  window.INTERCOM_API || import.meta.env.VITE_INTERCOM_API;
export const loginTimeout =
  Number(window.LOGIN_TIMEOUT || import.meta.env.VITE_LOGIN_TIMEOUT) || 5000;
export const build = {
  id: window.BUILD_ID || import.meta.env.VITE_BUILD_ID,
  hash: window.BUILD_HASH || import.meta.env.VITE_BUILD_HASH,
  time: new Date(window.BUILD_TIME || import.meta.env.VITE_BUILD_TIME),
};
export const amplitudeApiKey =
  window.AMPLITUDE_API_KEY || import.meta.env.VITE_AMPLITUDE_API_KEY;
export const amplitudeDebug =
  window.AMPLITUDE_DEBUG || import.meta.env.VITE_AMPLITUDE_DEBUG || false;
export const faroUrl =
  window.FARO_URL || import.meta.env.VITE_FARO_URL || undefined;

export function getConfig() {
  return {
    natsUrl,
    authAPIUrl,
    piserverAPIUrl,
    adminUrl,
    isCloud,
    sentryDsn,
    gaKey,
    environment,
    intercomId,
    intercomApi,
    build,
    amplitudeApiKey,
    amplitudeDebug,
  };
}
