<script lang="ts" setup>
import CurrentAlmIcon from "@/components/StickyNote/components/CurrentAlmIcon/CurrentAlmIcon.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../AttributeChip/AttributeChip.vue";
import StickyNoteRow from "../StickyNoteRow/StickyNoteRow.vue";
import StickyNoteTypeText from "../StickyNoteType/StickyNoteTypeText.vue";
import FlagChip from "./components/FlagChip/FlagChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
</script>

<template>
  <div class="sticky-note-header">
    <StickyNoteRow class="row">
      <StickyNoteTypeText
        v-show="cardMeta.levelOfDetails > 1"
        :text="card.type.name"
      />

      <div v-show="card.almId && cardMeta.levelOfDetails > 1" class="alm">
        <AttributeChip
          name="alm-chip"
          as="a"
          target="_blank"
          :href="card.almIssueUrl"
          :text="card.almId"
        >
          <template #icon>
            <CurrentAlmIcon />
          </template>
        </AttributeChip>
      </div>
      <FlagChip
        v-show="cardMeta.levelOfDetails >= 1"
        class="flag"
        data-ignore-click
      />
    </StickyNoteRow>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/z-index" as zIndex;
@use "@/styles/variables";

.sticky-note-header {
  padding: 8px 8px 0;
  height: 28px;
  width: variables.$sticky-size;

  .row {
    display: flex;
    justify-content: flex-end;
  }

  .alm {
    z-index: zIndex.$low;
    flex-shrink: 0;

    :deep(.text) {
      max-width: 140px;
    }
  }

  .flag {
    flex-shrink: 0;
  }
}
</style>
