<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { getAlmInfo } from "@/backend/Backend";
import { NoSuccessError } from "@/backend/NoSuccessError";
import { sender } from "@/backend/Sender";
import { IterationStatus } from "@/model/board";
import { useBoardsStore } from "@/store/boards";
import { useModalStore } from "@/store/modal";
import { useTeamStore } from "@/store/team";

import ConfirmModal from "./ConfirmModal.vue";

const props = defineProps<{ iteration: string }>();

const { t } = useI18n();
const error = ref("");
const almType = getAlmInfo().name;

const iter = computed(() => parseInt(props.iteration, 10));

async function sync() {
  setState("syncing");
  try {
    await sender.syncIteration(useTeamStore().currentTeam.id, iter.value);
  } catch (err) {
    if (err instanceof NoSuccessError) {
      setState(
        "fail",
        t("iterationSync.failedSync", {
          errorMessage: err.response.message,
        }),
      );
    }
  }
  const state =
    useBoardsStore().boardByType("team").iterations[iter.value].state;
  setTimeout(() => {
    if (state.status === "success") {
      useModalStore().hide();
    }
  }, 1000);
}

function setState(status: IterationStatus, detail = "") {
  error.value = detail;
  useBoardsStore().setIterationStatus({
    id: iter.value,
    status,
    detail,
  });
}
</script>

<template>
  <ConfirmModal
    min-height="220px"
    :question="$t('iterationSync.confirmManualUpdate', { almType })"
    :action-label="$t('iterationSync.updateStickies')"
    :error="error"
    :action="sync"
  >
    {{ $t("iterationSync.stickyOverwrite", { almType }) }}
  </ConfirmModal>
</template>
