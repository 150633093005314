<script lang="ts" setup></script>

<template>
  <div class="sticky-note-row">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.sticky-note-row {
  display: flex;
  height: 20px;
  align-items: center;
  gap: 6px;
  flex-wrap: nowrap;

  :deep(> *) {
    min-width: 0;
    overflow: hidden;
  }
}
</style>
