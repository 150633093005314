export interface Tenant {
  id: string;
  name: string;
}

export interface SelectList<T> {
  list: T[];
  /** the item selected by the user */
  selected: T | null;
  /** the item that is effectively loaded */
  current: T;
}

export function emptySelectList<T>(): SelectList<T> {
  return {
    list: [],
    current: { id: "", name: "", startDate: null, archived: false } as T,
    selected: null,
  };
}

export type LicenseType =
  | "trial"
  | "customer"
  | "partner"
  | "internal"
  | "expired"
  | "trial_expired";

export interface License {
  daysTillExpiration: number | null;
  usable: boolean;
  plan: "trial" | "standard" | "premium" | "enterprise";
  tracking: boolean;
  type: LicenseType;
  onPrem: boolean;
}

export interface Session {
  id: string;
  name: string;
  creationDate: number;
  startDate: Date | null;
  archived: boolean;
  almStatus: SessionAlmStatus | null;
  iterations: Iteration[];
}

export type SessionAlmStatus = "running" | "error" | "stopped" | "starting";

export interface Team {
  id: string;
  name: string;
  artId?: string;
}

export interface Art {
  id: string;
  name: string;
}

export type Group = Team | Art;

export interface Iteration {
  id: number;
  name: string;
  start: Date;
  end: Date;
}
