<script setup lang="ts">
import { ref } from "vue";

import { Icon } from "@/model/icon";

import LoadingIndicator from "../LoadingIndicator/LoadingIndicator.vue";
import SvgIcon from "../SvgIcon/SvgIcon.vue";
import { useAsyncHandler } from "./asyncHandler";

interface Props {
  as?: "button" | "a" | "div";
  variant?: "filled" | "outlined" | "ghost";
  size?: "small" | "medium";
  color?: "primary" | "grey" | "destructive";
  fullWidth?: boolean;
  iconBefore?: Icon;
  iconAfter?: Icon;
  disabled?: boolean;
  activated?: boolean;
}

withDefaults(defineProps<Props>(), {
  as: "button",
  variant: "filled",
  size: "medium",
  color: "primary",
  fullWidth: false,
  iconBefore: undefined,
  iconAfter: undefined,
});

defineOptions({ inheritAttrs: false });

// Allow parent to focus the button and call getBoundingClientRect
const buttonRef = ref<HTMLElement | null>(null);
const focus = () => buttonRef.value?.focus();
const blur = () => buttonRef.value?.blur();
const getBoundingClientRect = () => buttonRef.value?.getBoundingClientRect();

const { attrs, processing } = useAsyncHandler(100, "onClick");

defineExpose({ blur, focus, getBoundingClientRect });
</script>

<template>
  <Component
    :is="as"
    ref="buttonRef"
    :class="[
      'base-button',
      variant,
      color,
      size,
      {
        'full-width': fullWidth,
        activated: variant === 'ghost' && activated,
      },
    ]"
    :disabled="disabled"
    v-bind="attrs"
  >
    <SvgIcon v-if="iconBefore" :name="iconBefore" aria-hidden="true" />
    <span v-if="$slots.default" class="content">
      <LoadingIndicator
        v-if="processing.onClick"
        :inverted="variant === 'filled'"
      />
      <slot v-else></slot>
    </span>
    <SvgIcon v-if="iconAfter" :name="iconAfter" aria-hidden="true" />
  </Component>
</template>

<style scoped lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "sass:color";
@use "@/styles/mixins/utils";

.base-button {
  all: unset;
  outline: revert;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  user-select: none;
  font-weight: font.$weight-bold;
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }

  &.small {
    padding: 8px;
    font-size: 12px;
    gap: 4px;
    line-height: 16px;

    svg {
      width: 16px;
    }

    > * {
      height: 16px;
    }
  }

  &.medium {
    padding: 10px;
    font-size: 14px;
    gap: 8px;
    line-height: 20px;

    svg {
      width: 20px;
    }

    > * {
      height: 20px;
    }
  }

  &.full-width {
    width: 100%;
  }

  .content {
    @include utils.ellipsis;
  }

  &[class]:disabled {
    cursor: not-allowed;
  }

  &.filled {
    &[class]:disabled {
      color: colors-old.$shortcut-color;
      background-color: colors-old.$unfocused-input-border-color;
    }

    &.primary {
      background-color: colors-old.$primary-color;
      color: colors-old.$back-color;
      outline-offset: 3px; // Outline is the same color as the button

      &:not(:disabled) {
        &:hover {
          background-color: color.mix(
            colors-old.$menu-color,
            colors-old.$primary-color,
            15%
          );
        }

        &:active {
          background-color: color.mix(
            colors-old.$menu-color,
            colors-old.$primary-color,
            30%
          );
        }
      }
    }

    &.grey {
      background-color: colors-old.$light-background-color;
      color: colors-old.$reaction-active-icon-color;

      &:not(:disabled) {
        &:hover {
          background-color: color.mix(
            colors-old.$menu-color,
            colors-old.$light-background-color,
            5%
          );
          color: colors-old.$menu-color;
        }

        &:active {
          background-color: color.mix(
            colors-old.$menu-color,
            colors-old.$light-background-color,
            10%
          );
          color: colors-old.$menu-color;
        }
      }
    }

    &.destructive {
      background-color: colors-old.$error-color;
      color: colors-old.$back-color;

      &:not(:disabled) {
        &:hover {
          background-color: color.mix(
            colors-old.$menu-color,
            colors-old.$error-color,
            15%
          );
        }

        &:active {
          background-color: color.mix(
            colors-old.$menu-color,
            colors-old.$error-color,
            30%
          );
        }
      }
    }
  }

  &.outlined {
    &[class]:disabled {
      color: colors-a11y.$input-border;
      background-color: colors-old.$button-disabled-background-color;
      border: 1px solid currentcolor;
    }

    &.primary {
      color: colors-old.$primary-color;
      border: 1px solid currentcolor;

      &:not(:disabled) {
        &:hover {
          background-color: color.change(
            colors-old.$primary-color,
            $alpha: 0.04
          );
        }

        &:active {
          background-color: color.change(
            colors-old.$primary-color,
            $alpha: 0.08
          );
        }
      }
    }

    &.grey {
      border: 1px solid colors-a11y.$unfocused-input-border-color;
      color: colors-old.$text-secondary-color;

      &:not(:disabled) {
        &:hover {
          color: colors-old.$menu-color;
          background-color: colors-old.$hover-input-border-color;
        }

        &:active {
          color: colors-old.$menu-color;
          background-color: color.change(
            colors-old.$delete-color,
            $alpha: 0.08
          );
        }
      }
    }

    &.destructive {
      color: colors-old.$error-color;
      border: 1px solid currentcolor;

      &:not(:disabled) {
        &:hover {
          background-color: color.change(colors-old.$error-color, $alpha: 0.04);
        }

        &:active {
          background-color: color.change(colors-old.$error-color, $alpha: 0.08);
        }
      }
    }
  }

  &.ghost {
    &[class]:disabled {
      cursor: not-allowed;
      color: colors-a11y.$input-border;
    }

    &.primary {
      color: colors-old.$primary-color;

      &:not(:disabled, .activated) {
        &:hover {
          background-color: color.change(
            colors-old.$primary-color,
            $alpha: 0.04
          );
        }

        &:active {
          background-color: color.change(
            colors-old.$primary-color,
            $alpha: 0.08
          );
        }
      }

      &.activated {
        background-color: color.change(colors-old.$primary-color, $alpha: 0.08);
      }
    }

    &.grey {
      color: var(--text-secondary-color);

      &:not(:disabled, .activated) {
        &:hover {
          background-color: var(--light-background-color);
        }

        &:active {
          background-color: color.change(
            colors-old.$delete-color,
            $alpha: 0.08
          );
        }
      }

      &.activated {
        background-color: var(--active-background-color);
        color: colors-old.$primary-color;
      }
    }

    &.destructive {
      color: colors-old.$error-color;

      &:not(:disabled, .activated) {
        &:hover {
          background-color: color.change(colors-old.$error-color, $alpha: 0.04);
        }

        &:active {
          background-color: color.change(colors-old.$error-color, $alpha: 0.08);
        }
      }

      // NOTE: there is not active/activated state for destructive button
    }
  }
}
</style>
