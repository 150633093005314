<script setup lang="ts">
import { computed } from "vue";

import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { boardTypeName } from "@/model/baseTypes";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";

import GroupedByTypeSearchResults from "./GroupedByTypeSearchResults.vue";
import { GroupedSearchResults, StickyInfoKey } from "./SearchResult";

// Unique instance of the directive so we can render multiple
// components with this directive at once
const vKeyboardNav = keyboardNavigation();

const emit = defineEmits<{
  select: [item: StickyInfoKey, focus?: boolean];
}>();
const props = defineProps<{
  results: GroupedSearchResults;
  selected?: StickyInfoKey;
}>();

const currentBoardId = computed(() => useBoardStore().currentBoard().id);

const board = computed(() => useBoardsStore().boardById(props.results.boardId));
</script>

<template>
  <!-- Results count (screen reader only) -->
  <ScreenReaderMessage>
    {{ $t("label.resultsCount.currentBoard", { count: results.count }) }}
  </ScreenReaderMessage>
  <div
    v-keyboard-nav.soft-focus.ignore-trigger="{
      selector: '.result-card',
    }"
    v-bind="$attrs"
    role="listbox"
    class="base-search-items"
    :aria-label="$t('label.search.currentBoardResults')"
  >
    <div class="current-board-title">
      <div class="heading-container">
        <div class="heading">
          {{ board.type === "team" ? board.team.name : results.boardName }}
        </div>
        <div v-if="board.type === 'team'" class="subheadings">
          {{ $t(boardTypeName(board.type)) }}
        </div>
      </div>
      <div class="items-count">
        {{ results.count }}
      </div>
    </div>
    <div class="divider"></div>
    <GroupedByTypeSearchResults
      :items="results.items"
      :board-id="currentBoardId"
      :selected="selected"
      @select="(item, focus) => emit('select', item, focus)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;

.current-board-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  .heading-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .header {
      font-weight: font.$weight-bold;
      font-size: font.$size-normal;
    }

    .subheadings {
      font-weight: font.$weight-semi-bold;
      font-size: font.$size-small;
      color: colors-old.$text-secondary-color;
    }
  }

  .items-count {
    font-weight: font.$weight-medium;
    font-size: font.$size-small;
    color: colors-old.$text-secondary-color;
  }
}

.divider {
  border-top: 1px solid colors-old.$divider-color;
  width: 100%;
}
</style>
