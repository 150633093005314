<script setup lang="ts" generic="T extends { id: string }">
defineProps<{ firstList: T[]; secondList?: T[] }>();

const emit = defineEmits<{ select: [item: T] }>();

function selectItem(item: T) {
  emit("select", item);
}
</script>

<template>
  <div
    v-keyboard-nav.soft-focus-initial="{ selector: '.navigable-item' }"
    class="keyboard-navigable-list"
    role="menu"
  >
    <slot name="first-list-header"></slot>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events-->
    <div
      v-for="item in firstList"
      :id="'navigable-item-' + item.id"
      :key="item.id"
      role="menuitem"
      tabindex="0"
      class="item navigable-item"
      @click.prevent="selectItem(item)"
    >
      <slot name="row" :item="item"></slot>
    </div>

    <slot name="second-list-header"></slot>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events-->
    <div
      v-for="item in secondList || []"
      :id="'navigable-item-' + item.id"
      :key="item.id"
      role="menuitem"
      tabindex="0"
      class="item navigable-item"
      @click.prevent="selectItem(item)"
    >
      <slot name="second-list-row" :item="item"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.keyboard-navigable-list {
  cursor: pointer;

  .item {
    padding: 0 0.8em;
  }
}
</style>
