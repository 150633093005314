/**
 * Executes two async actions sequentially.
 * A pending second action can be cancelled either by calling `cancel` or by executing another pair of actions.
 */
export default class SinglePendingPromise {
  seq = 0;

  async execute<T>(action: () => Promise<T>, thenAction: (arg: T) => void) {
    this.cancel();
    const startSeq = this.seq;
    const res = await action();
    if (startSeq === this.seq) {
      thenAction(res);
    }
  }

  cancel() {
    this.seq++;
  }
}
