<script setup lang="ts">
import { watch } from "vue";

import UserAvatarNamed from "@/components/UserAvatar/UserAvatarNamed.vue";
import { pointerPosInterval } from "@/model/Settings";
import { Pointer } from "@/model/baseTypes";
import { isUnknownUser } from "@/model/user";
import { usePointerStore } from "@/store/pointer";
import { useUserStore } from "@/store/user";

import { trail } from "../pointerTrail";

const pointerPosDelay = 1 / pointerPosInterval + "s";
const store = usePointerStore();

watch(
  () => store.isPointerActive,
  () => trail(store.isPointerActive),
);

function showMarker(p: Pointer) {
  return (
    !isUnknownUser(p.user) && p.user.id !== useUserStore().technicalUser.id
  );
}
</script>

<template>
  <div id="pointers">
    <div v-for="p in store.pointers" :key="p.user.id">
      <div
        v-if="showMarker(p)"
        class="marker"
        :style="{
          '--pointer-pos-delay': pointerPosDelay,
          top: p.windowPos[0].pos.y + 'px',
          left: p.windowPos[0].pos.x + 'px',
        }"
      >
        <UserAvatarNamed :user="p.user" />
      </div>
      <div
        v-for="w in p.windowPos"
        :key="w.index"
        class="trail"
        :style="{
          top: w.pos.y + 'px',
          left: w.pos.x + 'px',
          backgroundColor: p.user.color,
        }"
      />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

#pointers {
  .marker {
    z-index: z-index.$pointer-trail + 1;
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    transition-property: top, left;
    transition-duration: var(--pointer-pos-delay);
    transition-timing-function: linear;
  }

  .trail {
    animation-name: disappear;
    animation-duration: 1s;
    z-index: z-index.$pointer-trail;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid colors-old.$back-color;
    opacity: 0;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
