import axios from "axios";

import { i18n } from "@/translations/i18n";
import { setAmplitudeUserProperty } from "@/utils/analytics/track";
import { addFontFamily } from "@/utils/dom/dom";

export async function setLanguage(lang: string) {
  setAmplitudeUserProperty("language", lang);
  if (lang === "ja") {
    addFontFamily(document.documentElement, "Noto Sans JP");
  }
  await setI18nLanguage(lang);
}

const loadedLanguages = ["en", "none"];

async function setI18nLanguage(lang: string) {
  try {
    if (i18n.global.locale.value !== lang && !loadedLanguages.includes(lang)) {
      const messages = await import(`./translations/${lang}.json`);
      i18n.global.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);
    }
    i18n.global.locale.value = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html")?.setAttribute("lang", lang);
  } catch (e) {
    // ignore when loading messages goes wrong
  }
}
