<script lang="ts" setup>
import SetStoryPoints from "@/components/card/components/SetStoryPoints/SetStoryPoints.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
</script>

<template>
  <AttributeChip
    name="story-points-chip"
    :text="card.points.toString()"
    :dropdown-props="{ width: 'auto' }"
    :tooltip="$t('statusDistribution.storyPoints')"
    :readonly="cardMeta.isReadonly"
  >
    <SetStoryPoints trigger="attribute-chip" />
  </AttributeChip>
</template>
