import { BoardCard } from "@/model/card";
import { Link } from "@/model/link";
import { minFaded } from "@/model/markMode";
import { useBoardStore } from "@/store/board";
import { LinkedCards } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

export function showLink(linked: LinkedCards) {
  const markMode = showIcon(linked.link)
    ? "normal"
    : minFaded(linked.from.meta.mark, linked.to.meta.mark);
  const search = useSearchMenuStore();
  return (
    !isHidden(linked.from) &&
    !isHidden(linked.to) &&
    ((useBoardStore().hasPermanentLinks && markMode !== "fade-out") ||
      search.showCardLinks(linked.from) ||
      search.showCardLinks(linked.to) ||
      search.matchesLinkStates(linked.link)) &&
    isStateShown(linked.link)
  );
}

export function showIcon(link: Link): boolean {
  return (
    useBoardStore().showRiskyLinks &&
    useSearchMenuStore().matchesLinkStates(link) &&
    ["warn", "error"].includes(link.state)
  );
}

function isHidden(c: BoardCard) {
  return c.meta.mark === "hidden";
}

function isStateShown(link: Link) {
  return (
    !useBoardStore().showRiskyLinks ||
    useSearchMenuStore().matchesLinkStates(link, true)
  );
}
