<script setup lang="ts">
import { computed } from "vue";

import { boardActions } from "@/action/boardActions";
import { cardsInLocation } from "@/components/board/location/BoardLocation";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useModalStore } from "@/store/modal";

import ConfirmModal from "./ConfirmModal.vue";

const props = defineProps<{ boardId: string; location: string[] }>();

const loc = computed(() =>
  useBoardStore().boardLocationFromIndex(props.location),
);

function arrange() {
  useModalStore().hide();
  const cards = cardsInLocation(useBoardStore().currentBoard(), loc.value);
  boardActions.arrange(
    "contextMenu",
    useBoardsStore().boardById(props.boardId),
    cards,
    loc.value.bounds,
  );
}
</script>

<template>
  <ConfirmModal
    min-height="320px"
    :question="$t('boardBase.arrangeStickyPrompt', { boardLocation: loc.name })"
    :action-label="$t('boardBase.arrangeStickies')"
    :action="arrange"
  >
    <p>{{ $t("boardBase.reorderBlurb") }}</p>
    <img
      src="@/assets/arrange.gif"
      :alt="$t('boardBase.reorderBlurb.imgAlt')"
    />
  </ConfirmModal>
</template>

<style lang="scss" scoped>
img {
  margin: 1em auto;
  width: 8em;
  height: 8em;
}
</style>
