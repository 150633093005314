import { Team } from "@/model/session";

type TeamSortFn = (t1: Team, t2: Team) => number;

/**
 * Sorts teams based on their presence in the user's teams list.
 * Teams that are present in the userTeams array will be prioritized
 * and sorted to the top of the list.
 */
export function sortByUserTeams(userTeams: Team[]): TeamSortFn {
  return (t1: Team, t2: Team) => {
    const t1InUserTeams = userTeams.some((team) => team.id === t1.id);
    const t2InUserTeams = userTeams.some((team) => team.id === t2.id);

    if (t1InUserTeams && !t2InUserTeams) return -1;
    if (!t1InUserTeams && t2InUserTeams) return 1;

    return 0;
  };
}

/**
 * Compares two teams by their names for sorting purposes.
 */
export function sortByName(t1: Team, t2: Team): number {
  return t1.name.localeCompare(t2.name);
}
