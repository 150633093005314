import { nextTick } from "vue";

export function animateCopy(
  el: Element,
  animationClass: string,
  started?: (el: HTMLElement) => void,
) {
  void nextTick(() => {
    const copy = el.cloneNode(true) as HTMLElement;
    copy
      .querySelectorAll("[data-no-animate]")
      .forEach((e) => e.parentElement!.removeChild(e));
    const parent = el.parentElement!;
    parent.appendChild(copy);
    setTimeout(() => {
      started?.(copy);
      copy.classList.add(animationClass);
    }, 20);
    setTimeout(() => parent.removeChild(copy), 1000);
  });
}
