<script setup lang="ts">
import { ComponentPublicInstance, computed, onMounted, ref, watch } from "vue";

import UserActions from "@/components/UserActions/UserActions.vue";
import SkipButton from "@/components/a11y/SkipButton.vue";
import MenuItemDelimiter from "@/components/menu/MenuItemDelimiter.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { cockpitSessionUrl } from "@/components/utils/cockpitUrls";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useLicenseStore } from "@/store/license";
import { useUserStore } from "@/store/user";
import { rteCockpitButtonClicked } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const cockpitAllowed = computed(() => useUserStore().isAllowed("cockpit"));

// Component rendered in the router-view
const routerContent = ref<ComponentPublicInstance | null>(null);

const handleRteCockpitButtonClick = () => {
  trackEvent(rteCockpitButtonClicked());
};

const licenseStore = useLicenseStore();

onMounted(() => {
  if (licenseStore.license.onPrem) {
    const url = cockpitSessionUrl();
    window.location.replace(url);
  }
});

watch(
  () => useClientSettingsStore().highContrastMode,
  (useMode, oldValue) => {
    if (useMode === oldValue || !document?.body) return;

    // Apply to the body so it also affects dropdown menus
    if (useMode) {
      document.body.classList.add("high-contrast-mode");
    } else {
      document.body.classList.remove("high-contrast-mode");
    }
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <SkipButton v-if="routerContent?.$el" @click="routerContent.$el.focus()">
      {{ $t("label.skipToContent") }}
    </SkipButton>
    <div class="page">
      <div class="page-top-bar">
        <div class="logo-header">
          <div>
            <a
              href="https://www.piplanning.io/"
              target="_blank"
              :aria-label="$t('label.menuTop.logo')"
            >
              <img src="@/assets/logo-named.svg" :alt="$t('label.mainLogo')" />
            </a>
          </div>
        </div>
        <nav class="page-nav" :aria-label="$t('label.menuTop')">
          <BaseButton
            v-if="cockpitAllowed"
            as="a"
            variant="outlined"
            color="grey"
            class="btn-rte-cockpit"
            target="_blank"
            :href="cockpitSessionUrl()"
            :aria-label="$t('label.menuTop.rteCockpit')"
            @click="handleRteCockpitButtonClick"
          >
            {{ $t("userAction.rteCockpit") }}
          </BaseButton>
          <MenuItemDelimiter />
          <UserActions />
        </nav>
      </div>

      <div class="page-content">
        <RouterView ref="routerContent"></RouterView>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";
@use "@/styles/font";

.page {
  font-size: 14px;
  background-color: colors-old.$back-color;
  min-height: 100vh;

  &-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    background-color: colors-old.$back-color;
    border-bottom: 1px solid colors-old.$divider-color;
    z-index: z-index.$low;
  }

  a {
    outline: revert;
  }

  h1,
  h2 {
    margin: 0;
  }

  .page-nav {
    position: relative;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    .btn-rte-cockpit {
      padding: 10px 20px;
      font-weight: font.$weight-normal;
      font-size: font.$size-large;
    }

    h2 {
      margin-left: 20px;
    }

    .menu-item-delimiter {
      margin: 0 15px;
    }
  }

  .page-content {
    max-width: 700px;
    width: 100%;
    margin: auto;
    padding: 120px 30px 0;
  }
}
</style>
