export type MarkMode =
  | "normal"
  | "highlight"
  | "fade-out"
  | "semi-fade-out"
  | "filter-out"
  | "hidden";

export function isFullyFaded(mode: MarkMode) {
  return mode === "fade-out" || mode === "filter-out";
}

export function isFaded(mode: MarkMode) {
  return isFullyFaded(mode) || mode === "semi-fade-out";
}

/**
 * Return the 'mark' of the most visible mode
 */
export function minFaded(
  a: MarkMode,
  b: MarkMode,
): "fade-out" | "semi-fade-out" | "normal" {
  if (!isFaded(a) || !isFaded(b)) {
    return "normal";
  }
  if (!isFullyFaded(a) || !isFullyFaded(b)) {
    return "semi-fade-out";
  }
  return "fade-out";
}
