import { defineStore } from "pinia";

export const useSwitchSessionStore = defineStore("switchSession", {
  state: () => ({
    active: false,
  }),
  actions: {
    toggleActive() {
      this.active = !this.active;
    },
    inactivate() {
      this.active = false;
    },
  },
});
