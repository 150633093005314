import { Vue } from "vue-class-component";

import { adjustScrollbarWidth } from "./scrollbarWidth";

export default class ScrollSupport extends Vue {
  stopScrollPropagation(e: WheelEvent) {
    const currentTarget = e.currentTarget as HTMLElement;
    if (
      (e.deltaY < 0 && currentTarget.scrollTop === 0) ||
      (e.deltaY > 0 &&
        currentTarget.scrollTop ===
          currentTarget.scrollHeight - currentTarget.offsetHeight)
    ) {
      e.preventDefault();
    }
  }

  adjustScrollbarWidth() {
    adjustScrollbarWidth(this.$el as HTMLElement);
  }
}
