<script lang="ts" setup>
import { cardKey } from "@/components/card/injectKeys";
import BaseList from "@/components/ui/BaseList/BaseList.vue";
import BaseListItem from "@/components/ui/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components/ui/BaseList/components/BaseListItems/BaseListItems.vue";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import { fullUrl } from "@/router/navigation";
import { useToastStore } from "@/store/toast";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

function stickyLinkToClipboard() {
  toClipboard(
    fullUrl({ stickyId: card.id }),
    /*$t*/ "message.stickyLinkCopied",
  );
}

function almLinkToClipboard() {
  toClipboard(card.almIssueUrl!, /*$t*/ "message.almLinkCopied");
}

function almIdToClipboard() {
  toClipboard(card.almId!, /*$t*/ "message.almIdCopied");
}

function toClipboard(value: string, message: string) {
  navigator.clipboard.writeText(value);
  useToastStore().show(message);
  dropdown.close();
}
</script>

<template>
  <BaseList role="dialog">
    <BaseListItems role="menu">
      <BaseListItem v-autofocus role="menuitem" @click="stickyLinkToClipboard">
        {{ $t("label.actionMenu.stickyLinkToClipboard") }}
      </BaseListItem>
      <BaseListItem
        v-if="card.almIssueUrl"
        role="menuitem"
        @click="almLinkToClipboard"
      >
        {{ $t("label.actionMenu.almLinkToClipboard") }}
      </BaseListItem>
      <BaseListItem v-if="card.almId" role="menuitem" @click="almIdToClipboard">
        {{ $t("label.actionMenu.almIdToClipboard") }}
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>
