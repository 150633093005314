import { Ref, computed, ref, watchEffect } from "vue";

export type TextAlign = "start" | "center" | "end";
const MARGIN = { start: 10, end: 30 };
const PADDING = 5;

/**
 * Helper to split a line/rect into two parts by a text
 */
export function useTextElement(
  textElem: Ref<SVGTextElement | undefined>,
  refs: {
    x: Ref<number>;
    y: Ref<number>;
    width: Ref<number>;
    height: Ref<number>;
    text: Ref<string | undefined>;
    align: Ref<TextAlign | undefined>;
  },
) {
  watchEffect(() => {
    if (textElem.value && refs.text.value) {
      cutText(textElem.value, refs.text.value);
    }
  });

  const textLen = ref(0);

  function cutText(textElem: SVGTextElement, text: string) {
    textElem.textContent = text;
    const allSpaces = MARGIN.start + MARGIN.end + 2 * PADDING;
    const maxLen = Math.max(0, size.value - allSpaces);
    let chars = text.length + 1;
    let len;
    do {
      chars--;
      len = textElem.getSubStringLength(0, chars);
    } while (len > maxLen);
    textElem.textContent = text.substring(0, chars);
    textLen.value = len;
  }

  function calcFirstEnd() {
    switch (refs.align.value) {
      case undefined:
      case "start":
        return MARGIN.start;
      case "center":
        return (size.value - textLen.value - 2 * PADDING) / 2;
      case "end":
        return size.value - textLen.value - 2 * PADDING - MARGIN.start;
    }
  }

  const hasText = computed(() => textLen.value > 0);
  const size = computed(() =>
    horizontal.value ? refs.width.value : refs.height.value,
  );
  const horizontal = computed(() => refs.width.value > refs.height.value);
  const firstEnd = computed(calcFirstEnd);
  const secondStart = computed(
    () => firstEnd.value + 2 * PADDING + textLen.value,
  );
  const textAttrs = computed(() => {
    return {
      x: refs.x.value + firstEnd.value + PADDING,
      y: refs.y.value + 3,
      transform: horizontal.value
        ? ""
        : `rotate(90, ${refs.x.value}, ${refs.y.value})`,
    };
  });

  return {
    hasText,
    textAttrs,
    horizontal,
    firstEnd,
    secondStart,
  };
}
