import { onUnmounted } from "vue";

import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { ServerTime } from "@/model/timer";
import { EnlargeTrigger } from "@/utils/analytics/events";

import EventBus from "./EventBus";

export const sendSetting = (name: string, value: unknown) =>
  sendKeyEvent("setting", name, value);
export const sendBoardSwitch = () => sendEvent("boardSwitch");
export const sendServerTick = (serverTime: ServerTime) =>
  sendEvent("serverTick", serverTime);
export const sendBoardLoaded = (boardId?: Board["id"]) =>
  sendEvent("boardLoaded", boardId);

// ------------------------------ New sticky note actions ------------------------------

interface StickyNoteActionBase<T extends string> {
  action: T;
  id: Card["id"];
}

export type StickyNoteEnlarge = StickyNoteActionBase<"enlarge"> & {
  focus?: boolean;
  trigger?: EnlargeTrigger;
};
export type StickyNoteShrink = StickyNoteActionBase<"shrink">;
type StickyNoteChanged = StickyNoteActionBase<"changed">;

export type StickyNoteAction =
  | StickyNoteEnlarge
  | StickyNoteShrink
  | StickyNoteChanged;

type StickyNoteActionPayloads = {
  [K in StickyNoteAction["action"]]: Omit<
    Extract<StickyNoteAction, { action: K }>,
    "id"
  >;
};

export const sendStickyNoteAction = <T extends StickyNoteAction["action"]>(
  id: string,
  action: StickyNoteActionPayloads[T],
) => {
  sendEvent("stickyNoteAction", { id, ...action });
};

export type EventType =
  | "boardSwitch"
  | "setting"
  | "message"
  | "serverTick"
  | "stickyNoteAction"
  | "boardLoaded";

function sendEvent(name: EventType, data?: unknown): void {
  EventBus.emit(name, data);
}

function sendKeyEvent(name: EventType, key: string, data?: unknown) {
  EventBus.emit(name, { key, value: data });
}

export function useEventBus() {
  const callbacks = new Array<{
    name: EventType;
    action: (e: unknown) => void;
  }>();

  onUnmounted(() => {
    for (const callback of callbacks) {
      EventBus.off(callback.name, callback.action);
    }
  });

  function onBoardSwitch(callback: () => void) {
    onEvent("boardSwitch", callback);
  }

  function onServerTick(callback: (serverTime: ServerTime) => void) {
    onEvent("serverTick", callback);
  }

  function onStickyNoteAction<T extends StickyNoteAction["action"]>(
    id: Card["id"] | null,
    action: T,
    callback: (action: Extract<StickyNoteAction, { action: T }>) => void,
  ) {
    onEvent("stickyNoteAction", (e: StickyNoteAction) => {
      if (id === null && e.action === action) {
        callback(e as Extract<StickyNoteAction, { action: T }>);
        return;
      }

      if (e.id === id && e.action === action) {
        callback(e as Extract<StickyNoteAction, { action: T }>);
      }
    });
  }

  function onEvent(name: EventType, action: (e?: any) => void) {
    callbacks.push({ name, action });
    EventBus.on(name, action);
  }

  return { onBoardSwitch, onServerTick, onStickyNoteAction };
}
