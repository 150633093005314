<script setup lang="ts">
import { getAlmInfo, isAlmSync } from "@/backend/Backend";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import { StickyType } from "@/model/stickyType";
import { useStickyTypeStore } from "@/store/stickyType";

const props = defineProps<{
  header?: string;
  modelValue?: StickyType | StickyType[];
  stickyTypes: StickyType[];
  disabled?: boolean;
}>();

const emit = defineEmits<{
  "update:model-value": [value: StickyType | StickyType[]];
}>();

function isAlm(type: StickyType) {
  return isAlmSync() && useStickyTypeStore().almStickyTypeOf(type)?.isMapped;
}
</script>

<template>
  <SelectableList
    v-keyboard-nav
    role="dialog"
    :header="props.header || $t('general.stickyTypes')"
    :aria-label="props.header || $t('general.stickyTypes')"
    :model-value="props.modelValue"
    :values="props.stickyTypes"
    :disabled="disabled"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #default="{ value }">
      <span>
        <TypeDot size="medium" :color="value.color" />
        {{ value.name }}
        <SvgIcon
          v-if="isAlm(value)"
          :name="getAlmInfo().icon"
          width="15"
          height="15"
        />
      </span>
    </template>
  </SelectableList>
</template>
