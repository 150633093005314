import { sender } from "@/backend/Sender";
import { receiveLinkMove } from "@/components/card/animator";
import { Id } from "@/model/baseTypes";
import { BoardCard, Card } from "@/model/card";
import { CardLink, Link, StickyLink } from "@/model/link";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { useToastStore } from "@/store/toast";
import {
  LinkCreateTrigger,
  LinkRemoveTrigger,
  objectiveLinkCreated,
  objectiveLinkRemoved,
  stickyLinkCreated,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { action, defineActions } from "./actions";
import { markActions } from "./mark";

export const linkActions = defineActions("link", {
  add: action(async (cardLink: { fromId: Link["from"]; toId: Link["to"] }) => {
    const link = await sender.addLink(cardLink.fromId, cardLink.toId);
    // this is used for performance reasons, there is a second call in subscriptionHandlers
    useLinkStore().add({
      id: link.id,
      from: link.from_sticky_id,
      to: link.to_sticky_id,
      type: link.link_type_id,
      state: "default",
    });
  }),
  remove: action(async (linkId: Link["id"]) => {
    await sender.deleteLink(linkId);
    // this is used for performance reasons, there is a second call in subscriptionHandlers
    useLinkStore().remove(linkId);
    linkActions.updateCardLinkedMarks("internal");
  }),
  addObjective: action(
    async (
      cardId: string,
      boardId: string,
      objectiveId: string,
      trigger?: LinkCreateTrigger,
    ) => {
      const { fromId, fromType, fromBoardType, toId, toBoardType } =
        getLinkObjectiveData(cardId, boardId, objectiveId);
      trackEvent(
        objectiveLinkCreated(
          fromId,
          fromType,
          fromBoardType,
          toId,
          toBoardType,
          trigger,
        ),
      );

      await sender.addObjectiveLink(cardId, boardId, objectiveId);
      useLinkStore().addObjectiveLink(cardId, { boardId, objectiveId });
    },
  ),
  removeObjective: action(
    async (
      cardId: string,
      boardId: string,
      objectiveId: string,
      trigger?: LinkRemoveTrigger,
    ) => {
      const { fromId, fromType, fromBoardType, toId, toBoardType } =
        getLinkObjectiveData(cardId, boardId, objectiveId);

      trackEvent(
        objectiveLinkRemoved(
          fromId,
          fromType,
          fromBoardType,
          toId,
          toBoardType,
          trigger,
        ),
      );

      await sender.deleteObjectiveLink(cardId, boardId, objectiveId);
      useLinkStore().removeObjectiveLink(cardId, { boardId, objectiveId });

      // Notify screen reader users that link was removed
      useToastStore().show(/*$t*/ "linkableCardRow.linkRemoved", {
        invisible: true,
      });
    },
  ),
  setCardLinkLabel: action(async (card: Card, cardLink: CardLink) => {
    if (cardLink.kind === "sticky") {
      await recreateStickyLink(cardLink);
    } else {
      await recreateObjectiveLink(card, cardLink.boardId, cardLink.objectiveId);
    }

    async function recreateStickyLink(stickyLink: StickyLink) {
      const link = useLinkStore().linksById[stickyLink.id];
      await linkActions.remove("internal", stickyLink.id);
      if (!link) return;

      await linkActions.add("internal", { fromId: link.from, toId: link.to });
    }

    async function recreateObjectiveLink(
      card: Card,
      boardId: string,
      objectiveId: string,
    ) {
      await linkActions.removeObjective(
        "internal",
        card.id,
        boardId,
        objectiveId,
      );
      await linkActions.addObjective("internal", card.id, boardId, objectiveId);
    }
  }),

  addByDrag: action(
    async (dragInfo: Id & { el: HTMLElement; dragId: number }) => {
      const currentBoard = useBoardStore().currentBoard();
      const boardCard = currentBoard.cards[dragInfo.id];
      const { from: linkFrom, to: linkTo } = useLinkStore().linking;

      useLinkStore().linking.from = null;
      useLinkStore().linking.to = null;
      if (linkFrom && linkTo) {
        if (linkTo.type === "sticky") {
          const to = useCardStore().cards[linkTo.id];
          trackEvent(
            stickyLinkCreated(
              linkFrom.id,
              linkFrom.type.functionality,
              useBoardStore().currentBoard().type,
              linkTo.id,
              to.type.functionality,
              "drag",
            ),
          );
          await linkActions.add("internal", {
            fromId: linkFrom.id,
            toId: linkTo.id,
          });
          markActions.reset(useBoardStore().currentBoard().cards[linkTo.id]);
          linkActions.updateCardLinkedMarks("internal");
        } else {
          await linkActions.addObjective(
            "dragDrop",
            linkFrom.id,
            currentBoard.id,
            linkTo.id,
            "drag",
          );
        }
        useDraggingStore().endLinkDragging(
          dragInfo.id,
          dragInfo.dragId,
          boardCard,
          dragInfo.el,
        );
      } else {
        receiveLinkMove(
          boardCard,
          useDraggingStore().dragging[dragInfo.dragId].pos,
          (coord) => useDraggingStore().dragLink(dragInfo.dragId, coord),
          () =>
            useDraggingStore().endLinkDragging(
              dragInfo.id,
              dragInfo.dragId,
              boardCard,
              dragInfo.el,
            ),
        );
      }
    },
  ),
  markCardLinkedCards: action((card: BoardCard) =>
    useLinkStore().markCardLinkedCards(card),
  ),
  updateCardLinkedMarks: action(() => useLinkStore().updateCardLinkedMarks()),
});

function getLinkObjectiveData(
  cardId: string,
  boardId: string,
  objectiveId: string,
) {
  const fromCard = useCardStore().cards[cardId];
  return {
    fromId: fromCard.id,
    fromType: fromCard.type.functionality,
    fromBoardType: useBoardStore().currentBoard().type,
    toId: objectiveId,
    toBoardType: useBoardsStore().boardById(boardId).type,
  };
}
