<script setup lang="ts">
import { computed } from "vue";

import OrganizationBaseTree from "@/components/OrganizationBaseTree/OrganizationBaseTree.vue";
import {
  OrganizationTreeNodeData,
  SelectedNode,
} from "@/components/OrganizationBaseTree/types";
import { ART_BOARDS, SOLUTION_BOARDS, TEAM_BOARDS } from "@/model/board";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";

const emit = defineEmits<{ select: [node: OrganizationTreeNodeData] }>();

const selected = computed<SelectedNode>(() => {
  const { arts } = useArtStore();
  if (!arts.length) return { level: "art", id: "" };

  const currentBoard = useBoardStore().currentBoard();
  const { type, artId } = currentBoard;
  const isSolutionTrainSession = useBoardsStore().hasSolutionBacklogBoard;

  if (TEAM_BOARDS.includes(type)) {
    const selectedArt = arts.find((art) => art.id === artId);
    return selectedArt
      ? { level: "art", id: selectedArt.id }
      : { level: "art", id: arts[0].id };
  }

  if (ART_BOARDS.includes(type)) {
    return isSolutionTrainSession
      ? { level: "solution-train" }
      : { level: "art", id: artId || arts[0].id };
  }

  if (SOLUTION_BOARDS.includes(type)) {
    return { level: "solution-train" };
  }

  // Flex boards fall back to selecting the first ART
  return { level: "art", id: arts[0].id };
});

const handleSelect = (node: OrganizationTreeNodeData) => {
  emit("select", node);
};
</script>

<template>
  <OrganizationBaseTree :selected="selected" @select="handleSelect" />
</template>
