import { Counter } from "./Counter";
import { Board } from "./board";
import color from "./color.module.scss";
import { RelativeCoordinate, WindowCoordinate } from "./coordinates";
import { Icon } from "./icon";
import { Art } from "./session";
import { AuthUser } from "./user";

export interface Id {
  id: string;
}

export type IdMap<T> = Record<string, T>;

export type WorkingMode = "planning" | "execution";

export type InfoLevel = "default" | "ok" | "warn" | "error";

export const infoLevelInfo: {
  [level in InfoLevel]: { color: string; backColor: string; icon: Icon };
} = {
  default: {
    color: color.back,
    backColor: "transparent",
    icon: "message/info",
  },
  ok: { color: color.ok, backColor: color.okBack, icon: "message/info" },
  warn: { color: color.warn, backColor: color.warnBack, icon: "message/warn" },
  error: {
    color: color.error,
    backColor: color.errorBack,
    icon: "message/error",
  },
};

// Our internal color type, representing [r, g, b, a], each on the range 0-1
export type Color = [number, number, number, number];

export type ArtId = number;

export const boardTypes = [
  "backlog",
  "program",
  "solution_backlog",
  "solution",
  "risk",
  "team",
  "flex",
  "objectives",
] as const;
export type BoardType = (typeof boardTypes)[number];

const boardI18nKeys: {
  [boardType in BoardType]: { short: string; long: string; icon: Icon };
} = {
  backlog: {
    short: /*$t*/ "board.backlog",
    long: /*$t*/ "board.backlog.long",
    icon: "board/backlog",
  },
  program: {
    short: /*$t*/ "board.program",
    long: /*$t*/ "board.program.long",
    icon: "board/planning",
  },
  solution_backlog: {
    short: /*$t*/ "board.solutionBacklog",
    long: /*$t*/ "board.solutionBacklog.long",
    icon: "board/solution-backlog",
  },
  solution: {
    short: /*$t*/ "board.solution",
    long: /*$t*/ "board.solution.long",
    icon: "board/solution",
  },
  risk: {
    short: /*$t*/ "board.risk",
    long: /*$t*/ "board.risk.long",
    icon: "board/risk",
  },
  team: {
    short: /*$t*/ "board.team",
    long: /*$t*/ "board.team.long",
    icon: "board/team",
  },
  flex: {
    short: /*$t*/ "board.flex",
    long: /*$t*/ "board.flex.long",
    icon: "board/canvas",
  },
  objectives: {
    short: /*$t*/ "board.objectives",
    long: /*$t*/ "board.objectives.long",
    icon: "board/objectives",
  },
};

export function boardTypeName(type: BoardType) {
  return boardI18nKeys[type].short;
}

export function boardIcon(board: Board) {
  return boardI18nKeys[board.type].icon;
}

export function boardTitle(
  board: Board,
  art?: Art | null,
): [string, Record<string, unknown>] {
  const key = boardI18nKeys[board.type];
  switch (board.type) {
    case "team":
      return [key.long, { team: board.team.name }];
    case "flex":
      return [key.long, { name: board.name }];
    default: {
      if (art === undefined) {
        return [key.short, {}];
      }
      const hasArt = board.artId && art?.name;
      return [key.long, { art: hasArt ? `(${art.name})` : "" }];
    }
  }
}

export function isBoardType(s: string) {
  return boardTypes.includes(s as BoardType);
}

export const riskTypes = ["R", "O", "A", "M", ""] as const;
export type RiskType = (typeof riskTypes)[number];

const riskI18nKeys: {
  [id in RiskType]: { short: string; long: string; icon: Icon };
} = {
  R: {
    short: /*$t*/ "risk.resolved.short",
    long: /*$t*/ "risk.resolved",
    icon: "risk/resolved",
  },
  O: {
    short: /*$t*/ "risk.owned.short",
    long: /*$t*/ "risk.owned",
    icon: "risk/owned",
  },
  A: {
    short: /*$t*/ "risk.accepted.short",
    long: /*$t*/ "risk.accepted",
    icon: "risk/accepted",
  },
  M: {
    short: /*$t*/ "risk.mitigated.short",
    long: /*$t*/ "risk.mitigated",
    icon: "risk/mitigated",
  },
  "": {
    short: /*$t*/ "risk.unRoam.short",
    long: /*$t*/ "risk.unRoam",
    icon: "risk/roam",
  },
};

export function riskTypeName(risk: RiskType) {
  return riskI18nKeys[risk].short;
}

export function riskTitle(risk: RiskType) {
  return riskI18nKeys[risk].long;
}

export function riskIcon(risk: RiskType) {
  return riskI18nKeys[risk].icon;
}

export const statusClasses = [
  "undefined",
  "todo",
  "in-progress",
  "done",
] as const;
export type StatusClass = (typeof statusClasses)[number];

export const statusClassNames: { [key in StatusClass]: string } = {
  undefined: /*$t*/ "statusClass.unknown",
  todo: /*$t*/ "statusClass.toDo",
  "in-progress": /*$t*/ "statusClass.inProgress",
  done: /*$t*/ "statusClass.done",
};

export type AlmSourceId = number | string;

export type ExecutionMode = "normal" | "preview" | "confirm";

type AlmType = "jira" | "ac" | "ado" | "";

export interface AlmTypeInfo {
  name: string;
  cockpitPath: string;
  icon: `alm/${Exclude<AlmType, "">}` | "base/minus";
  colorIcon: `alm/${Exclude<AlmType, "">}-color` | "base/minus";
  isUserMapped: boolean;
  hasAssignee: boolean;
}

export const almTypeInfo: { [type in AlmType]: AlmTypeInfo } = {
  jira: {
    name: "Jira",
    cockpitPath: "jira",
    icon: "alm/jira",
    colorIcon: "alm/jira-color",
    isUserMapped: true,
    hasAssignee: true,
  },
  ado: {
    name: "Azure DevOps",
    cockpitPath: "ado",
    icon: "alm/ado",
    colorIcon: "alm/ado-color",
    isUserMapped: false,
    hasAssignee: false,
  },
  ac: {
    name: "Rally",
    cockpitPath: "ac",
    icon: "alm/ac",
    colorIcon: "alm/ac-color",
    isUserMapped: true,
    hasAssignee: true,
  },
  "": {
    name: "",
    cockpitPath: "",
    icon: "base/minus",
    colorIcon: "base/minus",
    isUserMapped: false,
    hasAssignee: true,
  },
};

export interface Pointer {
  user: AuthUser;
  relativePos: RelativeCoordinate;
  windowPos: Array<{ pos: WindowCoordinate; index: number }>;
  timestamp: number;
}

export interface Statistics {
  calls: Counter;
  publishes: Counter;
  sends: Counter;
  receives: Counter;
  subscriptions: number;
  reconnects: number;
}
