import CardFlag from "./CardFlag";
import { AlmSourceId, RiskType } from "./baseTypes";
import { RelativeCoordinate } from "./coordinates";
import { Link } from "./link";
import { MarkMode } from "./markMode";
import { LinkedObjective } from "./objective";
import { DependencyTeamFilter } from "./search";
import { Iteration, Team } from "./session";
import { AlmItemStatus, Status } from "./status";
import { StickyType } from "./stickyType";
import { AuthUser } from "./user";

export interface BoardCard {
  data: Card;
  meta: CardMeta;
}

export interface CardMeta {
  pos: RelativeCoordinate;
  zIndex: number;
  mark: MarkMode;
  isHighlighted: boolean;
  isRelatedToHighlighted: boolean;
  dependencyTeamFilter: DependencyTeamFilter | null;
  editing: boolean;
  dragging: boolean;
  shouldAnimate?: boolean;
}

/**
 * Model that represents a card component on the UI.
 */
export interface Card {
  id: string;
  groupId?: string | null;
  almId?: string; // the ID of the external ALM item
  almIssueUrl?: string;
  almSourceId: AlmSourceId | null;
  text: string;
  points: number;
  priority: number;
  teamId: string | null;
  precondTeam?: Team; // team that created the sticky
  dependTeam?: Team; // team that the sticky depends on
  iterationId: number | null;
  editor: AuthUser | null;
  links: Link[];
  type: StickyType;
  flagType: CardFlag;
  risk?: RiskType;
  status?: AlmItemStatus;
  transition?: string;
  objectives: LinkedObjective[];
  reactions?: Reactions;
  artId: string | null;
  assignee: AuthUser | null;
  reporter: AuthUser | null;
} //TODO subtypes depending on type.functionality

export interface BaseStickyInfo {
  id: string;
  groupId?: string;
  text: string;
  type: StickyType;
  flag: CardFlag;
  iteration?: Iteration;
  team?: Team;
  artId?: string;
  almId?: string;
  status?: Status;
  points: number;
  precondTeam?: Team;
  dependTeam?: Team;
}

export type Reactions = { [reaction in Reaction]: AuthUser[] };

export const reactions = ["hot", "love", "increment"] as const;

export type Reaction = (typeof reactions)[number];

const reactionLabels: { [key in Reaction]: string } = {
  hot: /*$t*/ "label.reaction.hot",
  increment: /*$t*/ "label.reaction.increment",
  love: /*$t*/ "label.reaction.love",
};

export function getReactionLabel(reaction: Reaction) {
  return reactionLabels[reaction];
}
