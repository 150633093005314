import { createPopper } from "@popperjs/core";
import { Placement } from "@popperjs/core/lib/enums";
import { Ref, computed, nextTick, ref, watch } from "vue";

import { useUserStore } from "@/store/user";
import { useZoomStore } from "@/store/zoom";

export interface ActionMenuConfig {
  placement: Placement;
  offset: [number, number];
}

interface Props {
  referenceEl: Ref<HTMLElement | undefined>;
  open: Ref<boolean>;
  disabled: Ref<Readonly<boolean>>;
  config: Ref<ActionMenuConfig>;
}

export function useActionMenu({ referenceEl, open, disabled, config }: Props) {
  const isOpen = computed(() => {
    return (
      !!referenceEl.value &&
      open.value &&
      !disabled.value &&
      !useZoomStore().zooming &&
      useUserStore().isAllowed("edit")
    );
  });

  const popperEl = ref<HTMLElement>();
  const popper = ref<ReturnType<typeof createPopper> | null>(null);

  watch(isOpen, () => {
    if (isOpen.value) {
      void newPopper();
    } else {
      destroyPopper();
    }
  });

  watch(config, () => {
    void popper.value?.setOptions(popperOptions(config));
  });

  watch(referenceEl, () => {
    destroyPopper();
    void newPopper();
  });

  async function newPopper() {
    if (popper.value) return;

    await nextTick();

    if (!referenceEl.value || !popperEl.value) return;

    popper.value = createPopper(
      referenceEl.value,
      popperEl.value,
      popperOptions(config, { name: "flip", enabled: false }),
    );
  }

  function popperOptions(
    config: Ref<ActionMenuConfig>,
    ...modifiers: object[]
  ) {
    return {
      placement: config.value.placement,
      modifiers: [
        { name: "offset", options: { offset: config.value.offset } },
        ...modifiers,
      ],
    };
  }

  function destroyPopper() {
    popper.value?.destroy();
    popper.value = null;
  }

  function updateRef(ref: HTMLElement) {
    popperEl.value = ref;
  }

  function updateMenu() {
    popper.value?.update();
  }

  function isInMenu(node: Node) {
    return popperEl.value?.contains(node);
  }

  return { isOpen, isInMenu, updateMenu, updateRef };
}
