export interface PaintFunctions {
  canvasFontSize: number;
  canvasFont: string;
  fontSizeForOneLine: number;
  lineHeight: number;
  widthInCanvas: number;
  heightInCanvas: number;

  setCanvasFont(size: number): void;

  setElementFontSize(size: number): void;

  stringWidth(s: string): number;

  charWidth(char1: string, char2?: string): number;

  // display text lines on the canvas (for debugging reasons only)
  displayLines(
    x: number,
    y: number,
    text: string,
    lines: Array<[number, number]>,
  ): void;
}

export interface PaintLayer {
  init(el: SVGElement | HTMLElement): PaintFunctions | null;
}

export let thePaintLayer: PaintLayer;

export function registerPaintLayer(paintLayer: PaintLayer) {
  thePaintLayer = paintLayer;
}
