import { defineStore } from "pinia";

import { receiveCardMove } from "@/components/card/animator";
import { captureMessage } from "@/error/sentry";
import { windowToRelative } from "@/math/coordinate-systems";
import { times } from "@/math/coordinates";
import { zoomFactor } from "@/model/Settings";
import { BoardType, Id, boardTitle } from "@/model/baseTypes";
import {
  Board,
  BoardData,
  BoardId,
  IterationStatus,
  isSolutionBoard,
} from "@/model/board";
import { BoardCard, Card } from "@/model/card";
import { RelativeCoordinate, WindowCoordinate } from "@/model/coordinates";
import { FlexType } from "@/model/flexboard";
import { Shape } from "@/model/shape";
import { StickyType } from "@/model/stickyType";
import { translate } from "@/translations/i18n";

import { useArtStore } from "./art";
import { useBoardStore } from "./board";
import { boardByType } from "./helpers/boardByType";
import {
  flexBoard,
  objectivesBoard,
  objectivesBoardId,
} from "./helpers/frontendBoard";
import { cardsMirrorableToNonFlexBoard } from "./helpers/mirror";
import { useUserStore } from "./user";

type BoardMap = Record<Board["id"], Board>;

export const useBoardsStore = defineStore("boards", {
  state: () => {
    return {
      boards: {} as BoardMap,
    };
  },
  getters: {
    findIterations: (state) => {
      return (cardId: keyof Board["cards"]) => {
        for (const board of Object.values(state.boards)) {
          if (board.cards[cardId] && "iterations" in board) {
            return board.iterations;
          }
        }
      };
    },
    boardById: (state) => {
      return (boardId: Board["id"]): Board => {
        const board = state.boards[boardId];
        if (!board) {
          void captureMessage("Could not find board", { info: { boardId } });
          return { id: boardId } as Board;
        }
        return board;
      };
    },
    boardByType: (state) => {
      return <T extends BoardType>(
        type: T,
        options: { teamId?: string; artId?: string } = {},
      ): BoardData<T> => boardByType(state.boards, type, options);
    },
    stickyTypeOriginBoard() {
      return (type: StickyType, pos: WindowCoordinate) => {
        const teamId =
          useBoardStore().positionalCardProperties(windowToRelative(pos))
            .teamId ?? undefined;
        return this.boardByType(type.origin, { teamId });
      };
    },
    planningBoards() {
      return (artLevelOnly = false) => [
        this.boardByType("program"),
        ...(!artLevelOnly && this.hasSolutionBoard
          ? [this.boardByType("solution")]
          : []),
      ];
    },
    artBoardsToLoad() {
      return (force = false) =>
        [...this.currentArtBoards, ...this.solutionBoards]
          .filter((board) => {
            const wasLoaded = board.loaded && !force;
            return board !== useBoardStore().currentBoard() && !wasLoaded;
          })
          .sort((boardA, boardB) => order(boardA) - order(boardB));

      function order(board: Board) {
        const art = board.artId === useArtStore().currentArt.id ? 0 : 5;
        const type =
          board.type === "backlog" ? 0 : board.type === "team" ? 1 : 2;
        return art + type;
      }
    },
    artTeamBoards: (state) => {
      const teamBoards = Object.values(state.boards).filter(
        (board): board is BoardData<"team"> => board.type === "team",
      );
      return () => {
        return teamBoards.filter((board) =>
          useArtStore().isCurrentArt(board.team.artId),
        );
      };
    },
    currentArtBoards: (state) =>
      Object.values(state.boards).filter(
        (board) => (board.artId || "") === (useArtStore().currentArt.id || ""),
      ),
    solutionBoards: (state) =>
      Object.values(state.boards).filter((board) =>
        isSolutionBoard(board.type),
      ),
    boardsInited: (state) =>
      Object.keys(state.boards).some((boardId) => !boardId.startsWith("$")),
    boardsLoading: (state) =>
      Object.values(state.boards).some((board) => board.loaded === 0),
    backlogStickies(): BoardCard[] {
      return Object.values({
        ...this.boardByType("backlog").cards,
        ...(this.hasSolutionBacklogBoard
          ? this.boardByType("solution_backlog").cards
          : {}),
      });
    },
    hasSolutionBacklogBoard: (state) =>
      Object.values(state.boards).some(
        (board) => board.type === "solution_backlog",
      ),
    hasSolutionBoard: (state) =>
      Object.values(state.boards).some((board) => board.type === "solution"),
    mirrorTargetBoards: (state) => {
      return (cards: Card[]) =>
        Object.values(state.boards).filter(
          (board) =>
            !isCurrentBoard(board) &&
            cardsMirrorableToNonFlexBoard(cards, board),
        );
    },
    boardTitle() {
      return (id?: string) => {
        const board = id ? this.boardById(id) : useBoardStore().board;
        return board
          ? translate(boardTitle(board, useArtStore().artById(board.artId)))
          : "";
      };
    },
  },
  actions: {
    addBoards(boards: BoardMap) {
      this.boards = {
        ...this.boards,
        ...boards,
      };
    },
    addBoard<T extends BoardType>(board: BoardData<T>): BoardData<T> {
      this.boards = { ...this.boards, [board.id]: board };
      return board;
    },
    addFlexBoard(id: string, name: string, flexType: FlexType) {
      return this.addBoard<"flex">(flexBoard(id, name, flexType));
    },
    removeFlexBoard(id: string) {
      delete this.boards[id];
    },
    updateObjectiveBoardId() {
      Object.values(this.boards).forEach((board) => {
        if (board.type === "objectives") {
          board.id = objectivesBoardId();
        }
      });
    },
    resetBoards() {
      this.boards = { objectives: objectivesBoard() };
    },
    updateBoards<T extends BoardType>(
      type: T,
      boards: Array<BoardData<T>>,
      update: (exist: BoardData<T>, board: BoardData<T>) => void,
    ) {
      const bs = this.boards;
      for (const id in bs) {
        if (
          bs[id].type === type &&
          !boards.find((board) => board.id === bs[id].id)
        ) {
          delete this.boards[id];
        }
      }
      boards.forEach((board) => {
        const found = this.boards[board.id] as BoardData<T>;
        if (found) {
          update(found, board);
        } else {
          this.boards[board.id] = board;
        }
      });
    },
    setVelocity(e: { id: string; velocity: number; iteration: number }) {
      const board = this.boards[e.id] as BoardData<"team">;
      board.iterations[e.iteration] = {
        velocity: e.velocity,
        load: board.iterations[e.iteration].load,
        state: board.iterations[e.iteration].state,
      };
    },
    setCardSize(e: BoardId & { factor: number }) {
      if (
        !useUserStore().isAllowed("edit") ||
        !useUserStore().isNonTeamZoomAllowed(useBoardStore().currentBoard())
      ) {
        return false;
      }

      if (!(e.boardId in this.boards)) {
        // Board is not present: it could be a flex board, which is not loaded
        // until it is visited for the first time. However, we could receive an
        // update from someone else on the board. Since the flex board content
        // will be loaded the first time we access it, it's safe to ignore the
        // event.
        return false;
      }

      this.boards[e.boardId].cardSize = {
        factor: e.factor,
        ...times(zoomFactor, e.factor),
      };

      return true;
    },
    setIterationStatus(e: {
      boardId?: string;
      id: number;
      status: IterationStatus;
      detail?: string;
    }) {
      const boardId = e.boardId || useBoardStore().currentBoard().id;
      const board = this.boards[boardId] as BoardData<"team">;
      const iter = board.iterations[e.id];
      if (iter) {
        iter.state.status = e.status;
        iter.state.detail = e.detail || null;
        if (e.status === "success") {
          setTimeout(
            () =>
              this.setIterationStatus({ boardId, id: e.id, status: "synced" }),
            5000,
          );
        }
      }
    },
    cardToFront(e: Id & Partial<BoardId>) {
      const board = e.boardId ? this.boards[e.boardId] : useBoardStore().board;
      if (!board) {
        // Board is not present: it could be a flex board, which is not loaded
        // until it is visited for the first time. However, we could receive an
        // update from someone else on the board. Since the flex board content
        // will be loaded the first time we access it, it's safe to ignore the
        // event.
        return false;
      }
      const boardCard = board.cards[e.id];
      if (boardCard) {
        // card might just have been deleted
        boardCard.meta.zIndex = ++board.maxZ;
        return true;
      }
    },
    setCardPos(e: Id & BoardId & RelativeCoordinate) {
      const boardCard = this.boards[e.boardId].cards[e.id];
      receiveCardMove(boardCard, e, (pos) => {
        boardCard.meta.pos.x = pos.x;
        boardCard.meta.pos.y = pos.y;
      });
    },
    addShape(boardId: string, shape: Shape) {
      const { shapes, index } = this.findShape(boardId, shape.id);
      if (index >= 0) {
        return shapes[index];
      }
      shapes.push(shape);
      return shape;
    },
    editShape(boardId: string, shape: Shape) {
      const { shapes, index } = this.findShape(boardId, shape.id);
      if (index >= 0) {
        shapes[index] = shape;
      }
    },
    removeShape(boardId: string, id: string) {
      const { shapes, index } = this.findShape(boardId, id);
      if (index >= 0) {
        shapes.splice(index, 1);
      }
    },
    findShape(boardId: string, id: string) {
      const shapes = this.boards[boardId]?.shapes;
      return { shapes, index: shapes.findIndex((s) => s.id === id) };
    },
  },
});

function isCurrentBoard(board: Board) {
  return board.id === useBoardStore().board?.id;
}
