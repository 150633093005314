// Wrap window.MutationObserver so that they can be disconnected/reconnected to improve performance.

const observers = new Array<Wrapped>();

export function wrapMutationObserver() {
  window.MutationObserver = WrappedMutationObserver;
}

export function disconnectObservers() {
  for (const wrapped of observers) {
    wrapped.observer.disconnect();
  }
}

export function reconnectObservers() {
  for (const wrapped of observers) {
    for (const param of wrapped.observeParams) {
      wrapped.observer.observe(param.target, param.options);
    }
  }
}

interface ObserveParams {
  target: Node;
  options?: MutationObserverInit;
}

interface Wrapped extends MutationObserver {
  observeParams: ObserveParams[];
  observer: MutationObserver;
}

class WrappedMutationObserver extends MutationObserver {
  constructor(callback: MutationCallback, force?: boolean) {
    super(callback);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const observer = this;
    if (force) {
      return observer;
    }
    const obs = {
      observer,
      observeParams: new Array<ObserveParams>(),
      observe(target: Node, options?: MutationObserverInit) {
        this.observeParams.push({ target, options });
        observer.observe(target, options);
      },
      takeRecords(): MutationRecord[] {
        return observer.takeRecords();
      },
      disconnect() {
        this.observeParams = [];
        observer.disconnect();
      },
    };
    observers.push(obs);
    return obs;
  }
}
