<script setup lang="ts">
import { nextTick, ref } from "vue";

import BoardBase from "@/components/board/BoardBase";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { isKeyDown } from "@/components/utils/Shortcuts";
import { boundsCoord, clientCoord } from "@/model/coordinates";
import { useContextMenuStore } from "@/store/contextMenu";

import BoardContextMenu, { BoardContextMenuMode } from "./BoardContextMenu.vue";

const props = defineProps<{
  board: BoardBase;
  mode: BoardContextMenuMode;
  tooltip?: string;
}>();

const buttonEl = ref<HTMLElement | null>(null);

function showMenu(e: MouseEvent) {
  const fromKeyboard = isKeyDown("Enter") && !!buttonEl.value;
  const position = fromKeyboard ? boundsCoord(buttonEl.value!) : clientCoord(e);

  // Open the context menu where the user clicked
  useContextMenuStore().open(
    BoardContextMenu,
    {
      position,
      mode: props.mode,
    },
    { close: onKebabClose(fromKeyboard) },
  );
  return;
}

/**
 * When the kebab menu is closed, return the focus to the trigger
 * (but then blur it to hide the focus ring, unless it was opened by keyboard)
 * If we don't do this, the tab index moves to the start of the page because of how
 * the context menu is rendered
 */
const onKebabClose = (openedByKeyboard: boolean) => async () => {
  await nextTick();
  buttonEl.value?.focus();

  if (!openedByKeyboard) {
    await nextTick();
    buttonEl.value?.blur();
  }
};
</script>

<template>
  <IconButton
    ref="buttonEl"
    class="kebab-menu"
    icon="base/more"
    :tooltip="props.tooltip"
    @click="showMenu"
  />
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

:deep() {
  // Triple specificity to override button styles
  .kebab-menu.kebab-menu.kebab-menu {
    font-size: inherit;

    svg {
      width: 0.5em;
      height: 0.5em;
    }
  }
}
</style>
