/**
 * Converts specific characters from a string into their corresponding HTML entities.
 */
export function convertCharToHtmlEntity(s: string, i: number) {
  const c = s.charAt(i);
  if (c === "\n") {
    return "<br>";
  }
  if (c === " " && s.charAt(i - 1) === " ") {
    return "&nbsp;";
  }
  if (c === "<") {
    return "&lt;";
  }
  if (c === "&") {
    return "&amp;";
  }
  return c;
}

export function unique(name: string, exists: (name: string) => boolean) {
  let suffix = "";
  while (exists(name + suffix)) {
    suffix = suffix ? "" + (+suffix + 1) : "2";
  }
  return name + suffix;
}

export function toKebabCase(str = ""): string {
  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
}

export function toSnakeCase(str = ""): string {
  return toKebabCase(str).replace(/-/g, "_");
}
