<script setup lang="ts">
import { computed } from "vue";

import IconButton from "@/components/ui/IconButton/IconButton.vue";
import NavigableList from "@/components/ui/NavigableList/NavigableList.vue";
import { Team } from "@/model/session";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { useTeamStore } from "@/store/team";

import BaseModal from "./BaseModal.vue";

const teams = computed(() => [
  useTeamStore().noTeam,
  ...useTeamStore().teamsInCurrentArt,
]);

function team(team: Team) {
  useSelectionStore().setTeam(team);
  useModalStore().hide();
}
</script>

<template>
  <BaseModal
    class="team-modal"
    aria-labelledby="team-modal-title"
    :description="$t('label.teamModal.description')"
  >
    <template #header-title>
      <span id="team-modal-title" v-autofocus>
        {{ $t("teamModal.title") }}
      </span>
    </template>
    <div class="scrollable-list">
      <NavigableList :first-list="teams" @select="team">
        <template #row="{ item }">
          <div class="item">
            <span>{{ item.name }}</span>
            <IconButton icon="org/team" />
          </div>
        </template>
      </NavigableList>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
.team-modal {
  .item {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
