<template>
  <hr aria-hidden="true" />
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

hr {
  border: 1px solid colors-old.$light-background-color;
  width: 100%;
}
</style>
