<template>
  <div data-no-screenshot>
    <ContextMenu :board="board" />

    <TopMenu v-if="!drawMode" />
    <SideMenu v-if="!drawMode" />
    <BottomMenu :board="board" />

    <TimerPanel />
    <StickyActivityPanel />
    <BoardDiffPanel />
    <SwitchSessionPanel />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { boardActions } from "@/action/boardActions";
import BoardDiffPanel from "@/components/ActivityPanel/BoardDiffPanel.vue";
import StickyActivityPanel from "@/components/ActivityPanel/StickyActivityPanel.vue";
import ContextMenu from "@/components/ContextMenu/ContextMenu.vue";
import SwitchSessionPanel from "@/components/SwitchSessionPanel/SwitchSessionPanel.vue";
import TimerPanel from "@/components/Timer/TimerPanel.vue";
import FluidBoard from "@/components/board/FluidBoard";
import ShortcutsModal from "@/components/modal/ShortcutsModal.vue";
import SettingsModal from "@/components/modal/settings/SettingsModal.vue";
import { key } from "@/components/utils/Shortcuts";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { useDrawStore } from "@/store/draw";
import { useModalStore } from "@/store/modal";

import BottomMenu from "./BottomMenu/BottomMenu.vue";
import SideMenu from "./SideMenu/SideMenu.vue";
import TopMenu from "./TopMenu/TopMenu.vue";

@Component({
  components: {
    ContextMenu,
    BottomMenu,
    BoardDiffPanel,
    SwitchSessionPanel,
    StickyActivityPanel,
    TimerPanel,
    SideMenu,
    TopMenu,
  },
})
export default class MenuContainer extends mixins(ShortcutUser, EventBusUser) {
  @Prop() readonly board?: FluidBoard;

  created() {
    this.globalActionShortcut(boardActions.screenshot, {
      prevent: false,
      withEvent: true,
    });
    this.globalShortcut("?", () => useModalStore().open(ShortcutsModal));
    this.globalShortcut(key(",", "altCtrl"), () =>
      useModalStore().open(SettingsModal, { attrs: { isUserOnBoard: true } }),
    );
  }

  get drawMode() {
    return useDrawStore().active;
  }
}
</script>
