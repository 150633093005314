<template>
  <ModalLegacy
    class="overview-modal"
    :width="width"
    :class="[color]"
    :description="
      $t('label.overviewModal.description', { section: names.join(' ') })
    "
    :set-return-focus="setReturnFocus"
  >
    <ModalHeaderLegacy :title="$t('overviewModal.title')">
      <span v-for="(name, index) in names" :key="index">{{ name }}</span>
    </ModalHeaderLegacy>
    <div class="head">
      <div v-if="load !== undefined">
        {{
          $t("modal.loadValue", {
            number: load,
          })
        }}
      </div>
      <div v-if="load !== undefined">
        {{ $t("modal.capacityValue", { capacity: velocity }) }}
      </div>
    </div>
    <div
      class="scrollable"
      :style="{ fontSize }"
      @wheel="stopScrollPropagation"
    >
      <StickyNote
        v-for="(card, index) in cards"
        :key="card.data.id"
        :card="card.data"
        :card-meta="card.meta"
        :override="{ size: cardSize, position: cardMiddlePos(index) }"
        :draggable="false"
        class="disabled"
        aria-disabled="true"
        :full-details="true"
      />
    </div>
    <div class="bottom">
      <button
        class="action line line-right item"
        :aria-label="$t('label.scaleStickies.bigger')"
        @click="scale(-1)"
      >
        <SvgIcon name="modal-overview/font-up" width="20" height="20" />
      </button>
      <button
        class="action line line-right item"
        :aria-label="$t('label.scaleStickies.smaller')"
        @click="scale(1)"
      >
        <SvgIcon name="modal-overview/font-down" width="20" height="20" />
      </button>
    </div>
  </ModalLegacy>
</template>

<script lang="ts">
import { clamp } from "lodash-es";
import { computed, reactive } from "vue";
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Provide } from "vue-property-decorator";

import StickyNote from "@/components/StickyNote/StickyNote.vue";
import {
  BoardMeta,
  boardKey,
  boardMetaKey,
} from "@/components/board/injectKeys";
import { cardsInLocation } from "@/components/board/location/BoardLocation";
import { ActionType } from "@/components/card/actions";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { loadState } from "@/components/utils/capacity";
import ScrollSupport from "@/mixins/ScrollSupport";
import { InfoLevel } from "@/model/baseTypes";
import { BoardCard } from "@/model/card";
import { boardCoord, relativeCoord } from "@/model/coordinates";
import { isRisk } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useClientSettingsStore } from "@/store/clientSettings";
import { overviewModalSeen } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import ModalHeaderLegacy from "./ModalHeaderLegacy.vue";
import ModalLegacy from "./ModalLegacy.vue";

// Gap between new sticky notes
const stickyNoteGap = 8;

@Component({
  name: "OverviewModal",
  components: { SvgIcon, ModalHeaderLegacy, ModalLegacy, StickyNote },
})
export default class OverviewModal extends mixins(ScrollSupport) {
  @Prop(String) readonly boardId!: string;
  @Prop(Array) readonly location!: string[];
  @Prop(String) readonly velocity?: string;
  @Prop(String) readonly load?: string;
  @Prop(String) readonly setReturnFocus?: string; // Query selector where focus will be sent when the modal is closed

  get board() {
    return useBoardsStore().boardById(this.boardId);
  }

  mounted() {
    trackEvent(overviewModalSeen());
  }

  // Set board meta to use coordinates on (0, 0) to (1, 1)
  // This simplifies the calculations for card position
  @Provide({ to: boardMetaKey as symbol })
  boardMeta: BoardMeta = reactive({
    size: boardCoord(1, 1),
  });

  @Provide({ to: boardKey as symbol })
  get boardData() {
    return computed(() => useBoardStore().currentBoard());
  }

  get loc() {
    return useBoardStore().boardLocationFromIndex(this.location);
  }

  get cards() {
    return cardsInLocation(this.board, this.loc);
  }

  get names() {
    return (this.loc as any).names?.() || [this.loc.name];
  }

  get columns() {
    return useClientSettingsStore().overviewScale;
  }

  get fontSize() {
    return 100 - (this.columns - 4) * 10 + "%";
  }

  get width() {
    return document.documentElement.clientWidth * 0.85;
  }

  get cardSize() {
    return this.size - stickyNoteGap * 2;
  }

  scale(diff: number) {
    const cols = clamp(this.columns + diff, 4, 8);
    if (cols !== this.columns) {
      useClientSettingsStore().overviewScale = cols;
    }
  }

  actions(card: BoardCard): ActionType[] {
    return isRisk(card.data) ? ["risk"] : ["points"];
  }

  get color() {
    const state = loadState(+(this.load || 0), +(this.velocity || 0));
    const colors: Record<InfoLevel, string> = {
      default: "",
      ok: "green",
      warn: "orange",
      error: "red",
    };
    return colors[state];
  }

  pos(index: number) {
    return relativeCoord(
      (0.5 + (index % this.columns)) / this.columns,
      (0.5 + Math.floor(index / this.columns)) / this.rows,
    );
  }

  /**
   * Calculate Card's position in the appropriate row/column
   *
   * @param index Card's index in the array of cards
   */
  cardMiddlePos(index: number) {
    const left = () => {
      const colNumber = index % this.columns;
      const previousCardColsWidth = colNumber * this.size;
      return previousCardColsWidth + stickyNoteGap;
    };

    const top = (): number => {
      const rowNumber = Math.floor(index / this.columns);
      const previousCardRowsHeight = rowNumber * this.size;
      return previousCardRowsHeight + stickyNoteGap;
    };

    const cardMiddle = this.cardSize / 2;

    return relativeCoord(left() + cardMiddle, top() + cardMiddle);
  }

  get boardWidth() {
    return this.width - 60;
  }

  get rows() {
    return Math.ceil(this.cards.length / this.columns);
  }

  get size() {
    return this.boardWidth / this.columns;
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.overview-modal {
  &.red > div {
    background-color: colors-old.$error-back-color;
  }

  &.orange > div {
    background-color: colors-old.$warn-back-color;
  }

  h2 span {
    margin: 0 1em;
  }

  .head div {
    width: 30%;
  }

  .scrollable {
    top: 9rem;
    margin: 0 1rem 4rem;
    overscroll-behavior: contain;
  }

  .bottom {
    img {
      height: 1em;
    }
  }

  .card {
    .text {
      bottom: 1.2em;
    }

    .team {
      height: 0;
    }
  }

  // Disable interactions with new sticky notes until we can ensure updates
  // reflect properly on the board (without refreshing)
  // (mimics existing behaviour with old cards)
  // NOTE: this doesn't affect tab/keyboard interactions
  .disabled {
    pointer-events: none;
  }

  // Override legacy-modal row styles, because
  // this interferes with footer .row styles
  .sticky-note {
    .row {
      width: unset;
      margin: 0;
    }
  }
}
</style>
