<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { ApplicationError } from "@/error/ApplicationError";
import { currentRoute } from "@/router/navigation";
import { useGlobalErrorStore } from "@/store/globalError";
import { useUserStore } from "@/store/user";
import {
  errorDialogReloadClicked,
  errorDialogStatusLinkClicked,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isCloud } from "@/utils/env/Environment";
import { isFeatureEnabled } from "@/utils/env/feature";

const showDetails = ref(false);

const { t } = useI18n();

function reload() {
  trackEvent(errorDialogReloadClicked());
  window.location.reload();
}

function trackStatusLinkClicked() {
  trackEvent(errorDialogStatusLinkClicked());
}

const error = computed(() => useGlobalErrorStore().globalError);
const errorDetails = computed(() => useGlobalErrorStore().errorDetails);
const errorDetailsURI = computed(() =>
  encodeURI(JSON.stringify(errorDetails.value || {})),
);
const location = computed(() => window.location.href);
const message = computed(() =>
  JSON.stringify((error.value?.error as ApplicationError)?.contexts, null, 2),
);

const statusPageEnabled = computed(() =>
  isFeatureEnabled(currentRoute(), "status-page"),
);

/**
 * If user is an admin, on cloud, prompt them to contact support.
 * Otherwise, prompt them to inform their admin.
 */
const followupActions = computed(() => {
  if (isCloud && useUserStore().technicalUser?.role === "admin") {
    return t("globalError.contactSupport");
  }
  return t("globalError.informAdmin");
});
</script>

<template>
  <div
    v-if="error"
    v-focus-trap
    class="global-error"
    role="alertdialog"
    aria-modal="true"
    aria-labelledby="global-error-title"
  >
    <div class="content">
      <div id="global-error-title" class="text">
        <p>{{ $t("globalError.somethingWrong") }}</p>
        <p>
          <I18nT
            :keypath="
              statusPageEnabled
                ? /*$t*/ 'globalError.tryReloadOrStatus'
                : /*$t*/ 'globalError.tryReload'
            "
            scope="global"
          >
            <template #reload>
              <button @click="reload">
                {{ $t("globalError.tryReload.reload") }}
              </button>
            </template>
            <template #status>
              <a
                :href="`/#/page/status?errorDetails=${errorDetailsURI}`"
                target="_blank"
                @click="trackStatusLinkClicked"
              >
                {{ $t("globalError.tryReload.status") }}
              </a>
            </template>
          </I18nT>
        </p>
        <p>{{ followupActions }}</p>
      </div>
      <div class="details">
        <div>{{ errorDetails?.time }}</div>
        <div>{{ location }}</div>
        <div>{{ currentRoute()?.name }}</div>
        <div>{{ errorDetails?.shortMessage }}</div>
        <div>
          {{
            $t("globalError.correlationId", { id: errorDetails?.correlationId })
          }}
        </div>
      </div>
      <div class="info">
        <button
          :aria-expanded="showDetails"
          @click="showDetails = !showDetails"
        >
          {{ $t("globalError.technicalDetails") }}
        </button>
      </div>
      <textarea
        v-if="showDetails"
        class="scrollable"
        :aria-label="$t('globalError.technicalDetails')"
        :value="message"
        readonly
      />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/modal";

.global-error {
  @include modal.background;

  .content {
    position: fixed;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: colors-old.$back-color;
    width: 500px;
    padding: 20px;

    .text {
      line-height: 1.5em;
    }

    .info {
      margin-top: 20px;
      font-size: 80%;
    }

    .details {
      margin-top: 2em;
      text-align: left;
      font-size: 65%;
    }

    .scrollable {
      position: relative;
    }

    textarea {
      margin-top: 20px;
      font-size: 80%;
      width: 400px;
      height: 200px;
      text-align: left;
    }

    button,
    a {
      text-decoration: underline;
      color: colors-old.$primary-color;
      cursor: pointer;
      outline: revert;
    }
  }
}
</style>
