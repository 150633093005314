<script setup lang="ts">
import { computed, ref, toRef } from "vue";

import { TextAlign, useTextElement } from "./useTextElement";

// an SVG line with optional text inside (splitting the line into 2 parts)

const props = defineProps<{
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  text?: string;
  align?: TextAlign;
}>();

const x = computed(() => Math.min(props.x1, props.x2));
const y = computed(() => Math.min(props.y1, props.y2));
const width = computed(() => Math.abs(props.x2 - props.x1));
const height = computed(() => Math.abs(props.y2 - props.y1));
const text = toRef(props, "text");
const align = toRef(props, "align");

const textElem = ref<SVGTextElement>();

const { hasText, textAttrs, horizontal, firstEnd, secondStart } =
  useTextElement(textElem, { x, y, width, height, text, align });
</script>

<template>
  <text v-if="text" ref="textElem" v-bind="textAttrs"></text>
  <line v-if="!hasText" v-bind="{ ...$attrs, x1, y1, x2, y2 }" />
  <template v-else-if="horizontal">
    <line v-bind="{ ...$attrs, y1, y2 }" :x1="x" :x2="x + firstEnd" />
    <line
      v-bind="{ ...$attrs, y1, y2 }"
      :x1="x + secondStart"
      :x2="x + width"
    />
  </template>
  <template v-else>
    <line v-bind="{ ...$attrs, x1, x2 }" :y1="y" :y2="y + firstEnd" />
    <line
      v-bind="{ ...$attrs, x1, x2 }"
      :y1="y + secondStart"
      :y2="y + height"
    />
  </template>
</template>
