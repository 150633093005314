<script lang="ts" setup>
import { ref } from "vue";

import StickyNoteTextDisplay from "./components/StickyNoteTextDisplay.vue";
import StickyNoteTextWrapper from "./components/StickyNoteTextWrapper.vue";

defineProps<{
  notOnBoard?: boolean; // When rendered on a board, the card size is fixed (so we can optimize the font-size calculation)
}>();

const textDisplayRef = ref<typeof StickyNoteTextDisplay>();
</script>

<template>
  <StickyNoteTextWrapper
    :not-on-board="notOnBoard"
    :rendered-el="textDisplayRef?.element()"
    :edit="false"
  >
    <template #default="{ fontSize, lines }">
      <StickyNoteTextDisplay
        ref="textDisplayRef"
        :font-size="fontSize"
        :lines="lines"
      />
    </template>
  </StickyNoteTextWrapper>
</template>
