<script setup lang="ts">
import { computed } from "vue";

import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useConnectionStore } from "@/store/connection";

const hasWebsocketConnection = computed(() => useConnectionStore().isConnected);

function reloadPage() {
  location.reload();
}
</script>

<template>
  <div id="connection-status" role="alert" data-no-screenshot>
    <div v-if="!hasWebsocketConnection" class="no-net">
      <SvgIcon name="menu-top/disconnected" width="16" height="16" />
      <div class="message">
        {{ $t("connectionStatus.notWorking") }}
      </div>
      <div>
        <button @click="reloadPage">
          {{ $t("connectionStatus.refreshPage") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

#connection-status {
  z-index: z-index.$menu;
  position: fixed;
  top: variables.$top-header-height;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 12px;
  line-height: 18px;

  button {
    text-decoration: none;
    color: colors-old.$primary-color;
    cursor: pointer;
    margin-left: 50px;
    padding: 3px;
    font-size: 14px;
    font-weight: font.$weight-bold;
    line-height: 18px;
    outline: revert;
  }

  .no-net {
    background-color: colors-old.$error-back-color;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 12px;
    border-radius: variables.$common-border-radius;

    .message {
      font-size: 14px;
      font-weight: font.$weight-semi-bold;
      margin-left: 10px;
    }
  }
}
</style>
