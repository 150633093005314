import { nextTick } from "vue";

import { fakeZoom } from "@/model/Settings";
import { WindowCoordinate, windowCoord } from "@/model/coordinates";
import { ZoomedAppSize } from "@/model/size";
import { pushZoomState } from "@/router/navigation";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useZoomStore } from "@/store/zoom";

/**
 * zoom out fully and scroll to center so that padding is not visible.
 */
function resetZoom(size = useAppSizeStore().appSize) {
  applyZoom(1, {
    target: windowCoord(size.padding.left, size.padding.top),
    push: true,
  });
}

export function applyZoom(
  zoom: number,
  scrollTo?: { target: WindowCoordinate; smooth?: boolean; push?: boolean },
) {
  if (!useBoardStore().currentBoard().cardSize) {
    return;
  }
  useZoomStore().setZoomFactor(zoom);
  // wait for the zoom to update the DOM before calculating the coordinates
  void nextTick(() => {
    if (scrollTo) {
      window.scrollTo({
        left: scrollTo.target.x,
        top: scrollTo.target.y,
        behavior: scrollTo.smooth ? "smooth" : "auto",
      });
      if (scrollTo.push) {
        pushZoomState();
      }
    } else {
      window.scrollTo({
        left: (document.documentElement.scrollWidth - window.innerWidth) / 2,
        top: (document.documentElement.scrollHeight - window.innerHeight) / 2,
        behavior: "auto",
      });
    }
  });
}

export function applyFluidBoardSize(size: ZoomedAppSize, smooth = false) {
  if (size.zoom === 1) {
    resetZoom(size);
  }
  applyBoardSize(
    {
      scale: size.zoom / fakeZoom,
      top: size.top + "px",
      left: size.left + "px",
      height: size.height + "px",
      width: size.width + "px",
      paddingRight: size.padding.right + "px",
      paddingBottom: size.padding.bottom + "px",
    },
    smooth,
  );
}

export function applyFullBoardSize() {
  // Board needs to be downscaled, so we follow the pattern of fluid boards:
  // - first scale down x 0.1 here then scale back up x 10 in styling of individual full sized board
  // We need to keep this behavior to avoid bad-looking zooming in and out when switching
  // between boards during transition animation.
  applyBoardSize({
    scale: 1 / fakeZoom,
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    paddingRight: "0",
    paddingBottom: "0",
  });
}

function applyBoardSize(
  size: {
    scale: number;
    top: string;
    left: string;
    height: string;
    width: string;
    paddingRight: string;
    paddingBottom: string;
  },
  smooth = false,
) {
  const boardElem = document.getElementById("boards");
  const borderElem = document.getElementById("boards-border");
  if (boardElem && borderElem) {
    if (smooth) {
      boardElem.classList.add("smooth");
      setTimeout(() => boardElem.classList.remove("smooth"), 500);
    }
    const boardStyle = boardElem.style;
    boardStyle.transform = `scale(${size.scale})`;
    boardStyle.top = size.top;
    boardStyle.left = size.left;
    boardStyle.height = size.height;
    boardStyle.width = size.width;

    const borderStyle = borderElem.style;
    borderStyle.transform = `scale(${1 / size.scale})`;
    borderStyle.width = size.paddingRight;
    borderStyle.height = size.paddingBottom;
  }
}
