<script setup lang="ts"></script>

<template>
  <header class="home-header">
    <slot name="back" />
    <div v-if="$slots.title" class="title">
      <slot name="title" />
    </div>
    <div v-if="$slots.subtitle" class="subtitle">
      <slot name="subtitle" />
    </div>
  </header>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.home-header {
  position: relative;
  padding-bottom: 30px;

  .title {
    font-size: 24px;
    font-weight: font.$weight-black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: font.$weight-medium;
    color: colors-old.$text-secondary-color;
  }

  button.base-button {
    margin-left: -37px;
  }
}
</style>
