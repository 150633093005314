<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { navigationActions } from "@/action/navigationActions";
import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import { currentRoute } from "@/router/navigation";
import { useSwitchSessionStore } from "@/store/switchSession";
import { appLogoClicked } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isFeatureEnabled } from "@/utils/env/feature";

const handleClick = () => {
  trackEvent(appLogoClicked());
};

const showSwitchSessions = computed(() =>
  isFeatureEnabled(currentRoute(), "switch-session"),
);
const { active: isSwitchSessionOpen } = storeToRefs(useSwitchSessionStore());
</script>

<template>
  <div class="switch-session-item">
    <ActionButton
      v-if="showSwitchSessions"
      :action="toggleActions.showSwitchSession"
      :activated="isSwitchSessionOpen"
      icon="menu-top/logo"
      :aria-label="$t('label.menuTop.changeSession')"
      @click="handleClick"
    />
    <ActionButton
      v-else
      :action="navigationActions.goBack"
      icon="menu-top/logo"
      :aria-label="$t('label.menuTop.changeSession')"
      @click="handleClick"
    />
  </div>
</template>

<style lang="scss">
.switch-session-item {
  display: flex;

  svg {
    width: 25px !important;
    height: 25px !important;
    filter: grayscale(100%);
    transition: 0.3s ease-in-out filter;
  }

  .base-button {
    width: 40px;
    height: 40px;
  }

  &:hover {
    svg {
      filter: grayscale(0%);
    }
  }
}

.dark-mode {
  .switch-session-item:not(:hover) {
    svg path {
      fill: var(--text-secondary-color);
    }
  }
}
</style>
