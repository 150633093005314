import { useI18n } from "vue-i18n";

import { getAlmInfo } from "@/backend/Backend";
import { AuthUser, isUnknownUser } from "@/model/user";

export function useUser() {
  const { t } = useI18n();

  function userName(user: AuthUser | null, fallback: string): string;
  function userName(user: AuthUser): string;
  function userName(user: AuthUser | null, fallback?: string) {
    if (!user) {
      return fallback;
    }
    return isUnknownUser(user)
      ? t("boardUsers.unknown")
      : user.almMapping === "alm-only"
      ? t("assignee.unknownAlmUser", { almType: getAlmInfo().name })
      : user.name;
  }

  return { userName };
}
