<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
  <div class="add-item" :class="{ choose, disabled }" @click="toggle">
    <template v-if="choose">
      <IconButton
        icon="base/close"
        :aria-label="$t('general.close')"
        aria-expanded="true"
      />
      <div class="popout" role="menu">
        <BaseTooltip v-for="type in types" :key="type.id" position="top">
          <AddItemCard
            :type="type"
            :current="type === currentType"
            @select="select"
            @create="addCard"
          />
          <template #content>{{ type.name }}</template>
        </BaseTooltip>
      </div>
    </template>
    <ActionButton
      v-else
      :action="actions.togglePalette"
      :style="colors"
      style="height: 44px; width: 44px"
      aria-expanded="false"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import { cardActions } from "@/action/cardActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { isFrontendBoard } from "@/model/board";
import color from "@/model/color.module.scss";
import { StickyType } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useUserStore } from "@/store/user";
import { contrastCssColor, toCssColor } from "@/utils/color";

import AddItemCard from "./AddItemCard.vue";

@Component({
  components: { ActionButton, IconButton, AddItemCard, BaseTooltip },
})
export default class AddItem extends mixins(ShortcutUser, EventBusUser) {
  actions = cardActions;
  choose = false;
  readOnly = !useUserStore().isAllowed("edit");

  created() {
    this.globalActionShortcut(cardActions.add);
  }

  get board() {
    return useBoardStore().currentBoard();
  }

  get types(): StickyType[] {
    return useBoardStore().creatableStickyTypes;
  }

  get currentType() {
    return this.types.find(
      (t) => t.id === useClientSettingsStore().defaultStickyTypeId,
    );
  }

  get colors() {
    const current = this.currentType?.color;
    return current
      ? {
          backgroundColor: toCssColor(current),
          color: contrastCssColor(current),
        }
      : { backgroundColor: color.ok, color: color.back };
  }

  get disabled() {
    return isFrontendBoard(useBoardStore().currentBoard().type);
  }

  toggle() {
    if (!this.readOnly) {
      this.choose = !this.choose;
    }
  }

  select(type: StickyType) {
    useClientSettingsStore().setDefaultStickyType(
      this.currentType === type ? undefined : type,
    );
    this.choose = false;
  }

  addCard(type: StickyType) {
    cardActions.add("mainMenu", { type }, true, "board-actions-menu-click");
  }
}
</script>

<style lang="scss">
.add-item {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 8px;
  overflow: hidden;

  &.choose {
    padding: 0 2px;

    .popout {
      display: flex;
      gap: 10px;
      padding: 0 10px;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
