import { isString } from "lodash-es";

function get<T, Key extends keyof T>(a: T, prop?: Key): T | T[Key] {
  return prop ? a[prop] : a;
}

export function contains<T, Key extends keyof T>(
  as: T[],
  a: T | T[Key] | null | undefined,
  prop?: Key,
): boolean {
  return as.some((b) => normalize(get(b, prop)) === normalize(a));
}

// transform all falsy values except 0 to undefined, so they can be compared with ===
function normalize(value: unknown): unknown {
  return !value && value !== 0 ? undefined : value;
}

export function matches<T, Key extends keyof T>(
  as: T[],
  a: T | T[Key] | null | undefined,
  prop?: Key,
) {
  return as.length === 0 || contains(as, a, prop);
}

export function toggle<T, Key extends keyof T>(
  as: T[],
  a: T,
  single: boolean,
  prop?: Key,
) {
  const i = as.findIndex((b) => get(b, prop) === get(a, prop));
  if (i < 0) {
    if (single) {
      as.splice(0, as.length, a);
    } else {
      as.push(a);
    }
  } else {
    if (single) {
      as.splice(0, as.length);
    } else {
      as.splice(i, 1);
    }
  }
}

/**
 * Joins input array by comma
 * @param as - input array
 * @param prop - key to project input array
 * @returns Comma separated input array values
 */
export function join<T, Key extends keyof T>(as: T[], prop?: Key): string {
  return as.map((a) => get(a, prop)).join();
}

/**
 * Filter input array by comma separated values
 * @template T - Element type
 * @param as - List of elements
 * @param str - String to parse from
 * @param prop - Key of parsed property
 * @returns Objects from the input array filtered by the given key
 */
export function fromString<T, Key extends keyof T>(
  as: readonly T[],
  str: string | undefined,
  prop?: Key,
): T[] {
  const res = [];
  if (str) {
    const ns = str.split(",");
    for (const n of ns) {
      const a = as.find((b) => {
        const value = get(b, prop);
        if (!isString(value)) {
          return false;
        }
        // eslint-disable-next-line eqeqeq
        return value == n;
      });
      if (a) {
        res.push(a);
      }
    }
  }
  return res;
}

export function equals<T>(as: T[], bs: T[]) {
  return as.length === bs.length && as.every((a) => bs.some((b) => a === b));
}

export function nextIndex(
  list: unknown[],
  startIndex: number,
  offset: number,
): number {
  const len = list.length;
  if (startIndex === -1 && offset < 0) {
    startIndex = len;
  }
  return (startIndex + offset + len) % len;
}

export function nextElement<T>(
  list: T[],
  isStartElement: (item: T) => boolean,
  offset: number,
): T {
  const startIndex = list.findIndex(isStartElement);
  return list[nextIndex(list, startIndex, offset)];
}
