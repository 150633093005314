<template>
  <div class="time">
    <SuffixInput
      id="duration"
      :value="duration.hourStr"
      allowed="0123456789"
      :max-length="2"
      :suffix="$t('timer.hour')"
      :aria-label="$t('label.timerPanel.durationHours')"
      @change="$emit('duration', duration.withHours(+$event))"
    />
    <SuffixInput
      :value="duration.minuteStr"
      allowed="0123456789"
      :max-length="2"
      :suffix="$t('timer.minute')"
      :aria-label="$t('label.timerPanel.durationMinutes')"
      @change="$emit('duration', duration.withMinutes(+$event))"
    />
    <SuffixInput
      :value="duration.secondStr"
      allowed="0123456789"
      :max-length="2"
      :suffix="$t('timer.second')"
      :aria-label="$t('label.timerPanel.durationSeconds')"
      @change="$emit('duration', duration.withSeconds(+$event))"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SuffixInput from "@/components/input/SuffixInput.vue";
import LabeledInput from "@/mixins/LabeledInput";

import Duration from "../Duration";

@Component({ components: { SuffixInput }, emits: ["duration"] })
export default class InputTime extends mixins(LabeledInput) {
  @Prop(Object) readonly duration!: Duration;

  mounted() {
    this.setForId("duration");
  }
}
</script>

<style lang="scss">
.time {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
</style>
