import { sender } from "@/backend/Sender";
import { BoardData } from "@/model/board";
import { FlexType } from "@/model/flexboard";
import { useBoardsStore } from "@/store/boards";

import { action, defineActions } from "./actions";

export const flexActions = defineActions("flex", {
  add: action((type: FlexType, name: string) => {
    return sender
      .addBoard(type.id, name)
      .then((id) => useBoardsStore().addFlexBoard(id, name, type));
  }),
  update: action((id: string, name: string) => {
    sender.updateBoard(id, name);
    (useBoardsStore().boardById(id) as BoardData<"flex">).name = name;
  }),
  delete: action((id: string) => {
    sender.deleteBoard(id);
    useBoardsStore().removeFlexBoard(id);
  }),
});
