<script setup lang="ts">
import { computed } from "vue";

import { TOP_MENU_OFFSET } from "@/components/menu/TopMenu/constants";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectStatusClass from "@/components/ui/SelectStatusClass/SelectStatusClass.vue";
import { StatusClass } from "@/model/baseTypes";
import { statusClassItems, useSearchMenuStore } from "@/store/searchMenu";

import StatusClassItemTooltip from "../tooltips/StatusClassItemTooltip.vue";
import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedStatusClassItems,
  set: (values: Array<{ id: StatusClass }>) =>
    (store.selectedStatusClasses = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu
    class="search-select"
    width="sm"
    stay-open-on-content-click
    @open="trackFilterOpen('status')"
  >
    <template #trigger="{ isOpen }">
      <IconButton
        icon="general/status"
        data-dropdown-trigger
        :activated="isOpen"
        :aria-label="
          $t('label.search.filterButton', {
            value: $t('general.statusClasses'),
          })
        "
        :aria-expanded="isOpen"
        aria-haspopup="listbox"
        :offset="TOP_MENU_OFFSET"
      >
        <template #tooltip>
          <StatusClassItemTooltip :status-class-items="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectStatusClass v-model="selection" :status-classes="statusClassItems" />
  </DropdownMenu>
</template>
