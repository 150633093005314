import { isArray, sortBy } from "lodash-es";
import { nextTick } from "vue";

import {
  sendBoardLoaded,
  sendStickyNoteAction,
} from "@/composables/useEventBus";
import { CancelError } from "@/error/CancelError";
import { addBreadcrumb } from "@/error/sentry";
import { windowToRelative } from "@/math/coordinate-systems";
import { distance2 } from "@/math/coordinates";
import { updateTimeout } from "@/model/Settings";
import { IdMap } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { centerCoord, relativeCoord } from "@/model/coordinates";
import { SessionAlmStatus } from "@/model/session";
import { Shape } from "@/model/shape";
import { TimerEvent } from "@/model/timer";
import { isBackendUserId } from "@/model/user";
import { loadUser } from "@/services/user.service";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { useConnectionStore } from "@/store/connection";
import { useLinkStore } from "@/store/link";
import { useObjectiveStore } from "@/store/objective";
import { usePointerStore } from "@/store/pointer";
import { useSessionStore } from "@/store/session";
import { useTimerStore } from "@/store/timer";
import { useUsersOnBoardStore } from "@/store/usersOnBoard";
import { i18n } from "@/translations/i18n";
import {
  disconnectObservers,
  reconnectObservers,
} from "@/utils/dom/mutationObserver";
import { performanceLog } from "@/utils/general";
import { nextTicks } from "@/utils/vue";

import { BackendSession, Subscription } from "./BackendSession";
import { EventInfo } from "./EventInfo";
import { addSticky, changeSticky, serverStickyPos } from "./changeSticky";
import {
  mapEvents,
  mapObjective,
  mapPartialObjective,
} from "./mapper/mapBackendData";
import {
  ServerEvent,
  ServerIterationSubsState,
  ServerLink,
  ServerObjective,
  ServerStickiesUpdate,
  ServerStickiesUpdateCacheHit,
  ServerStickiesUpdateCacheMiss,
  ServerSticky,
} from "./serverModel";

export function sessionSubscriptions(backendSess: BackendSession) {
  return [
    backendSess.sessionSubscribe<[ServerLink]>("link.add", (args) => {
      const [link] = args ?? [];
      sendAddLink(link);
    }),
    backendSess.sessionSubscribe<[string]>("link.remove", (args) => {
      const [id] = args ?? [];
      useLinkStore().remove(id);
    }),
    backendSess.almSubscribe<[SessionAlmStatus]>(
      "running_state.update",
      (args) => {
        const [status] = args ?? [];
        useConnectionStore().alm = status === "running";
        useSessionStore().setSessionAlmStatus(status);
      },
    ),
    !backendSess.getAlmType()
      ? null
      : backendSess.almSubscribe<[ServerIterationSubsState]>(
          "iteration_sync.update",
          (args) => {
            const [iterState] = args ?? [];
            setIterationStatus(iterState);
          },
        ),
    backendSess.sessionSubscribe<[ServerEvent[]]>(
      "timer.handle_event",
      (args) => {
        const [events] = args ?? [];
        void setTimers(events);
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.start_update",
      "session",
      handleUpdate((id, userId) =>
        useCardStore().readOnly({ readOnly: true, id, userId }),
      ),
    ),
    backendSess.boardSubscribe("", "sticky.update", "session", handleUpdate()),
    backendSess.boardSubscribe(
      "",
      "sticky.stop_update",
      "session",
      handleUpdate((id) => {
        useCardStore().readOnly({ readOnly: false, id });
        sendStickyNoteAction(id, { action: "changed" });
      }),
    ),
    backendSess.boardSubscribe<[ServerSticky]>(
      "",
      "sticky.add",
      "session",
      (args, _, event) => {
        const [data] = args ?? [];
        const board = getBoardFromEvent(event);
        if (!board) {
          return;
        }
        addSticky(board, { ...data, shouldAnimate: true });
        sendStickyNoteAction(data._id, { action: "changed" });
      },
    ),
    backendSess.boardSubscribe<[string]>(
      "",
      "sticky.remove",
      "session",
      (args, _, event) => {
        const [id] = args ?? [];
        useCardStore().delete({ id, boardId: event.boardId });
      },
    ),
    backendSess.boardSubscribe<[number]>(
      "",
      "sticky.scale",
      "session",
      (args, _, event) => {
        const [factor] = args ?? [];
        useBoardsStore().setCardSize({ boardId: event.boardId, factor });
      },
    ),
    backendSess.boardSubscribe<[string]>(
      "",
      "sticky.bring_to_front",
      "session",
      (args, _, event) => {
        const [id] = args ?? [];
        useBoardsStore().cardToFront({ id, boardId: event.boardId });
      },
    ),
    backendSess.boardSubscribe<[Shape]>(
      "",
      "shape.add",
      "session",
      ([shape], _, event) => useBoardsStore().addShape(event.boardId, shape),
    ),
    backendSess.boardSubscribe<[Shape]>(
      "",
      "shape.edit",
      "session",
      ([shape], _, event) => useBoardsStore().editShape(event.boardId, shape),
    ),
    backendSess.boardSubscribe<[string]>(
      "",
      "shape.remove",
      "session",
      ([id], _, event) => useBoardsStore().removeShape(event.boardId, id),
    ),
  ];
}

function handleUpdate(action?: (id: string, userId: string) => void) {
  return (args: [string, ServerSticky], kwargs: unknown, event: EventInfo) => {
    const [id, data] = args ?? [];
    const board = getBoardFromEvent(event);
    if (!board) {
      return;
    }
    action?.(id, event.userId);
    changeSticky(board, id, data);
  };
}

export function createBoardSubscriptions(
  backendSess: BackendSession,
  board: Board,
) {
  type Subscriptions = Array<Subscription | Promise<Subscription>>;

  return Promise.all([
    ...boardSubscriptions(),
    ...teamBoardSubscriptions(),
    ...objectivesBoardSubscriptions(),
  ]);

  function boardSubscriptions(): Subscriptions {
    return [
      backendSess.boardSubscribe<[{ x: number; y: number }]>(
        board.id,
        "pointer.position",
        "board",
        (args, _, event) => {
          const [coord] = args ?? [];
          usePointerStore().set({
            boardId: board.id,
            id: event.userId,
            pos: relativeCoord(coord.x, coord.y),
          });
        },
      ),
      backendSess.boardSubscribe<[string]>(
        board.id,
        "subscribers.join",
        "board",
        (args) => {
          const [userId] = args ?? [];
          // don't await!
          void handleUserJoined(userId);
        },
      ),
      backendSess.boardSubscribe<[string]>(
        board.id,
        "subscribers.leave",
        "board",
        (args) => {
          const [userId] = args ?? [];
          handleUserLeft(userId);
        },
      ),
    ];

    async function handleUserJoined(userId: string) {
      if (!isBackendUserId(userId)) {
        const user = useUsersOnBoardStore().findUserOnBoard(userId);
        if (user) {
          user.boardVisitTimestamp = Date.now();
        } else {
          const user = await loadUser({ id: userId });
          const boardVisitTimestamp = Date.now();
          useUsersOnBoardStore().addUserOnBoard({
            ...user,
            boardVisitTimestamp,
          });
        }
      }
    }

    function handleUserLeft(userId: string) {
      if (!isBackendUserId(userId)) {
        useUsersOnBoardStore().removeUserFromBoard(userId);
      }
    }
  }

  function objectivesBoardSubscriptions(): Subscriptions {
    if (board.type !== "objectives") {
      return [];
    }
    return Object.values(useBoardsStore().boards)
      .filter(
        (board) =>
          useArtStore().isCurrentArt(board.artId) &&
          ["team", "program"].includes(board.type),
      )
      .flatMap((board) => objectivesSubscriptions(board));
  }

  function teamBoardSubscriptions(): Subscriptions {
    if (board.type !== "team") {
      return [];
    }
    return [
      ...objectivesSubscriptions(board),
      backendSess.boardSubscribe<[number, number]>(
        board.id,
        "capacity.update",
        "board",
        (args) => {
          const [iteration, velocity] = args ?? [];
          useBoardsStore().setVelocity({
            id: board.id,
            iteration,
            velocity: +velocity,
          });
        },
      ),
    ];
  }

  function objectivesSubscriptions(board: Board): Subscriptions {
    const objectives = useObjectiveStore();

    return [
      backendSess.boardSubscribe<[ServerObjective]>(
        board.id,
        "objective.add",
        "board",
        (args) => {
          const [objective] = args ?? [];
          objectives.add({ boardId: board.id, ...mapObjective(objective) });
        },
      ),
      backendSess.boardSubscribe<[string]>(
        board.id,
        "objective.remove",
        "board",
        (args) => {
          const [id] = args ?? [];
          objectives.remove({ boardId: board.id, id });
        },
      ),
      backendSess.boardSubscribe<[string, ServerObjective]>(
        board.id,
        "objective.update",
        "board",
        (args) => {
          const [id, objective] = args ?? [];
          objectives.update({
            boardId: board.id,
            ...mapPartialObjective(objective),
            id,
          });
        },
      ),
      backendSess.boardSubscribe<[string, boolean, number]>(
        board.id,
        "objective.move",
        "board",
        (args) => {
          const [id, stretch, rank] = args ?? [];
          objectives.move({ boardId: board.id, id, stretch, rank });
        },
      ),
    ];
  }
}

export function loadStickies(
  backendSess: BackendSession,
  board: Board,
): Promise<number> {
  let loadStart: number;
  type ServerStickyId = string;

  interface ParsedServerStickyUpdates {
    time: number;
    changes: ServerSticky[];
    removes?: ServerStickyId[];
  }

  const time = Date.now();
  const since = board.loaded;
  return backendSess
    .getBoardStickies(board.id, since)
    .then((stickiesOrUpdates) => {
      // user logged out / switched session while the stickies where loaded
      if (!useBoardsStore().boards[board.id]) {
        return Promise.reject(new CancelError("Sticky loading cancelled"));
      }
      const stickies = parseStickies(stickiesOrUpdates);
      const updated = updateStickies(stickies.changes);
      deleteStickies(updated, stickies.removes);
      return stickies.time;
    });

  function isCacheHitServerStickiesUpdate(
    serverStickies: ServerSticky[] | ServerStickiesUpdate,
  ): serverStickies is ServerStickiesUpdateCacheHit {
    return !isArray(serverStickies) && "updates" in serverStickies;
  }

  function isCacheMissServerStickiesUpdate(
    serverStickies: ServerSticky[] | ServerStickiesUpdate,
  ): serverStickies is ServerStickiesUpdateCacheMiss {
    return !isArray(serverStickies) && "stickies" in serverStickies;
  }

  function parseStickies(
    serverStickies: ServerSticky[] | ServerStickiesUpdate,
  ): ParsedServerStickyUpdates {
    if (isCacheMissServerStickiesUpdate(serverStickies)) {
      const { stickies } = serverStickies;
      const stickyIds = new Set(
        stickies.map((sticky: ServerSticky) => sticky._id),
      );
      const removes: ServerStickyId[] = Object.keys(board.cards).filter(
        (cardId) => !stickyIds.has(cardId),
      );

      return {
        time: serverStickies.timestamp,
        changes: stickies,
        removes,
      };
    }

    if (isCacheHitServerStickiesUpdate(serverStickies)) {
      const { updates } = serverStickies;
      const changes: ServerSticky[] = updates
        .filter((update) => update.event === "alter_sticky")
        .map((update) => update.obj);
      const removes: ServerStickyId[] = updates
        .filter((update) => update.event === "delete_sticky")
        .map((update) => update.obj._id);

      return {
        time: serverStickies.timestamp,
        changes,
        removes,
      };
    }

    return { time, changes: serverStickies };
  }

  function updateStickies(changes: ServerSticky[]): IdMap<boolean> {
    const ids: IdMap<boolean> = {};

    const center = windowToRelative(centerCoord());
    const sorted = sortBy(changes, (serverSticky) => {
      const pos = serverStickyPos(serverSticky);
      return pos ? distance2(pos, center) : 2;
    });
    const operations = sorted.map((serverSticky: ServerSticky) => {
      ids[serverSticky._id] = true;
      return useCardStore().cards[serverSticky._id]
        ? () => {
            useCardStore().readOnly({ id: serverSticky._id, readOnly: false });
            changeSticky(board, serverSticky._id, serverSticky);
          }
        : () => addSticky(board, serverSticky);
    });

    void addBreadcrumb("render", {
      message: "Updating stickies on the board",
      data: { count: operations.length, type: board.type, boardId: board.id },
    });
    disconnectObservers();
    loadStart = performance.now();
    setTimeout(() => processOperations(operations, 25, board.id), 0);
    return ids;
  }

  function processOperations(
    operations: Array<() => void>,
    batchSize: number,
    boardId?: Board["id"],
  ) {
    const start = performance.now();
    operations.slice(0, batchSize).forEach((operation) => operation());
    if (operations.length > batchSize) {
      setTimeout(
        () =>
          nextTick(() => {
            const end = performance.now();
            processOperations(
              operations.slice(batchSize),
              (batchSize * updateTimeout) / (end - start),
              board.id,
            );
          }),
        1,
      );
    } else {
      // 2 is found by trial: check that the last tick takes (nearly) no time
      nextTicks(2, () => {
        performanceLog("Load stickies", loadStart);
        sendBoardLoaded(boardId);
        reconnectObservers();
      });
    }
  }

  function deleteStickies(updateIds: IdMap<boolean>, deleteIds?: string[]) {
    if (deleteIds) {
      deleteIds.forEach((id) => {
        useCardStore().delete({ id, boardId: board.id });
      });
    } else {
      for (const c in board.cards) {
        if (!updateIds[c]) {
          useCardStore().delete({ id: c, boardId: board.id });
        }
      }
    }
  }
}

// links are updated by subscription of link.add / link.remove
// this is not always working (why?), so we explicitly update the links with this function
export async function updateLinks(backendSess: BackendSession) {
  type LinkStates = Record<string, "orphan" | "valid" | "existing">;

  const links = await backendSess.getLinks();
  await loadStickiesForHalfCompleteLinks({ links, backendSess });
  const ids = linkIds(links);
  deleteLinks(ids);
  createLinks(links, ids);

  function linkIds(ls: ServerLink[]) {
    const ids: LinkStates = {};
    ls.forEach((l) => {
      ids[l.id] =
        useLinkStore().cardsByLink(l.from_sticky_id).length > 0 &&
        useLinkStore().cardsByLink(l.to_sticky_id).length > 0
          ? "valid"
          : "orphan";
    });
    return ids;
  }

  function deleteLinks(ids: LinkStates) {
    for (const link of useLinkStore().links) {
      if (!ids[link.id]) {
        // useLinkStore().remove(link.id);
      } else if (ids[link.id] === "valid") {
        ids[link.id] = "existing";
      }
    }
  }

  function createLinks(ls: ServerLink[], ids: LinkStates) {
    ls.forEach((l) => {
      if (ids[l.id] === "valid") {
        sendAddLink(l);
      }
    });
  }
}

// stickies from other ARTs might not be loaded
// if we have links to them, load them now
async function loadStickiesForHalfCompleteLinks({
  links,
  backendSess,
}: {
  links: ServerLink[];
  backendSess: BackendSession;
}) {
  const notLoadedStickies = new Array<string>();
  links.forEach((link) => {
    const fromCards = useLinkStore().cardsByLink(link.from_sticky_id);
    const toCards = useLinkStore().cardsByLink(link.to_sticky_id);

    const hasFrom = fromCards.length > 0;
    const hasTo = toCards.length > 0;

    // If one of the stickies is not loaded, load it
    if (hasFrom !== hasTo) {
      // Only fetch stickies that are linked to a card on the current board
      const loadedCards = hasFrom ? fromCards : toCards;
      const onCurrentBoard = loadedCards.some(
        (card) => useBoardStore().board?.cards[card.id],
      );

      if (onCurrentBoard) {
        notLoadedStickies.push(
          hasFrom ? link.to_sticky_id : link.from_sticky_id,
        );
      }
    }
  });
  if (notLoadedStickies.length === 0) {
    return;
  }

  const stickies = await backendSess.getStickies(notLoadedStickies);
  stickies.forEach((sticky) => {
    const board = useBoardsStore().boards[sticky.board_id];
    addSticky(board, sticky);
  });
}

function setIterationStatus(iterState: ServerIterationSubsState) {
  for (const id in useBoardsStore().boards) {
    const board = useBoardsStore().boards[id];
    if (board.type === "team" && board.team.id === "" + iterState.team_id) {
      const status =
        iterState.status === "syncing"
          ? "syncing"
          : iterState.error
          ? "fail"
          : "success";
      useBoardsStore().setIterationStatus({
        boardId: id,
        id: iterState.pi_iteration_id,
        status,
        detail: iterState.error
          ? i18n.global.t("iterationSync.failedSync", {
              errorMessage: iterState.error,
            })
          : "",
      });
      break;
    }
  }
}

export async function setTimers(events: ServerEvent[]) {
  const timers = mapEvents(events);
  await loadTimerUsers(timers);
  useTimerStore().timers = timers;
}

function loadTimerUsers(timers: TimerEvent[]) {
  return Promise.all(
    timers.map(async (timer) => {
      timer.updatedByUser = await loadUser({ id: timer.updatedById });
    }),
  );
}

function sendAddLink(link: ServerLink) {
  useLinkStore().add({
    id: link.id,
    from: link.from_sticky_id,
    to: link.to_sticky_id,
    type: link.link_type_id,
    state: "default",
  });
}

function getBoardFromEvent(event: EventInfo) {
  return useBoardsStore().boards[event.boardId];
}
