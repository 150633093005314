<script lang="ts" setup>
import { FunctionalComponent, computed } from "vue";

import { getAlmInfo } from "@/backend/Backend";
import IconAc from "@/icons/alm/ac.svg?component";
import IconAdo from "@/icons/alm/ado.svg?component";
import IconJira from "@/icons/alm/jira.svg?component";

const icons: Record<string, FunctionalComponent> = {
  "alm/jira": IconJira,
  "alm/ado": IconAdo,
  "alm/ac": IconAc,
};

const icon = computed(() => getAlmInfo().icon);
</script>

<template>
  <component :is="icons[getAlmInfo().icon]" v-if="icon !== 'base/minus'" />
</template>
