<script setup lang="ts"></script>

<template>
  <div>
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.left {
  position: absolute;
  inset: 8em 66.66% 1em 1em;
  border-right: 3px solid colors-old.$menu-color;
}

.right {
  position: absolute;
  inset: 8em 1em 1em 33.33%;

  & > :deep(div) {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
