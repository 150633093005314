<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import ROAMSticky from "@/components/card/components/ROAMSticky/ROAMSticky.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { riskTitle, riskTypeName } from "@/model/baseTypes";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const { t } = useI18n();

const cardRisk = computed(() => {
  return card.risk
    ? t(riskTypeName(card.risk))
    : t("risk.roam.ariaLabel.notSet");
});

const triggerAriaLabel = computed(() => {
  return t("risk.roam.ariaLabel", {
    riskTypeName: cardRisk.value,
  });
});
</script>

<template>
  <AttributeChip
    name="roam-chip"
    :text="card.risk"
    :text-placeholder="$t('risk.roam')"
    :dropdown-props="{ width: 'auto' }"
    :tooltip="card.risk ? $t(riskTitle(card.risk)) : $t('risk.roam')"
    :readonly="cardMeta.isReadonly"
    :trigger-aria-label="triggerAriaLabel"
  >
    <ROAMSticky />
  </AttributeChip>
</template>
