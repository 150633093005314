import { clamp } from "lodash-es";

import {
  AnyCoordinate,
  Line,
  Rectangle,
  RelativeCoordinate,
  relativeCoord,
} from "@/model/coordinates";

export function rectangle<T extends AnyCoordinate>(
  p0: T,
  size: T,
): Rectangle<T> {
  return { p0, p1: plus(p0, size) };
}

export function rectangleAt<T extends AnyCoordinate>(center: T, size: T) {
  return rectangle(minus(center, times(size, 0.5)), size);
}

export function add<T extends AnyCoordinate>(a: T, b: T) {
  a.x += b.x;
  a.y += b.y;
}

export function plus<T extends AnyCoordinate>(a: T, b: T): T {
  return { x: a.x + b.x, y: a.y + b.y } as T;
}

export function minus<T extends AnyCoordinate>(a: T, b?: T): T {
  return (b ? { x: a.x - b.x, y: a.y - b.y } : { x: -a.x, y: -a.y }) as T;
}

export function times<T extends AnyCoordinate>(
  a: T,
  factor1: number,
  factor2?: number,
): T {
  return { x: a.x * factor1, y: a.y * (factor2 ?? factor1) } as T;
}

export function divided<T extends AnyCoordinate>(
  a: T,
  factor1: number,
  factor2?: number,
): T {
  return { x: a.x / factor1, y: a.y / (factor2 ?? factor1) } as T;
}

export function max<T extends AnyCoordinate>(...as: T[]) {
  return as.reduce(
    (res, a) => ({ x: Math.max(res.x, a.x), y: Math.max(res.y, a.y) }) as T,
    { x: -Infinity, y: -Infinity } as T,
  );
}

export function min<T extends AnyCoordinate>(...as: T[]) {
  return as.reduce(
    (res, a) => ({ x: Math.min(res.x, a.x), y: Math.min(res.y, a.y) }) as T,
    { x: Infinity, y: Infinity } as T,
  );
}

export function toSize<T extends AnyCoordinate>(a: T) {
  return { width: a.x, height: a.y };
}

const ETA = 0.000001;

export function isEqual(a: RelativeCoordinate, b: RelativeCoordinate) {
  return isNear(a.x, b.x) && isNear(a.y, b.y);
}

function isNear(a: number, b: number) {
  return Math.abs(a - b) < ETA;
}

export function distance2<T extends AnyCoordinate>(a: T, b: T): number {
  return (a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y);
}

export function interpolate<T extends AnyCoordinate>(v: number, a: T, b: T): T {
  return { x: a.x - v * (a.x - b.x), y: a.y - v * (a.y - b.y) } as T;
}

export function insideRectangle<T extends AnyCoordinate>(
  c: T,
  r: Rectangle<T>,
) {
  return c.x >= r.p0.x && c.x <= r.p1.x && c.y >= r.p0.y && c.y <= r.p1.y;
}

export function clampRelativeCoord(c: RelativeCoordinate): RelativeCoordinate {
  return relativeCoord(clamp(c.x, 0, 1), clamp(c.y, 0, 1));
}

/**
 * Return the point on the line that is nearest the given point.
 * @return {
 * t: the fractional position along the line 0..1 (0: p0, 1: p1, 0.5: middle of the line),
 * point: the point itself
 * }
 */
export function nearestLinePoint<T extends AnyCoordinate>(
  point: T,
  line: Line<T>,
) {
  const len = distance2(line.p0, line.p1);
  const t =
    ((point.x - line.p0.x) * (line.p1.x - line.p0.x) +
      (point.y - line.p0.y) * (line.p1.y - line.p0.y)) /
    len;
  return { t, point: interpolate(clamp(t, 0, 1), line.p0, line.p1) };
}

export function isHorizontal<T extends AnyCoordinate>(line: Line<T>) {
  return Math.abs(line.p0.y - line.p1.y) < ETA;
}
