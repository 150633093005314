<script setup lang="ts">
import { computed, ref } from "vue";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/ui/DropdownMenu/DropdownTrigger.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { Session } from "@/model/session";
import { useSessionStore } from "@/store/session";

const emit = defineEmits<{ close: []; duplicate: [session: Session] }>();

const sessions = computed(() => useSessionStore().session.list);
const selected = ref(useSessionStore().session.current);
</script>

<template>
  <div v-autofocus class="duplicate-flex-board-elem">
    {{ $t("flexBoardElem.duplicateToSession") }}
    <br />
    <DropdownMenu class="duplicate-flex-board-elem-menu">
      <template #trigger="{ isOpen }">
        <DropdownTrigger
          :value="selected.name"
          :aria-expanded="isOpen"
          aria-controls="duplicate-dropdown"
          aria-haspopup="listbox"
        />
      </template>
      <div
        id="duplicate-dropdown"
        v-keyboard-nav
        class="dropdown-list"
        role="listbox"
      >
        <ListItem
          v-for="session in sessions"
          :key="session.id"
          role="option"
          :aria-selected="selected === session"
          @click="selected = session"
        >
          {{ session.name }}
        </ListItem>
      </div>
    </DropdownMenu>
    <div class="bottom">
      <button class="action line line-right item" @click.stop="emit('close')">
        {{ $t("general.cancel") }}
      </button>
      <button class="action item" @click.stop="emit('duplicate', selected)">
        <SvgIcon name="action/duplicate" width="20" height="20" />
        {{ $t("flexBoardElem.duplicate") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables/colors" as colors;

.duplicate-flex-board-elem {
  .dropdown-trigger {
    margin-top: 1.5em;

    &,
    button {
      color: colors.$text-inverse !important;

      &:hover {
        background-color: colors.$background-grey-bold !important;
      }
    }
  }
}

.duplicate-flex-board-elem-menu {
  max-width: 100%;

  .dropdown-list {
    max-height: 250px;
    overflow-y: auto;
  }
}
</style>
