<template>
  <div id="search-results">
    <div class="scrollable no-wheel">
      <div class="result-board">
        <div
          v-if="showNoResult"
          class="no-results"
          data-search-results-anchor
          tabindex="-1"
          aria-live="polite"
        >
          {{ $t("searchResults.noResults") }}
        </div>
        <CurrentBoardSearchResults
          v-if="getCurrentBoardSearchResults"
          class="focusable-search-results"
          tabindex="-1"
          data-search-results-anchor
          :results="getCurrentBoardSearchResults"
          :selected="selected"
          @select="select"
        />
        <OtherBoardsSearchResults
          class="focusable-search-results"
          data-search-results-anchor
          tabindex="-1"
          :query="searchQuery"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

import { SearchStickiesQuery } from "@/backend/BackendSession";
import { SearchResult } from "@/model/search";
import { useBoardStore } from "@/store/board";

import CurrentBoardSearchResults from "./CurrentBoardSearchResults.vue";
import OtherBoardsSearchResults from "./OtherBoardsSearchResults.vue";
import SearchItemBase from "./SearchItemBase";
import {
  GroupedSearchResults,
  StickyInfoKey,
  groupSearchResults,
} from "./SearchResult";

@Component({
  components: {
    CurrentBoardSearchResults,
    OtherBoardsSearchResults,
  },
  emits: ["select"],
})
export default class SearchResults extends mixins(SearchItemBase) {
  @Prop(Array) readonly results!: SearchResult[];
  @Prop(Object) readonly selected: StickyInfoKey | undefined;

  currentItem: StickyInfoKey | null = null;

  @Watch("selected")
  currentChanged(selected: StickyInfoKey | undefined) {
    if (selected) {
      this.currentItem = selected;
      document
        .getElementById(`result-${selected.id}`)
        ?.scrollIntoView({ block: "nearest" });
    }
  }

  get searchQuery(): SearchStickiesQuery {
    return {
      text: this.text,
      teams: this.teams,
      iterations: this.iterations,
      types: this.types,
      flags: this.flags,
      statusClasses: Array.from(this.selectedStatusClasses),
    };
  }

  get getCurrentBoardSearchResults(): GroupedSearchResults | null {
    const currentBoardItems = this.results.filter(
      (item) => item.boardId === useBoardStore().board?.id,
    );
    if (currentBoardItems.length === 0) {
      return null;
    }
    return groupSearchResults(currentBoardItems)[0];
  }

  get showNoResult(): boolean {
    return this.results.length === 0;
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  select() {}
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/mixins/shadow";
@use "@/styles/side-panel" as *;

#search-results {
  @include side-panel;

  left: 0;

  @include shadow.default;

  .high-contrast-mode & {
    border-right: 2px solid colors-a11y.$divider-color;
  }

  .no-results {
    margin-top: 16px;
    font-size: font.$size-small;
    background-color: colors-old.$light-background-color;
    padding: 16px;
    text-align: center;

    &:focus-visible {
      outline: 3px solid colors.$outline;
    }
  }

  .focusable-search-results {
    &:focus-visible {
      outline: 3px solid colors.$outline;
    }

    // Only highlight the current result when focus is on the list
    // (to avoid both lists having a highlighted item at the same time)
    &:not(:focus-within) :deep(.result-card.current) {
      background-color: transparent;
    }
  }
}
</style>
