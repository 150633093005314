<script setup lang="ts">
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";

const emit = defineEmits<{ close: []; delete: [] }>();
</script>

<template>
  <div v-autofocus>
    {{ $t("flexBoardElem.confirm") }}
    <div class="bottom">
      <button class="action line line-right item" @click.stop="emit('close')">
        {{ $t("general.cancel") }}
      </button>
      <button class="action item" @click.stop="emit('delete')">
        <SvgIcon name="action/delete" width="20" height="20" />
        {{ $t("flexBoardElem.delete") }}
      </button>
    </div>
  </div>
</template>
