import { IdMap } from "@/model/baseTypes";

import { Store } from "./Store";

export class SimpleCache<T> {
  store?: Store<IdMap<[number, T]>>;
  timeout: number;
  data: IdMap<[number, T]> = {};
  loading: IdMap<Promise<T>> = {};

  constructor(options: { store?: Store<any>; timeout: number }) {
    this.store = options.store; //TODO check data on load
    this.timeout = options.timeout;
    this.data = this.load();
  }

  async loadItem(
    id: string,
    fetch: () => Promise<T>,
    options: { lookup?: boolean } = {},
  ) {
    if (options.lookup !== false) {
      const cached = this.data[id];
      if (cached && Date.now() - cached[0] < this.timeout) {
        return cached[1];
      }
    }
    if (id in this.loading) {
      return this.loading[id];
    }
    try {
      return await (this.loading[id] = fetch()).then((item) =>
        this.add(id, item),
      );
    } finally {
      delete this.loading[id];
    }
  }

  clear() {
    this.data = {};
    this.save();
  }

  add(id: string, item: T) {
    this.data[id] = [Date.now(), item];
    this.save();
    return item;
  }

  load() {
    return this.store?.load() ?? {};
  }

  save() {
    this.store?.save(this.data);
  }
}
