<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { navigationActions } from "@/action/navigationActions";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { Team } from "@/model/session";
import { navigateBack, navigateForward } from "@/router/navigation";
import { router } from "@/router/router";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import {
  teamBackButtonClicked,
  teamChangeModalSeen,
  teamChanged,
  teamSelected,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { neverResolve } from "@/utils/general";

import ChooseButton from "../components/ChooseButton.vue";
import HomeHeader from "../components/HomeHeader.vue";

const props = defineProps<{ asModal?: boolean }>();

const loading = ref(false);

const teams = computed(() => useTeamStore().teamsInCurrentArt);
const selectedSessionName = computed(
  () => useSessionStore().session.selected?.name,
);
const currentArt = computed(() => useArtStore().currentArt);

function isUserTeam(team: Team) {
  return useUserStore().technicalUser.teams.some(
    (userTeam) => userTeam.id === team.id,
  );
}

onMounted(() => {
  if (props.asModal) {
    trackEvent(teamChangeModalSeen());
  }
});

function back() {
  if (!props.asModal) {
    trackEvent(teamBackButtonClicked());
  }
  void navigateBack();
}

/**
 * Navigate back to the previous page (to make this page
 * act more like a menu, since it feels like one coming from the app)
 */
function navBack() {
  router.back();
}

function select(team: Team) {
  if (loading.value) {
    // this is to avoid loading multiple sessions in parallel which can cause troubles
    // would be nice to solve the problem with a lock around session loading
    // but when loading is in progress and then another request comes into the router, the original request is always canceled
    return;
  }
  if (!props.asModal) {
    loading.value = true;
  }

  if (props.asModal) {
    const currentBoard = useBoardStore().currentBoard();
    trackEvent(teamChanged(currentBoard.type, "sidebar-menu"));
    navigationActions.changeTeam("modal", team);
    useModalStore().hide();
  } else {
    trackEvent(teamSelected());
    navigationActions.selectTeam("mainMenu", team);
    void navigateForward();
  }
  return neverResolve();
}
</script>

<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <main
    v-autofocus
    class="home-overview team-page"
    tabindex="-1"
    aria-labelledby="team-page-subtitle"
    @keydown.esc="navBack"
  >
    <!-- eslint-enable vuejs-accessibility/no-static-element-interactions -->
    <HomeHeader v-if="!asModal">
      <template #back>
        <BaseButton
          variant="ghost"
          color="grey"
          icon-before="chevron/left"
          @click="back"
        >
          {{
            currentArt.name
              ? $t("teamPage.backToARTs")
              : $t("page.backToSessions")
          }}
        </BaseButton>
      </template>
      <template #title>
        <span v-if="currentArt.name" id="team-page-title">
          <h1>{{ currentArt.name }}</h1>
        </span>
        <span v-else id="team-page-title">
          <h1>{{ selectedSessionName }}</h1>
        </span>
      </template>
      <template #subtitle>
        <div v-if="currentArt.name" class="art">{{ selectedSessionName }}</div>
        <div id="team-page-subtitle">{{ $t("teamPage.selectTeam") }}</div>
      </template>
    </HomeHeader>

    <hr v-if="!asModal" class="separator" aria-hidden="true" />
    <div
      :class="{ scrollable: !asModal }"
      class="scrollable-relative scrollable-content"
    >
      <ChooseButton
        v-for="team in teams"
        :key="team.id"
        size="medium"
        :class="{ mark: isUserTeam(team) }"
        @click="select(team)"
      >
        <div class="two-line-ellipsis-text">
          {{ team.name }}
        </div>
      </ChooseButton>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/team-art-list" as *;

.team-page {
  .art {
    font-weight: font.$weight-normal;
    color: colors-old.$text-secondary-color;
    margin-top: 5px;
  }
}
</style>
