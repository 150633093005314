<template>
  <div class="label-input">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label :for="forId">{{ label }}</label>
    <slot></slot>
    <div class="error-message" aria-live="polite">{{ error }}</div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LabelInput extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly error: string | undefined;

  forId = "";

  setForId(forId: string) {
    this.forId = forId;
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.label-input {
  label {
    display: block;
    font-size: 90%;
    font-weight: font.$weight-bold;
    margin-bottom: 0.4em;
  }

  .error-message {
    height: 1em;
    margin-top: 0.4em;
    font-size: 90%;
    color: colors-old.$error-color;
  }
}
</style>
