import { noop } from "lodash-es";
import { defineStore } from "pinia";

export const useSidePanelStore = defineStore("sidePanel", {
  state: () => {
    return {
      left: { width: 0, close: noop },
      right: { width: 0, close: noop },
    };
  },
  getters: {
    panelLeftOpen: (state) => state.left.width > 0,
    panelRightOpen: (state) => state.right.width > 0,
  },
  actions: {
    /**
     * @param close function to be called before another panels opens
     * @returns if the panel width could be set
     */
    setWidth(side: "left" | "right", width: number, close: () => void) {
      const target = this[side];
      // different close function = a different panel is opened -> call close() on the old panel
      if (target.close !== close) {
        if (width === 0) {
          return false;
        }
        target.close();
      }
      target.close = width === 0 ? noop : close;
      target.width = width;
      return true;
    },
  },
});
