<script setup lang="ts">
import { computed } from "vue";

/**
 * A simple empty (square) div that will render to the given size
 */

const props = defineProps<{ height?: string; width?: string }>();

// Style the component - only set the height if it's given as a number
const style = computed(() =>
  props.height?.match(/[^0-9]/) || props.width?.match(/[^0-9]/)
    ? {}
    : {
        height: `${props.height}px`,
        width: `${props.width}px`,
      },
);
</script>

<template>
  <div class="svg-loading-placeholder" :style="style"></div>
</template>

<style scoped>
.svg-loading-placeholder {
  display: inline-block;
  aspect-ratio: 1 / 1;
}
</style>
