import { onBeforeUnmount } from "vue";

interface Listener {
  elem: Document | HTMLElement;
  type: string;
  listener: (this: any, ev: any) => unknown;
  options?: boolean | AddEventListenerOptions;
}

export function useNativeEvents() {
  const listeners = new Array<Listener>();

  onBeforeUnmount(() => {
    removeEventListeners();
  });

  function addEventListener<K extends keyof DocumentEventMap>(
    elem: Document,
    type: K,
    listener: (this: HTMLElement, ev: DocumentEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  function addEventListener<K extends keyof HTMLElementEventMap>(
    elem: HTMLElement | undefined,
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  function addEventListener(
    elem: Document | HTMLElement | undefined,
    type: string,
    listener: (this: Document | HTMLElement, ev: any) => unknown,
    options?: boolean | AddEventListenerOptions,
  ) {
    if (elem) {
      listeners.push({ elem, type, listener, options });
      elem.addEventListener(type, listener, options);
    }
  }

  function removeEventListeners() {
    for (const listener of listeners) {
      listener.elem.removeEventListener(
        listener.type,
        listener.listener,
        listener.options,
      );
    }
    listeners.length = 0;
  }

  return { addEventListener, removeEventListeners };
}
