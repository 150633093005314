import { onUnmounted } from "vue";

type DelayedAction<T extends unknown[]> = (...args: T) => void;

export function useDelayedAction<T extends unknown[]>(
  action: DelayedAction<T>,
  delay: number,
): [DelayedAction<T>, () => void] {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const clear = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  // Cleanup on unmount
  onUnmounted(clear);

  // Start the delayed action
  const execute: DelayedAction<T> = (...args: T) => {
    clear(); // Clear any existing timeouts
    timeoutId = setTimeout(() => {
      action(...args);
      clear(); // Clear the timeout after the action is executed
    }, delay);
  };

  // Cancel the delayed action
  const cancel = () => {
    clear();
  };

  return [execute, cancel];
}
