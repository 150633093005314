<script lang="ts" setup>
import { ref } from "vue";

defineProps<{ fontSize: number; lines: string[] }>();

defineExpose({ element: () => textDivRef.value });

const textDivRef = ref<HTMLTextAreaElement>();
</script>

<template>
  <div
    ref="textDivRef"
    class="sticky-note-text-display"
    :style="{ fontSize: fontSize + '%' }"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-for="(line, index) in lines" :key="index" v-html="line" />
  </div>
</template>

<style lang="scss" scoped>
.sticky-note-text-display {
  overflow: hidden;
  white-space: pre;
  width: 100%;
  height: 100%;

  &:deep(a) {
    text-decoration: underline;
  }
}
</style>
