import { InfoLevel } from "@/model/baseTypes";
import { useServerSettingsStore } from "@/store/serverSettings";

/**
 * Returns the state of the load based on the load and capacity.
 */
export function loadState(load: number, capacity: number): InfoLevel {
  if (!load || !capacity) {
    return "default";
  }
  const ratio = (100 * load) / capacity;

  const error = useServerSettingsStore().iterationLoadCritical ?? 100;
  if (ratio > error) {
    return "error";
  }

  const warn = useServerSettingsStore().iterationLoadWarn;
  if (warn && ratio > warn) {
    return "warn";
  }

  return "ok";
}
