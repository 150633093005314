<template>
  <div class="work-mode">
    <ActionButton
      :action="actions.showPlanningMode"
      :activated="store.isPlanningMode"
      :aria-current="store.isPlanningMode ? 'true' : 'false'"
    />
    <ActionButton
      :action="actions.showExecutionMode"
      :activated="store.isExecutionMode"
      :aria-current="store.isExecutionMode ? 'true' : 'false'"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import ShortcutUser from "@/mixins/ShortcutUser";
import { useWorkModeStore } from "@/store/workMode";

@Component({ components: { ActionButton } })
export default class WorkModeItem extends mixins(ShortcutUser) {
  store = useWorkModeStore();
  actions = toggleActions;

  created() {
    this.globalActionShortcut(toggleActions.toggleWorkingMode);
  }
}
</script>
<style lang="scss" scoped>
@use "@/styles/variables";

.work-mode {
  display: flex;
  border: 1px solid var(--divider-color);
  border-radius: variables.$common-border-radius;
  margin-left: 8px;
}
</style>
