// iconLoader.ts
/// <reference types="vite-svg-loader" />
import { ComponentPublicInstance, defineAsyncComponent } from "vue";

import { captureException } from "@/error/sentry";
import PlaceHolderIcon from "@/icons/base/placeholder.svg?component";
import { Icon } from "@/model/icon";

import SvgLoadingPlaceholder from "./SvgLoadingPlaceholder.vue";

const modules = import.meta.glob("/src/icons/**/*.svg", {
  query: "?component",
});

export const getIconComponent = (name: Icon) => {
  return defineAsyncComponent({
    loader: async () => {
      const path = `/src/icons/${name}.svg`;
      try {
        return (await modules[path]()) as ComponentPublicInstance;
      } catch (err) {
        void captureException(err);
        return PlaceHolderIcon;
      }
    },
    // Render an empty div while the icon is loading so there's
    // no layout shift when the icon is finally loaded
    loadingComponent: SvgLoadingPlaceholder,
    delay: 0,
  });
};
