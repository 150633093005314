<script setup lang="ts">
import { computed } from "vue";

import { useCardStore } from "@/store/card";

import CardRow from "./CardRow.vue";

const props = defineProps<{
  linkedCardIds: string[];
  showStatus?: boolean;
}>();

const linkedCards = computed(() =>
  props.linkedCardIds.map((cardId) => useCardStore().cards[cardId]),
);
</script>

<template>
  <div class="">
    <slot />
    <div class="left-indent" @click.stop>
      <div>
        <CardRow
          v-for="linkedCard in linkedCards"
          :key="linkedCard.id"
          :card="linkedCard"
          show-team
          :show-status="showStatus"
          :tabindex="undefined"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.left-indent {
  margin-left: 42px;
}
</style>
