import { computed, ref, watch } from "vue";

import {
  divided,
  max,
  min,
  minus,
  plus,
  times,
  toSize,
} from "@/math/coordinates";
import { IdMap } from "@/model/baseTypes";
import { BoardCard } from "@/model/card";
import { RelativeCoordinate, relativeCoord } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { Drag, useDraggingStore } from "@/store/dragging";

const PADDING = relativeCoord(1, 1);

export function useSelectionShape(width: number, height: number) {
  const pos = ref<RelativeCoordinate[]>([]);
  watch(
    selectionAndDrag,
    ([drag, selection]) => {
      pos.value = dragPos(drag) ?? selectionPos(selection) ?? [];
    },
    { immediate: true, deep: true },
  );

  function selectionAndDrag(): [IdMap<Drag>, BoardCard[]] {
    return [useDraggingStore().dragging, useBoardStore().selectedStickies];
  }

  function dragPos(drag: IdMap<Drag>) {
    const drags = Object.values(drag);
    if (drags.length > 1 && drags[0].type === "card") {
      return drags.map((d) => d.pos);
    }
  }

  function selectionPos(selection: BoardCard[]) {
    if (selection.length > 1) {
      return selection.map((card) => ({ ...card.meta.pos }));
    }
  }

  const selectionRect = computed(() => {
    if (pos.value.length === 0) {
      return;
    }
    const cardSize = divided(useBoardStore().currentBoard().cardSize, 2);
    const start = minus(min(...pos.value), cardSize);
    const end = plus(max(...pos.value), cardSize);
    const scaledStart = times(start, width, height);
    const scaledSize = times(minus(end, start), width, height);
    return {
      ...minus(scaledStart, PADDING),
      ...toSize(plus(scaledSize, times(PADDING, 2))),
    };
  });

  return { selectionRect };
}
