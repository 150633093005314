<script setup lang="ts">
import { computed } from "vue";

import SidePanel from "@/components/SidePanel/SidePanel.vue";
import SidePanelContent from "@/components/SidePanel/SidePanelContent.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { goToSession } from "@/router/navigation";
import { useSessionStore } from "@/store/session";
import { useSwitchSessionStore } from "@/store/switchSession";
import { sessionDateRange } from "@/utils/session";

import OrganizationTree from "./components/OrganizationTree.vue";

const store = useSwitchSessionStore();

const handleClose = () => {
  store.inactivate();
};

const sessionName = computed(() => useSessionStore().session.current.name);
const currentSessionDateRange = computed(() =>
  sessionDateRange(useSessionStore().session.current),
);

const goToSwitchSession = () => goToSession();
</script>

<template>
  <div class="switch-session-panel" data-ignore-click @scroll.stop>
    <SidePanel
      side="left"
      :active="store.active"
      name="switchSession"
      resizable
      @close="handleClose"
    >
      <SidePanelContent
        v-focus-trap
        v-autofocus
        :title="$t('switchSession.title')"
      >
        <template #sub-title>
          <div class="switch-session-header-container">
            <div class="session-name">{{ sessionName }}</div>
            <div class="session-date">
              {{ currentSessionDateRange }}
            </div>
            <BaseButton
              variant="ghost"
              color="primary"
              size="small"
              class="switch-session-button"
              :aria-label="$t('switchSession.title')"
              @click="goToSwitchSession"
            >
              {{ $t("switchSession.title") }}
            </BaseButton>
          </div>
        </template>
        <div class="organization-container">
          <div class="organization-title">
            {{ $t("switchSession.organization") }}
          </div>
          <OrganizationTree />
        </div>
      </SidePanelContent>
    </SidePanel>
  </div>
</template>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/typography";

.switch-session-panel {
  position: relative;
  z-index: z-index.$menu;

  .switch-session-header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    .session-name {
      @include typography.medium("bold");

      color: colors-old.$text-primary-color;
    }

    .session-date {
      @include typography.small("semi-bold");

      color: colors-old.$text-secondary-color;
    }

    .switch-session-button {
      padding: unset;
      align-self: flex-start;

      &.ghost.primary:hover {
        background-color: initial;
      }
    }
  }

  .organization-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    .organization-title {
      @include typography.small("extra-bold");

      color: colors-old.$text-secondary-color;
    }
  }
}
</style>
