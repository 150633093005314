import { Ref, computed, watch } from "vue";

import {
  ActionMenuConfig,
  useActionMenu,
} from "@/components/ActionMenu/useActionMenu";
import { Card } from "@/model/card";
import variables from "@/model/variable.module.scss";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { isElementInViewport } from "@/utils/dom/dom";

interface Props {
  referenceEl: Ref<HTMLElement | undefined>;
  card: Card;
  isPinned: Ref<Readonly<boolean>>;
  disabled: Ref<Readonly<boolean>>;
}

export function useStickyActionMenu({
  referenceEl,
  card,
  disabled,
  isPinned,
}: Props) {
  const open = computed(() => {
    return (
      useBoardStore().activeCardId === card.id &&
      !useDraggingStore().dragging[card.id] &&
      !useLinkStore().linking.from
    );
  });

  const size = parseInt(variables.stickySize.replace("px", ""));
  const config = computed<ActionMenuConfig>(() => {
    return {
      placement: "top",
      offset: referenceEl.value
        ? calculateOffset(referenceEl.value, isPinned.value, size)
        : [0, 0],
    };
  });

  const { isOpen, updateMenu, updateRef } = useActionMenu({
    referenceEl,
    open,
    disabled,
    config,
  });

  watch(isOpen, () => {
    // hide the action menu if the sticky note is not in the viewport
    if (isOpen.value && !isElementInViewport(referenceEl.value)) {
      useBoardStore().activeCardId = null;
      useBoardStore().unselectSticky(card.id);
    }
  });

  // update the action menu position when the card type, or priority changes
  watch([() => card.type, () => card.priority], updateMenu);

  return { isOpen, updateRef };
}

/**
 * Calculates the offset for the action menu of a sticky note.
 *
 * @param referenceEl - The reference element to which the action menu is positioned.
 * @param isPinned - A boolean indicating whether the sticky note is pinned.
 * @param size - The initial size of the sticky note, 240px
 * @returns A tuple containing the x and y offsets for the action menu.
 */
function calculateOffset(
  referenceEl: Element,
  isPinned: boolean,
  size: number,
): [number, number] {
  const multiplier = size / referenceEl.getBoundingClientRect().height;
  const offsetY = isPinned ? 11 : 5;

  return [0, offsetY / multiplier];
}
