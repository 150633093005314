import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import { DependencyTeamFilter } from "@/model/search";

import { boardActions } from "./boardActions";

export const markActions = {
  reset(card?: BoardCard) {
    const meta = card?.meta;
    if (meta) {
      meta.mark = "normal";
      meta.isHighlighted = false;
      meta.isRelatedToHighlighted = false;
      meta.dependencyTeamFilter = null;
    }
  },
  hide(card: BoardCard) {
    card.meta.mark = "hidden";
  },
  fadeOut(card: BoardCard, fadeOut: boolean) {
    card.meta.mark = fadeOut ? "fade-out" : "normal";
  },
  link(card: BoardCard, distance?: number) {
    if (distance === undefined || distance > 2) {
      this.linkFadeOut(card);
    } else if (distance > 0) {
      this.linkHighlight(card, distance === 1);
    } else {
      this.highlight(card);
    }
  },
  linkFadeOut(card: BoardCard) {
    card.meta.mark = "fade-out";
    card.meta.isHighlighted = false;
    card.meta.isRelatedToHighlighted = false;
  },
  linkHighlight(card: BoardCard, near: boolean) {
    card.meta.mark = near ? "normal" : "semi-fade-out";
    card.meta.isRelatedToHighlighted = true;
    boardActions.cardToFront("internal", card.data.id);
  },
  highlight(card: BoardCard) {
    card.meta.mark = "normal";
    card.meta.isHighlighted = true;
    boardActions.cardToFront("internal", card.data.id);
  },
  setSearch(
    card: BoardCard,
    match: boolean,
    hide: boolean,
    dependencyTeamFilter: DependencyTeamFilter | null,
  ) {
    card.meta.mark = match ? "normal" : hide ? "filter-out" : "fade-out";
    card.meta.dependencyTeamFilter = dependencyTeamFilter;
  },
  fadeOutAll(board: Board) {
    Object.values(board.cards).forEach((card) => this.fadeOut(card, true));
  },
  resetAll(board: Board) {
    Object.values(board.cards).forEach(this.reset);
  },
};
