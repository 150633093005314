<script setup lang="ts">
import {
  CategoryScale,
  type ChartData,
  Chart as ChartJS,
  type ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { computed } from "vue";
import { Line as LineChart } from "vue-chartjs";
import { useI18n } from "vue-i18n";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const props = defineProps<{
  labels: ChartData<"line">["labels"];
  datasets: ChartData<"line">["datasets"];
  showHiddenLabel?: boolean;
}>();

const { t } = useI18n();

const chartOptions: ChartOptions<"line"> = {
  responsive: true,
  scales: {
    y: {
      axis: "y",
      beginAtZero: true,
      title: {
        display: true,
        text: t("statusPage.responseTime"),
      },
      ticks: {
        // Append 'ms' to each value
        callback: (value) => `${value}ms`,
      },
    },
    // Separate axis to show the shading when the browser is inactive
    yArea: {
      axis: "y",
      type: "category",
      labels: [t("statusPage.true"), t("statusPage.false")],
      title: { display: false },
      grid: { display: false },
      ticks: { display: false },
      border: { display: false },
    },
    x: {
      axis: "x",
      title: {
        display: true,
        text: t("statusPage.reqTime"),
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: t("statusPage.pingTitle"),
    },
    // Only show the 'Inactive tab' in the legend when there is data to show
    legend: {
      labels: {
        filter: (item) =>
          item.text !== t("statusPage.hidden") || props.showHiddenLabel,
      },
    },
  },
};

const chartData = computed(() => ({
  labels: props.labels,
  datasets: props.datasets,
}));
</script>

<template>
  <div>
    <LineChart :data="chartData" :options="chartOptions" />
  </div>
</template>
