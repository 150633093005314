import {
  NavigationFailure,
  RouteLocationNamedRaw,
  createRouter,
  createWebHashHistory,
} from "vue-router";

import { captureException } from "@/error/sentry";
import { setRouter } from "@/router";
import Resolvable from "@/utils/async/Resolvable";
import { createSyncQueue } from "@/utils/async/syncQueue";

import { afterEach, beforeEach } from "./guards";
import { routes } from "./routes";
import { PiVueRouter } from "./types";

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
}) as PiVueRouter;

// set router.navigatingTo and navigating promise for the duration of a navigation action
let navigating = new Resolvable<void>();
navigating.resolve();

router.beforeEach((to, from, next) => {
  router.navigatingTo = to;
  // no new navigating promise while the old one is still pending
  // there might be syncPush/syncReplace waiting on the old promise, so it must be resolved
  if (!navigating.pending) {
    navigating = new Resolvable<void>();
  }
  return beforeEach(to, from, next);
});
router.afterEach((to, from) => {
  endNavigating();
  return afterEach(to, from);
});
router.onError((error, to, from) => {
  endNavigating();
  void captureException(error, {
    navigation: { from: from.fullPath, to: to.fullPath },
  });
});

function endNavigating() {
  router.navigatingTo = undefined;
  navigating.resolve();
}

// queue to synchronize programmatic changes to the URL
const queue = createSyncQueue<void | NavigationFailure | undefined>();
router.syncPush = (routeFunc: () => RouteLocationNamedRaw) =>
  queue.add(() => navigating.promise.then(() => router.push(routeFunc())), {
    waitingOn: router.navigatingTo?.fullPath,
    target: routeFunc(),
  });

router.syncReplace = (routeFunc: () => RouteLocationNamedRaw) =>
  queue.add(() => navigating.promise.then(() => router.replace(routeFunc())), {
    waitingOn: router.navigatingTo?.fullPath,
    target: routeFunc(),
  });

setRouter(router);
