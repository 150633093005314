<script setup lang="ts">
import { computed } from "vue";

import { getAlmInfo } from "@/backend/Backend";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import { useCardStore } from "@/store/card";

const props = defineProps<{ stickyId?: string }>();

const linkedSticky = computed(() =>
  props.stickyId ? useCardStore().cards[props.stickyId] : null,
);

const almIcon = computed(() => getAlmInfo().icon);
</script>

<template>
  <template v-if="linkedSticky">
    <span class="change-value">
      <TypeDot size="medium" :color="linkedSticky.type.color" />
      <span class="overflow-ellipsis"> {{ linkedSticky.text }}</span>
    </span>
    <span v-if="linkedSticky.almId && almIcon" class="change-value alm-id">
      <SvgIcon :name="almIcon" width="20" height="20" />
      {{ linkedSticky.almId }}
    </span>
  </template>
  <div v-else class="not-found">
    {{ $t("stickyChange.linkedStickyNotFound") }}
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";

.overflow-ellipsis {
  @include utils.ellipsis;

  min-width: 0;
}

.not-found {
  color: colors-old.$text-subtle-color;
}

.alm-id {
  flex-shrink: 0;
}
</style>
