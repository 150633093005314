<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { resetUser } from "@/error/sentry";
import { goToLogin } from "@/router/navigation";
import { clearSessionData } from "@/router/sessionData";
import { LogoutReason } from "@/router/types";
import AuthService from "@/services/auth.service";
import { clearUserCache } from "@/services/user.service";
import { useUserStore } from "@/store/user";

const route = useRoute();

onMounted(async () => {
  clearUserData();

  try {
    const logoutReason = (route.query.reason || "unknown") as LogoutReason;
    const res = await AuthService.logout(logoutReason);
    if (res.data.redirectUrl) {
      window.location.assign(res.data.redirectUrl);
    } else {
      login();
    }
  } catch (_) {
    login();
  }
});

function clearUserData() {
  clearUserCache();
  useUserStore().resetUser();
  resetUser();
  window.Intercom("shutdown");
}

function login() {
  clearSessionData();
  void goToLogin();
}
</script>

<template>
  <div />
</template>
