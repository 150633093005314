<script setup lang="ts">
import {
  BarElement,
  CategoryScale,
  type ChartData,
  Chart as ChartJS,
  type ChartOptions,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { computed } from "vue";
import { Bar as BarChart } from "vue-chartjs";
import { useI18n } from "vue-i18n";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const props = defineProps<{
  labels: ChartData<"bar">["labels"];
  datasets: ChartData<"bar">["datasets"];
}>();

const { t } = useI18n();

const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  aspectRatio: 1,
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: t("statusPage.responseTime"),
      },
      ticks: {
        // Append 'ms' to each value
        callback: (time) => t("statusPage.timeMs", { time }),
      },
    },
    x: {
      title: {
        display: true,
        text: t("statusPage.reqTime"),
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: t("statusPage.payloadTitle"),
    },
  },
};

const chartData = computed(() => ({
  labels: props.labels,
  datasets: props.datasets,
}));
</script>

<template>
  <div>
    <BarChart :data="chartData" :options="chartOptions" />
  </div>
</template>
