import { captureMessage } from "@/error/sentry";

import Resolvable from "./Resolvable";

/**
 * A queue containing functions that return a promise.
 * A function is executed when the promise of the previous function has been fulfilled.
 */
export function createSyncQueue<T>() {
  const queue = new Array<[() => Promise<T>, Resolvable<T>, object]>();
  let running = false;

  function doJobs() {
    const item = queue.shift();
    if (!item) {
      running = false;
    } else {
      running = true;
      const [job, resolvable] = item;
      job()
        .then((result) => resolvable.resolve(result))
        .catch((fail) => resolvable.reject(fail))
        .finally(doJobs);
    }
  }

  return {
    add(func: () => Promise<T>, desc: object): Promise<T> {
      const resolvable = new Resolvable<T>();
      queue.push([func, resolvable, { time: Date.now(), ...desc }]);
      if (queue.length > 5) {
        const info = { queues: queue.map((q) => q[2]) };
        void captureMessage("Long sync queue", { info });
      }
      if (!running) {
        doJobs();
      }
      return resolvable.promise;
    },
  };
}
