import { sortBy } from "lodash-es";

import { boardActions } from "@/action/boardActions";
import { sendCardMove } from "@/components/card/animator";
import { PositionedCard } from "@/components/utils/layout";
import { ExecutionMode } from "@/model/baseTypes";

import { useBoardStore } from "../board";
import { useBoardsStore } from "../boards";

export function saveCardPositions(): PositionedCard[] {
  return useBoardStore().selectedStickies.map((card) => ({
    data: { id: card.data.id },
    meta: { pos: { ...card.meta.pos } },
  }));
}

export function moveCards(cards: PositionedCard[], mode: ExecutionMode) {
  setCardPositions(cards, mode);
  if (mode !== "preview") {
    toFront(cards);
  }
}

export function resetCardPositions(cardStates: PositionedCard[]) {
  setCardPositions(cardStates, "normal");
}

function setCardPositions(cards: PositionedCard[], mode: ExecutionMode) {
  const boardId = useBoardStore().currentBoard().id;
  cards.forEach((card) => {
    if (mode !== "confirm") {
      useBoardsStore().setCardPos({
        id: card.data.id,
        boardId,
        ...card.meta.pos,
      });
    }
    if (mode !== "preview") {
      // TODO this is not clean as we use sender from inside a store, move it into an action
      sendCardMove(card.data.id, boardId, card.meta.pos);
    }
  });
}

function toFront(cards: PositionedCard[]) {
  sortBy(
    cards,
    (card) => card.meta.pos.y,
    (card) => card.meta.pos.x,
  ).forEach((card) => boardActions.cardToFront("internal", card.data.id));
}
