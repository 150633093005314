<script setup lang="ts">
import { faro } from "@grafana/faro-web-sdk";
import { type ChartData } from "chart.js";
import { isString } from "lodash-es";
import { type Ref, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { getServerInfo, getStatusWithData, loggedIn } from "@/backend/Backend";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import StatusDot from "@/components/ui/StatusDot/StatusDot.vue";
import { useNativeEvents } from "@/composables/useNativeEvents";
import { useTimeout } from "@/composables/useTimeout";
import HomeHeader from "@/pages/components/HomeHeader.vue";
import { useUserStore } from "@/store/user";
import { isCloud } from "@/utils/env/Environment";

import ResponseTable, { type DataRow } from "./ResponseTable.vue";
import StatusChart from "./StatusChart.vue";
import StatusStackedBarChart from "./StatusStackedBarChart.vue";
import { fetchGoogleIcon, now, nowMinutes } from "./statusHelpers";
import { useOnlineStatus } from "./useOnlineStatus";

const { repeated } = useTimeout();
const { t } = useI18n();
const router = useRouter();

const PING_INTERVAL_SHORT = 4_995; // Ping every 5 seconds to start (timing function adds a few ms delay)
const PING_INTERVAL_LONG = 14_995; // For longer sessions, only ping every 15 seconds
const PAYLOAD_INTERVAL = 60_000; // Space out bigger requests

const { online } = useOnlineStatus();

const { addEventListener } = useNativeEvents();

onMounted(() => {
  tabHasBeenInactive.value = false;
  addEventListener(document, "visibilitychange", updateLastVisibility);
});

/**
 * Manage the data for the status page
 */

const tableData = ref<DataRow[]>([]);

const tabHasBeenInactive = ref<boolean>(false);

// Send out 3 concurrent requests (HTTP, WS, and Google)
const makePingRequests = () => {
  const time = now();
  addLabel(time, pingLabels, true);
  void pingServer(time, false);
  void pingServer(time, true);
  void pingGoogle(time);
  // Add current tab state (to show when the tab is hidden)
  addVisibilityDataPoint(time);
};

// Send 4 heavier requests, 15 seconds apart (WS with 500 kB, HTTP with 0.5/1/2 MB)
const makePayloadRequests = () => {
  addLabel(nowMinutes(), payloadLabels, false);
  requestWithData({ http: false, size: 0.5, i: 0 });
  setTimeout(() => requestWithData({ http: true, size: 0.5, i: 1 }), 15_000);
  setTimeout(() => requestWithData({ http: true, size: 1, i: 2 }), 30_000);
  setTimeout(() => requestWithData({ http: true, size: 2, i: 3 }), 45_000);
};

// Send ping/payload requests repeatedly at intervals given
const pingRepetition = repeated(makePingRequests, PING_INTERVAL_SHORT, true);
repeated(makePayloadRequests, PAYLOAD_INTERVAL, true);

// After 5 minutes, switch to a longer interval for pings
setTimeout(() => pingRepetition.updateInterval(PING_INTERVAL_LONG), 5 * 60_000);

const userStore = useUserStore();

// Labels and data for the ping chart (line graph)
const payloadLabels = ref<ChartData["labels"]>([]);
const payloadDatasets = ref<ChartData<"bar">["datasets"]>([
  {
    label: t("statusPage.ws500kb"),
    backgroundColor: "sandybrown",
    data: [],
  },
  {
    label: t("statusPage.http500kb"),
    backgroundColor: "lightpink",
    data: [],
  },
  {
    label: t("statusPage.http1mb"),
    backgroundColor: "lightcoral",
    data: [],
  },
  {
    label: t("statusPage.http2mb"),
    backgroundColor: "indianred",
    data: [],
  },
]);

// Labels and data for the payload chart (bar graph)
const pingLabels = ref<ChartData["labels"]>([]);
const pingDatasets = ref<ChartData<"line">["datasets"]>([
  {
    label: t("statusPage.ws"),
    backgroundColor: "blue",
    data: [],
    yAxisID: "y",
  },
  {
    label: t("statusPage.http"),
    backgroundColor: "lightblue",
    data: [],
    yAxisID: "y",
  },
]);

// Don't ping Google if on-prem
if (isCloud) {
  pingDatasets.value.push({
    label: t("statusPage.google"),
    backgroundColor: "lightyellow",
    data: [],
    yAxisID: "y",
  });
}

// To show a shaded area on the chart when the tab was inactive
pingDatasets.value.push({
  label: t("statusPage.hidden"),
  backgroundColor: "rgba(100,100,100,0.1)",
  fill: "start",
  data: [],
  yAxisID: "yArea",
  pointStyle: false,
  showLine: false,
});

// Position of visibility data in dataset array
const VISIBILITY_INDEX = isCloud ? 3 : 2;

/**
 * Add the given timestamp to the given array, and re-assign the ref
 * so that vue-chartjs knows it should re-render
 */
const addLabel = (
  label: string,
  arrayRef: Ref<ChartData["labels"]>,
  addTableRow?: boolean,
) => {
  arrayRef.value = [...(arrayRef.value || []), label];

  if (addTableRow) {
    // Add a new row to the table
    tableData.value = [{ label: label, data: [] }, ...tableData.value];
  }
};

/**
 * Add the given response time to the appropriate array, and re-assign datasets
 * so that vue-chartjs knows it should re-render
 */
const addDataPoint = ({
  index,
  tableIndex,
  data,
  dataset,
  errorMessage,
  label,
}: {
  index: number;
  tableIndex?: number;
  label: string;
  data: number | null;
  dataset: Ref<ChartData["datasets"]>;
  errorMessage?: string;
}) => {
  // Add the data to the chart
  dataset.value[index].data = [...dataset.value[index].data, data];
  dataset.value = [...dataset.value];

  // Add the data to the table
  const row = tableData.value.find((row) => row.label === label);
  if (row) {
    row.data[tableIndex || index] = data || errorMessage || null;
  }
};

/**
 * Add the current visibility state to the chart and table
 */
const addVisibilityDataPoint = (time: string) => {
  const hidden = document.visibilityState === "hidden";
  const visibility = hidden ? t("statusPage.true") : t("statusPage.false");

  // Add the data to the chart
  pingDatasets.value[VISIBILITY_INDEX].data = [
    ...pingDatasets.value[VISIBILITY_INDEX].data,
    visibility as any, // Chartjs allows string datasets, but their typings don't play well with this
  ];
  pingDatasets.value = [...pingDatasets.value];

  // Mark the row for highlighting
  const row = tableData.value.find((row) => row.label === time);
  if (row) {
    row.hidden = hidden;
  }
};

/**
 * When the tab is hidden, update the last data point to show this
 * (in some cases the tab might not fire any more events while hidden,
 * eg. when the computer is put to sleep, but we still want to highlight
 * this for the user)
 */
const updateLastVisibility = () => {
  if (document.visibilityState !== "hidden") {
    return;
  }

  tabHasBeenInactive.value = true;

  // Update the last visibility data point to "Hidden"
  pingDatasets.value[VISIBILITY_INDEX].data = [
    ...pingDatasets.value[VISIBILITY_INDEX].data.slice(0, -1),
    t("statusPage.true") as any,
  ];
  pingDatasets.value = [...pingDatasets.value];

  const row = tableData.value[0];
  if (row) {
    row.hidden = true;
  }
};

/**
 * Fetch Google's favicon to measure response time (not on on-prem)
 */
async function pingGoogle(label: string) {
  if (!isCloud) {
    return;
  }

  try {
    const delay = await fetchGoogleIcon();
    faro.api?.pushMeasurement({
      type: "ping_google",
      values: { elapsedTimeMs: delay },
      context: {
        company: userStore.technicalUser.company,
        visibilityState: document.visibilityState,
      },
    });
    addDataPoint({
      dataset: pingDatasets,
      index: 2,
      label,
      data: Math.round(delay),
    });
  } catch (e) {
    const errorMessage = extractErrorMessage(e);
    addDataPoint({
      dataset: pingDatasets,
      index: 2,
      label,
      data: null,
      errorMessage,
    });
    // eslint-disable-next-line no-console
    console.warn("No response from Google. Error:", e);
  }
}

/**
 * Call getServerInfo to measure response time (with WS [default] or HTTP)
 */
async function pingServer(label: string, http: boolean) {
  // Use performance.now instead of Date.now to get more accurate timings when the user has reduced timer precision set
  // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now#reduced_time_precision)
  const start = performance.now();
  try {
    await getServerInfo(http);
    const delay = performance.now() - start;

    const type = http ? "ping_server_http" : "ping_server_ws";
    faro.api?.pushMeasurement({
      type,
      values: { elapsedTimeMs: delay },
      context: {
        company: userStore.technicalUser.company,
        visibilityState: document.visibilityState,
      },
    });
    addDataPoint({
      dataset: pingDatasets,
      index: http ? 1 : 0,
      label,
      data: Math.round(delay),
    });
  } catch (e) {
    const errorMessage = extractErrorMessage(e);
    addDataPoint({
      dataset: pingDatasets,
      index: http ? 1 : 0,
      label,
      data: null,
      errorMessage,
    });

    // eslint-disable-next-line no-console
    console.warn(
      `No response from piplanning.io (${http ? "HTTP" : "WS"}). Error: ${e}`,
    );
  }
}

/**
 *
 * @param config
 * @param config.http if true use HTTP, otherwise use WS
 * @param config.size size of payload to return (note limits in getStatusWithData())
 * @param config.i index of the dataset in which to store the results
 */
async function requestWithData({
  http,
  size,
  i,
}: {
  http: boolean;
  size: number;
  i: number;
}) {
  const start = performance.now();

  // Add the request to the closest row. This is not 100% accurate (request time for
  // the ping might be slightly different from this one), but it's close enough
  // and makes the table easiest to understand
  const rowLabel = tableData.value?.[0]?.label;
  try {
    await getStatusWithData(size, http);
    const delay = performance.now() - start;

    const type = http ? "payload_request_http" : "payload_request_ws";
    faro.api?.pushMeasurement({
      type,
      values: { elapsedTimeMs: delay, payloadSize: size },
      context: {
        company: userStore.technicalUser.company,
        visibilityState: document.visibilityState,
      },
    });
    addDataPoint({
      dataset: payloadDatasets,
      index: i,
      tableIndex: 3,
      label: rowLabel,
      data: Math.round(delay),
    });
  } catch (e) {
    const errorMessage = extractErrorMessage(e);
    addDataPoint({
      dataset: payloadDatasets,
      index: http ? 1 : 0,
      tableIndex: 3,
      label: rowLabel,
      data: null,
      errorMessage,
    });

    // eslint-disable-next-line no-console
    console.warn(
      `Error when requesting data from piplanning.io servers
      (${http ? "HTTP" : "WS"} with ${size}MB payload). Error: ${e}`,
    );
  }
}

const description = isCloud
  ? t("statusPage.descriptionCloud")
  : t("statusPage.descriptionOnPrem");

/**
 * Map the dataset labels to the table headers
 */
const tableHeaders = [
  t("statusPage.reqTime"),
  ...pingDatasets.value
    .map((dataset) => dataset.label)
    .filter((label) => label !== t("statusPage.hidden")), // inactivity shown as highlighting, not a column
  t("statusPage.payloadTitleShort"),
].filter((header) => isString(header));

/**
 * Navigate back to the previous page, or to the home page if there is no history
 * (when opened in a new tab)
 */
const back = () => {
  if (window?.history?.length > 1) {
    router.back();
  } else {
    void router?.push("/");
  }
};

/**
 * Show the error details taken from the query string (sent if the user
 * navigated here from the error dialog)
 */
const errorDetails = computed(() => {
  const encoded = router?.currentRoute?.value?.query?.errorDetails?.toString();
  const decoded = encoded && decodeURI(encoded);
  return decoded ? JSON.parse(decoded) : null;
});

/**
 * Extract the error message from an error object or string
 */
const extractErrorMessage = (e: unknown) =>
  e instanceof Error || isString(e)
    ? e.toString()
    : t("statusPage.unknownError");
</script>

<template>
  <main
    v-autofocus
    class="debug-page scrollable"
    tabindex="-1"
    aria-labelledby="status-page-title"
  >
    <div class="content">
      <HomeHeader>
        <template #back>
          <BaseButton
            variant="ghost"
            color="grey"
            icon-before="chevron/left"
            @click="back"
          >
            {{ $t("page.back") }}
          </BaseButton>
        </template>
        <template #title>
          <div class="header">
            <h1 id="status-page-title">{{ $t("page.status") }}</h1>
            <div class="data-pair">
              <StatusDot
                class="status-dot"
                :status-class="online ? 'done' : 'todo'"
                role="img"
                :alt="online ? $t('statusPage.yes') : $t('statusPage.no')"
              />
              <span class="status-title">{{ $t("statusPage.online") }}</span>
            </div>
            <div class="data-pair">
              <StatusDot
                class="status-dot"
                :status-class="loggedIn() ? 'done' : 'todo'"
                role="img"
                :alt="loggedIn() ? $t('statusPage.yes') : $t('statusPage.no')"
              />
              <span class="status-title">{{ $t("statusPage.loggedIn") }}</span>
            </div>
          </div>
        </template>
      </HomeHeader>
      <div class="details">
        <div class="description">
          <p>{{ description }}</p>
          <p>{{ $t("statusPage.descriptionPart2") }}</p>
        </div>
        <!-- If user got here from the error dialog, show a summary of that error
         (helps us know where to look if they send us a screenshot) -->
        <div v-if="errorDetails" class="error-details">
          <h2>{{ $t("statusPage.errorDetails") }}</h2>
          <p>{{ errorDetails.time }}</p>
          <p>{{ errorDetails.shortMessage }}</p>
          <p>
            {{
              $t("globalError.correlationId", {
                id: errorDetails?.correlationId,
              })
            }}
          </p>
        </div>
      </div>
      <div class="visualizations">
        <StatusChart
          :labels="pingLabels"
          :datasets="pingDatasets"
          :show-hidden-label="tabHasBeenInactive"
          class="ping-chart"
        />
        <StatusStackedBarChart
          :labels="payloadLabels"
          :datasets="payloadDatasets"
          class="payload-chart"
        />
      </div>
      <h2>{{ $t("statusPage.responseData") }}</h2>
      <p v-if="tabHasBeenInactive" class="subtitle">
        {{ $t("statusPage.hiddenExplanation") }}
      </p>
      <ResponseTable :headers="tableHeaders" :data="tableData" />
    </div>
  </main>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables/colors";

.debug-page {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .content {
    width: 100%;
    margin-top: 80px;
    padding: 0 40px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    gap: 2em;

    :first-child {
      flex-grow: 1;
    }
  }

  h1 {
    font-weight: font.$weight-bold;
  }

  h2 {
    font-size: font.$size-large;
    margin-bottom: 8px;
  }

  .status-title,
  h2,
  h3 {
    font-weight: font.$weight-bold;
  }

  .data-pair {
    font-size: font.$size-normal;
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .status-dot {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  // Place the 2 charts next to each other, same heights
  .visualizations {
    display: flex;
    padding-top: 2rem;

    // Line chart on the left, 2/3 of the screen
    .ping-chart {
      flex-grow: 1;
      flex-basis: 66%;
      min-width: 300px;
      aspect-ratio: 2 / 1;
    }

    // Bar chart on the right, 1/3 of the screen
    .payload-chart {
      flex-basis: 33%;
      min-width: 150px;
      aspect-ratio: 1 / 1;
    }
  }

  .details {
    display: flex;
    gap: 1rem;

    .description {
      flex-grow: 1;
      flex-basis: 65%;
    }

    .error-details {
      flex-basis: 35%;
      padding: 12px;
      border: 1px solid colors.$border;
      border-radius: 5px;
      background: colors.$background-grey;
      font-size: 65%;

      h2 {
        font-weight: font.$weight-normal;
        font-size: 100%;
        margin-bottom: 4px;
      }
    }
  }

  .subtitle {
    margin-bottom: 8px;
  }
}
</style>
