<script setup lang="ts">
import { computed, ref } from "vue";

import { useKeyboardDrag } from "@/components/StickyNote/composables/useKeyboardDrag";
import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const moveButtonRef = ref<HTMLElement | null>(null);

const emit = defineEmits(["deactivate"]);

const { moveMode, screenReaderMessage, handleKeydown, startMove, stopMove } =
  useKeyboardDrag({
    card,
    cardMeta,
    deactivate: () => emit("deactivate"),
  });

/**
 * Shortcut to enter 'move' mode
 */
const focusAndStartMove = () => {
  moveButtonRef.value?.focus();
  startMove();
};
defineExpose({ focusAndStartMove });

/**
 * Only show the move controls on boards where the user can drag the sticky
 */
const showControls = computed(() => {
  return ["team", "program", "solution", "risk", "flex"].includes(
    useBoardStore().currentBoard().type,
  );
});

/**
 * Toggle the move mode on and off
 */
const toggleMoveMode = () => {
  if (moveMode.value) {
    stopMove();
  } else {
    startMove();
  }
};
</script>

<template>
  <!-- The move control MUST be contained within an element with role="application", 
       otherwise screen readers (JAWS) will intercept the arrow keys - note that, 
       for VoiceOver, you still have to press Option+arrow -->
  <div
    v-if="showControls"
    class="sticky-note-accessibility-controls"
    role="application"
  >
    <IconButton
      ref="moveButtonRef"
      class="visible-on-focus"
      icon="arrow/move"
      :tooltip="$t('label.moveSticky.controls')"
      :activated="moveMode"
      @click.stop="toggleMoveMode"
      @keydown="handleKeydown"
      @blur="stopMove()"
    />
    <ScreenReaderMessage>{{ screenReaderMessage }}</ScreenReaderMessage>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/z-index";

.sticky-note-accessibility-controls {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  &:not(:focus-within) {
    opacity: 0;
    z-index: z-index.$a11y-hidden;
    top: -200vh;
    left: -200vw;
    bottom: unset;
    right: unset;
  }

  :deep(.visible-on-focus) {
    position: relative;
    color: inherit !important;
  }
}
</style>
