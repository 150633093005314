import { Codec, JSONCodec } from "nats.ws";

interface RPCRequestData {
  args?: unknown[];
  kwargs?: Record<string, unknown>;
}

export class NatsRPCPayload {
  args: unknown[];
  kwargs: Record<string, unknown>;
  codec: Codec<unknown> = JSONCodec();

  constructor({ args = [], kwargs = {} }: RPCRequestData = {}) {
    this.args = args;
    this.kwargs = kwargs;
  }

  encode(): Uint8Array {
    const RPCData = this.prepareRPCRequestData();
    if (Object.keys(RPCData).length === 0) {
      return new Uint8Array(0);
    }
    return this.codec.encode(RPCData);
  }

  private prepareRPCRequestData(): RPCRequestData {
    const RPCData: RPCRequestData = {};
    if (this.args.length > 0) {
      RPCData.args = this.args;
    }
    if (Object.keys(this.kwargs).length > 0) {
      RPCData.kwargs = this.kwargs;
    }
    return RPCData;
  }
}
