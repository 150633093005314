import { isFunction } from "lodash-es";
import { onMounted, onUnmounted } from "vue";

export function useTimeout() {
  function repeated(
    action: () => unknown,
    interval: number,
    strictTiming?: boolean,
  ) {
    let timeout = -1;
    onMounted(execute);
    onUnmounted(() => window.clearTimeout(timeout));

    function execute() {
      const res = action();
      if (!strictTiming && isPromise(res)) {
        res.then(schedule);
      } else {
        schedule();
      }
    }

    function schedule() {
      timeout = window.setTimeout(execute, interval);
    }

    function updateInterval(newInterval: number) {
      window.clearTimeout(timeout);
      interval = newInterval;
      schedule();
    }

    return { updateInterval };
  }

  return { repeated };
}

function isPromise(value: any): value is PromiseLike<unknown> {
  return isFunction(value?.then);
}
