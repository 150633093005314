<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/ui/DropdownMenu/DropdownTrigger.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import { textAlignmentOptions } from "@/store/clientSettings";

const props = defineProps<{ modelValue: string; triggerLabel?: string }>();
const emit = defineEmits<{ "update:modelValue": [font: string] }>();

function select(alignment: string) {
  emit("update:modelValue", alignment);
}

const selectedOption = computed(() => {
  const option = textAlignmentOptions.find(
    (opt) => opt.value === props.modelValue,
  );

  return option?.label || "";
});
</script>

<template>
  <DropdownMenu
    class="settings-text-alignment"
    :offset="{ top: 15 }"
    :disable-teleport="true"
  >
    <template #trigger="{ isOpen }">
      <DropdownTrigger
        role="combobox"
        aria-controls="text-alignment-popup"
        aria-autocomplete="none"
        aria-haspopup="listbox"
        :aria-label="props.triggerLabel"
        :aria-expanded="isOpen"
        :value="$t(selectedOption)"
        @keydown.space.stop
      />
    </template>
    <div
      id="text-alignment-popup"
      v-keyboard-nav
      role="listbox"
      @keydown.escape.stop
    >
      <ListItem
        v-for="option in textAlignmentOptions"
        :key="option.value"
        role="option"
        :aria-selected="option.value === props.modelValue"
        @click="select(option.value)"
      >
        {{ $t(option.label) }}
      </ListItem>
    </div>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
.settings-text-alignment {
  .dropdown-trigger > :deep(span:first-child) {
    width: 80px;
    font-family: inherit;
  }
}
</style>
