<script setup lang="ts">
import { ref, watch } from "vue";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/ui/DropdownMenu/DropdownTrigger.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { useSessionStore } from "@/store/session";
import { boardDiffTimespanChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { lastWeekDay } from "@/utils/date";
import { formatShortDate } from "@/utils/dateFormat";

const values = [
  {
    id: "workday",
    name: /*$t*/ "boardDiff.lastWorkday",
    date: () => lastWeekDay(new Date()),
  },
  {
    id: "iteration",
    name: /*$t*/ "boardDiff.iterationStart",
    date: () => useSessionStore().currentIteration.start,
  },
  {
    id: "pi",
    name: /*$t*/ "boardDiff.piStart",
    date: () => useSessionStore().iterations[0].start,
  },
];

export interface DateItem {
  id: string;
  date: Date;
}

const model = defineModel<DateItem | undefined>();
const selection = ref(
  values.find((value) => value.id === model.value?.id) || values[0],
);

watch(selection, (value) => {
  trackEvent(boardDiffTimespanChanged(value.id));
});

function date(value: (typeof values)[number]) {
  return { date: formatShortDate(value.date()) };
}

select(selection.value);

function select(value: (typeof values)[number]) {
  model.value = { id: value.id, date: value.date() };
}
</script>

<template>
  <div class="date-filter">
    <div>{{ $t("boardDiff.dateFilter") }}</div>
    <DropdownMenu width="lg">
      <template #trigger="{ isOpen }">
        <DropdownTrigger
          :value="$t(selection.name, date(selection))"
          :aria-expanded="isOpen"
          aria-haspopup="listbox"
          aria-controls="date-filter-list"
        />
      </template>
      <SelectableList
        id="date-filter-list"
        v-model="selection"
        v-keyboard-nav
        :values="values"
        @update:model-value="select"
      >
        <template #default="{ value }">
          <span>{{ $t(value.name, date(value)) }}</span>
        </template>
      </SelectableList>
    </DropdownMenu>
  </div>
</template>

<style lang="scss">
.date-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  .dropdown-menu {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
    }
  }
}
</style>
