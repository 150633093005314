<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { TippyOptions } from "vue-tippy";

import { Action } from "@/action/actions";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Icon } from "@/model/icon";

const props = defineProps<{
  action: Action<any[]>;
  icon?: Icon;
  tooltip?: string;
  position?: TippyOptions["placement"];
  trigger?: string;
}>();

defineOptions({ inheritAttrs: false });

const { t } = useI18n();
const tooltip = computed(() => {
  const name = props.action.data.name;
  return props.tooltip || (name ? t(name) : "");
});
const shortcut = computed(() => props.action.data.shortcut?.name());
</script>

<template>
  <IconButton
    :icon="props.icon || action.data.icon || 'base/placeholder'"
    :position="position"
    :tooltip="tooltip"
    :shortcut="shortcut"
    v-bind="$attrs"
    @click="action('mainMenu', props.trigger)"
    @pointerenter="props.action.data.startPreview"
    @pointerleave="props.action.data.stopPreview"
  />
</template>
