import { ApplicationError } from "@/error/ApplicationError";

export class TimeoutError extends ApplicationError {
  constructor(contexts: {
    request: Record<string, unknown>;
    response: Record<string, unknown>;
  }) {
    super("TimeoutError", "", contexts);
  }
}
