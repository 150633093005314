import { fakeZoom } from "@/model/Settings";

const scaleAttr = "data-scale";
const scrollbarAdjusted = "scrollbar-adjusted";

/**
 * Scale the element and zoom out right after, so it appears as before scaling.
 * As result this will increase width of scroll bar and will fix the problem
 * of slow scrolling in Chrome. Scaling is computed based on the current element
 * fontSize but won't exceed 10x scaling (to avoid extremely large scroll bar when font size
 * is too large).
 */
export function adjustScrollbarWidth(el: HTMLElement) {
  if (isInBoard(el)) {
    const fontSize = getComputedStyle(el).fontSize;
    const fontSizeBasedZoom = parseInt(fontSize, 10) / fakeZoom;
    const zoom = Math.min(fontSizeBasedZoom, 10);
    el.classList.add(scrollbarAdjusted);
    el.setAttribute(scaleAttr, "" + zoom);
    el.style.transformOrigin = "top left";
    setElementScale(el);
  }
}

function setElementScale(el: HTMLElement, set = true) {
  const zoom = el.getAttribute(scaleAttr);
  if (zoom) {
    if (set) {
      el.style.transform = "scale(" + +zoom + ")";
      const percent = 100 / +zoom + "%";
      el.style.height = el.style.width = el.style.fontSize = percent;
    } else {
      el.style.transform = "";
      el.style.height = el.style.width = el.style.fontSize = "";
    }
  }
}

function isInBoard(el: Element | null) {
  while (el) {
    if (el.id === "boards") {
      return true;
    }
    el = el.parentElement;
  }
}
