<script lang="ts" setup>
import { computed } from "vue";

import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import MirrorSticky from "../../../MirrorSticky/MirrorSticky.vue";
import * as actions from "../../actions";
import { isActionVisible } from "../../utils";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() => {
  const isVisible = isActionVisible("mirror", card, board.value);
  const isMirrorable = actions.isStickyMirrorable(card);
  return isVisible && isMirrorable;
});
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    width="lg"
    name="mirror"
    :tooltip-text="$t('cardAction.mirror')"
    :aria-label="$t('label.actionMenu.mirror')"
    aria-controls="mirror-sticky-dropdown"
  >
    <SvgIcon name="action/mirror" width="20" height="20" />
    <template #dropdown>
      <MirrorSticky id="mirror-sticky-dropdown" trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>
