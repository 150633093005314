<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from "vue";

import { useNativeEvents } from "@/composables/useNativeEvents";

import TreeNode from "./components/TreeNode.vue";
import { TreeNodeData, TreeNodeInstance } from "./types";

const { addEventListener } = useNativeEvents();

const props = defineProps<{
  nodes: TreeNodeData[];
}>();

const emit = defineEmits<{ select: [node: TreeNodeData] }>();

const treeRef = ref<HTMLElement | null>(null);
const nodeRefs = ref<Record<string, TreeNodeInstance | null>>({});

const setNodeRef = (key: TreeNodeData["id"], cmp: TreeNodeInstance | null) => {
  nodeRefs.value[key] = cmp;
};

const selectedNodeId = ref<string | null>(null);

const handleSelectNode = (node: TreeNodeData) => {
  selectedNodeId.value = node.id;

  emit("select", node);
};

function findSelectedNode(nodes: TreeNodeData[]): TreeNodeData | null {
  for (const node of nodes) {
    if (node.selected) return node;
    const foundChild = findSelectedNode(node.children);
    if (foundChild) return foundChild;
  }
  return null;
}

async function expandParentNodes(
  nodes: TreeNodeData[],
  nodeId: string | null,
  parents: TreeNodeData[] = [],
): Promise<void> {
  if (!nodeId) return;
  for (const node of nodes) {
    if (node.id === nodeId) {
      for (const parent of parents) {
        const parentRef = nodeRefs.value[parent.id];
        if (parentRef) {
          parentRef.expand();
          await nextTick();
        }
      }
      return;
    }

    if (node.children.length > 0) {
      await expandParentNodes(node.children, nodeId, [...parents, node]);
    }
  }
}

onMounted(() => {
  const initialSelectedNode = findSelectedNode(props.nodes);
  if (initialSelectedNode) {
    handleSelectNode(initialSelectedNode);
    expandParentNodes(props.nodes, selectedNodeId.value);
  }

  if (treeRef.value) {
    addEventListener(treeRef.value, "focusout", handleFocusOut);
  }
});

watch(
  () => props.nodes,
  () => {
    const initialSelectedNode = findSelectedNode(props.nodes);
    selectedNodeId.value = initialSelectedNode?.id ?? null;
    if (initialSelectedNode) {
      expandParentNodes(props.nodes, selectedNodeId.value);
    }
  },
  { deep: true },
);

const handleFocusOut = (event: FocusEvent) => {
  if (
    !treeRef.value?.contains(event.relatedTarget as Node) &&
    selectedNodeId.value
  ) {
    expandParentNodes(props.nodes, selectedNodeId.value);
  }
};
</script>

<template>
  <div ref="treeRef" role="tree" aria-label="Tree View">
    <TreeNode
      v-for="(node, index) in props.nodes"
      :ref="(ref) => setNodeRef(node.id, ref as TreeNodeInstance)"
      :key="node.id"
      :node="node"
      :selected-node-id="selectedNodeId"
      :indentation="0"
      :is-first-node="index === 0"
      :set-node-ref="setNodeRef"
      @select-node="handleSelectNode"
    />
  </div>
</template>
