import color from "@/model/color.module.scss";

/* eslint-disable no-console */
export function showLogo() {
  const black = "color:" + color.textPrimary;
  const blue = "color:" + color.ok;
  const red = "color:" + color.error;
  console.log(
    "             %c███████████\n" +
      "             █         █\n" +
      "             █         █   %c      (_)     | |                 (_)\n" +
      "%c███████████  %c█         █   %c _ __  _ _ __ | | __ _ _ __  _ __  _ _ __   __ _\n" +
      "%c█         █  %c█         █   %c| '_ \\| | '_ \\| |/ _` | '_ \\| '_ \\| | '_ \\ / _` |\n" +
      "%c█         █  %c█         █   %c| |_) | | |_) | | (_| | | | | | | | | | | | (_| |\n" +
      "%c█         █  %c███████████   %c| .__/|_| .__/|_|\\__,_|_| |_|_| |_|_|_| |_|\\__, |\n" +
      "%c█         █                %c| |     | |                                 __/ |\n" +
      "%c█         █                %c|_|     |_|                                |___/\n" +
      "%c███████████",
    blue + ";line-height: 12px",
    black,
    red,
    blue,
    black,
    red,
    blue,
    black,
    red,
    blue,
    black,
    red,
    blue,
    black,
    red,
    black,
    red,
    black,
    red,
  );
}

window.baseSize = () => {
  const child = window.open(location.href, "", "resizable=1")!;
  child.addEventListener("load", () => {
    const docElem = child.document.documentElement;
    const dw = 1920 - docElem.clientWidth;
    const dh = 1080 - docElem.clientHeight;
    child.resizeBy(dw, dh);
  });
};
