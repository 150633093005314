<!-- 
  Transition wrapper to animate the height/opacity of content when mounting/unmounting
 -->
<script setup lang="ts">
// Set 'wipe' to use a wipe transition (by default, the content can overflow the container during the animation)
defineProps<{ wipe?: boolean }>();

// When the content is rendered, animate the height
// (not sure why this bit has to be hand-coded instead of <Transition>'s magic)
const onEnter = (element: Element) => {
  const el = element as HTMLElement;
  if (!el?.style) {
    return;
  }

  // Calculate the element's full height
  el.style.height = "auto";
  const fullHeight = window.getComputedStyle(el).height;

  // Reset height so the transition will work properly
  el.style.height = "0";

  // Set the new height
  el.style.height = fullHeight;
};

// As the content is unmounted, animate the height
const onLeave = (element: Element) => {
  const el = element as HTMLElement;
  if (!el?.style) {
    return;
  }

  el.style.height = "0";
};
</script>

<template>
  <div :class="{ 'hide-overflow': wipe }">
    <transition name="reveal-height" @enter="onEnter" @leave="onLeave">
      <slot></slot>
    </transition>
  </div>
</template>

<style lang="scss">
.reveal-height-enter-from,
.reveal-height-leave-to {
  opacity: 0;
  height: 0;
}

.reveal-height-enter-active,
.reveal-height-leave-active {
  transition:
    opacity 500ms,
    height 500ms;
}

.hide-overflow {
  overflow: hidden;
}
</style>
