<script setup lang="ts">
import { computed, provide } from "vue";

import StickyNoteTextStatic from "@/components/StickyNote/components/StickyNoteText/StickyNoteTextStatic.vue";
import BaseFlag from "@/components/card/BaseFlag.vue";
import { cardKey } from "@/components/card/injectKeys";
import { riskTitle } from "@/model/baseTypes";
import { Card } from "@/model/card";
import { toCssColor } from "@/utils/color";

interface Props {
  selectedRisk: Card;
}

const props = defineProps<Props>();

// Providers for StickyNoteTextStatic
provide(cardKey, props.selectedRisk);

/**
 * Extract the label key based on the risk's type
 */
const selectedRiskTypeLabel = computed(() => {
  const type = props.selectedRisk?.risk || "";
  return riskTitle(type);
});

const backgroundColor = computed(() => {
  return props.selectedRisk
    ? toCssColor(props.selectedRisk.type.color)
    : "white";
});

const hasFlag = computed(() => {
  const icon = props.selectedRisk?.flagType?.icon;
  return !!icon && icon !== "none";
});
</script>

<template>
  <div v-if="selectedRisk" class="detail-card" :style="{ backgroundColor }">
    <StickyNoteTextStatic class="risk-text" not-on-board />
    <span class="detail-card-footer">
      <div v-if="hasFlag" class="flag">
        <BaseFlag
          v-if="hasFlag"
          :flag="selectedRisk.flagType"
          class="flag-icon"
        />
      </div>
      <span class="tag">{{ $t(selectedRiskTypeLabel) }}</span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.detail-card {
  aspect-ratio: 1 / 1;
  height: min(80%, 600px, 50vw);
  padding: 32px;
  border-radius: variables.$common-border-radius;
  background-color: colors-old.$light-background-color;
  display: flex;
  flex-direction: column;

  .risk-text {
    height: 100%;
  }

  .detail-card-footer {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .flag {
      justify-self: flex-start;
      width: 40px;
    }

    .tag {
      margin-left: auto;
      padding: 8px 12px;
      border-radius: variables.$common-border-radius;
      background-color: rgba(
        colors-old.$text-primary-color,
        colors-old.$background-color-transparency
      );
      color: colors-old.$text-primary-color;
      font-weight: font.$weight-bold;
      font-size: font.$size-large;
      line-height: 20px;
    }
  }
}
</style>
