<script setup lang="ts">
import { computed } from "vue";

import { useBoardsStore } from "@/store/boards";

import MetricsModalLoadCapacityBody, {
  LoadCapacityItem,
} from "./MetricsModalLoadCapacityBody.vue";
import { toLoadCapacityItem } from "./metrics";

const items = computed((): LoadCapacityItem[] => {
  return useBoardsStore()
    .artTeamBoards()
    .map(({ team, iterations }) => {
      const teamIterations = toLoadCapacityItem(iterations).reduce(
        (acc, curr) => {
          return {
            load: acc.load + curr.load,
            capacity: acc.capacity + curr.capacity,
          };
        },
        { load: 0, capacity: 0 },
      );
      return { id: team.id, title: team.name, ...teamIterations };
    });
});
</script>

<template>
  <MetricsModalLoadCapacityBody :overrides="{ items }" />
</template>
