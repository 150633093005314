import { noop } from "lodash-es";

import { sender } from "@/backend/Sender";
import { namedKey } from "@/components/utils/Shortcuts";
import CardFlag from "@/model/CardFlag";
import { AlmSourceId, RiskType } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { Card, Reaction } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { AuthUser } from "@/model/user";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { useSelectionStore } from "@/store/selection";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { StickyCreateTrigger } from "@/utils/analytics/events";

import { action, defineActions } from "./actions";
import {
  CardCreateProps,
  addCard,
  cardCreateProps,
  startAddCard,
} from "./addCard";
import { boardActions } from "./boardActions";

export const cardActions = defineActions("card", {
  add: action(startAddCard, { shortcut: namedKey("KeyN") }),
  togglePalette: action(noop, {
    icon: "action/add-new",
    name: /*$t*/ "action.addSticky",
    shortcut: namedKey("KeyN"),
  }),
  copy: action(noop),
  paste: action(startAddCard),
  pasteMultiple: action(addMultipleCards, {
    name: /*$t*/ "action.paste",
  }),
  pasteText: action(startAddCard),
  duplicate: action(
    async (trigger?: StickyCreateTrigger) => {
      const cards = useBoardStore().selectedOrActiveStickies;
      if (cards.length === 1) {
        startAddCard(
          cardCreateProps(cards[0], { findFreePos: true }),
          true,
          trigger,
        );
      } else {
        const ids = await addMultipleCards(
          cards.map((card) => cardCreateProps(card)),
        );
        useSelectionStore().duplicated(ids);
      }
    },
    {
      name: /*$t*/ "action.duplicate",
      shortcut: namedKey("KeyD", { modifiers: ["altCtrl"] }),
    },
  ),
  delete: action((id: string, boardId: string) => {
    if (useCardStore().delete({ id, boardId })) {
      sender.deleteCard(boardId, id);
    }
  }),

  mirror: action((id: string, teamId: string | null, board: Board) => {
    if (!teamId && board.type === "team") {
      cardActions.setTeam("internal", id, board.team.id);
    }
    return sender.mirror(id, board.id);
  }),

  move: action((id: string, teamId: string) => sender.move(id, teamId)),

  toProgram: action((id: string) =>
    sender.mirror(id, useBoardsStore().boardByType("program").id),
  ),

  toSolution: action((id: string) =>
    sender.mirror(id, useBoardsStore().boardByType("solution").id),
  ),

  toRisk: action((id: string, teamId: string) => {
    const boardId = useBoardsStore().boardByType("risk", {
      artId: useTeamStore().team?.current?.artId,
    }).id;
    const card = useCardStore().cards[id];
    sender.toRisk(id, boardId, card.text, card.type.id, teamId);
  }),

  toggleReaction: action(async (cardId: string, reaction: Reaction) => {
    if (useCardStore().hasCurrentUserReaction(cardId, reaction)) {
      await sender.removeReaction(cardId, reaction);
    } else {
      await sender.addReaction(cardId, reaction);
    }
  }),

  startAlter: action((id: string) =>
    sender.startAlterCard(useBoardStore().currentBoard().id, id),
  ),

  stopAlter: action((id: string) => {
    const card = useBoardStore().currentBoard().cards[id];
    // could have just been deleted
    // don't stop alter when card is selected
    if (card && !useBoardStore().isStickySelected(id)) {
      sender.stopAlterCard(useBoardStore().currentBoard().id, id, {
        type: card.data.type,
        x: card.meta.pos.x,
        y: card.meta.pos.y,
      });
    }
  }),

  setAlmSource: action((id: string, almSourceId: AlmSourceId | null) => {
    useCardStore().setAlmSource({ id, almSourceId });
    sender.alterCard(useBoardStore().currentBoard().id, id, { almSourceId });
  }),

  setPriority: action((id: string, priority: number, priorities?: boolean) => {
    const { use, priority: prio } = useCardStore().setPriority({
      id,
      priority,
      priorities,
    });
    alterCard(
      useBoardStore().currentBoard().id,
      id,
      { id, priority: prio },
      use,
    );
  }),

  setPoints: action((id: string, points: number) => {
    useCardStore().setPoints({ id, points });
    alterCard(useBoardStore().currentBoard().id, id, { points });
  }),

  setFlag: action((id: string, flagType: CardFlag) => {
    useCardStore().setFlag({ id, flagType });
    alterCard(useBoardStore().currentBoard().id, id, { flagType });
  }),

  setRisk: action((id: string, risk: RiskType) => {
    useCardStore().setRisk({ id, risk });
    alterCard(useBoardStore().currentBoard().id, id, { risk });
  }),

  setText: action(
    (id: string, text: string) => {
      useCardStore().setText({ id, text });
      sender.alterCard(useBoardStore().currentBoard().id, id, { text });
    },
    {
      history: {
        merge: true,
        saveState: (id) => ({
          id,
          text: useCardStore().cards[id].text,
        }),
      },
    },
  ),

  setIteration: action((id: string, iterationId: number | null) => {
    if (useCardStore().setIteration({ id, iterationId })) {
      sender.alterCard(useBoardStore().currentBoard().id, id, { iterationId });
    }
  }),

  setTeam: action((cardId: string, teamId: string | null) => {
    useCardStore().setTeam({ id: cardId, teamId });
    alterCard(useBoardStore().currentBoard().id, cardId, { teamId });
  }),

  setTeamAction: action((id: string, teamId: string) => {
    if (teamId === NO_TEAM_ID) {
      cardActions.setTeam("internal", id, null);
      cardActions.setIteration("internal", id, null);
    } else {
      cardActions.setTeam("internal", id, teamId);
    }
  }),

  setDepend: action((id: string, teamId: string) => {
    const { board, card } = useCardStore().setDependAction({ id, teamId });
    sender.mirror(id, board.id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
    alterCard(useBoardStore().currentBoard().id, id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
  }),

  setArt: action((id: string, artId: string | null) => {
    useCardStore().setArt({ id, artId });
    alterCard(useBoardStore().currentBoard().id, id, { artId });
  }),

  setAssignee: action((id: string, assignee: AuthUser | null) => {
    useCardStore().setAssignee(id, assignee);
    alterCard(useBoardStore().currentBoard().id, id, { assignee });
  }),

  setType: action((id: string, type: string, boardId?: string) => {
    const { board, props, priorities } = useCardStore().setType({
      id,
      type,
      boardId,
    });
    sender.alterCard(board.id, id, props, priorities);
  }),

  setStatus: action(
    (board: Board, cardId: string, statusName: string, transition?: string) => {
      const status = useCardStore().setStatus(board, cardId, statusName, {
        transition,
      });
      alterCard(board.id, cardId, { status, transition });
    },
  ),

  // the actual moving is done continuously by mouse dragging
  // we need this here to track the action and to save the state for undo
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPosition: action((_: string[]) => {}, {
    history: {
      saveState: (ids) =>
        ids.map((id) => ({
          id,
          pos: { ...useBoardStore().currentBoard().cards[id].meta.pos },
        })),
    },
  }),

  toggleActivity: action((id: string) => useActivityStore().toggleCard(id)),
});

async function addMultipleCards(cards: CardCreateProps[]) {
  boardActions.clearCardSelection("internal");
  return await Promise.all(
    cards.map(async (card) => {
      const id = await addCard(card, false);
      useBoardStore().selectSticky(id);
      return id;
    }),
  );
}

/**
 * If the card is currently locked by the user (editing or selected),
 * send alterCard. Otherwise, send stopAlterCard.
 *
 * This ensures that the changes are persisted, but the card isn't unlocked accidentally
 */
export function alterCard(
  boardId: string,
  id: string,
  props: Partial<Card & RelativeCoordinate>,
  priorities?: boolean,
) {
  if (useBoardStore().isStickyIdSelectedOrActive(id)) {
    sender.alterCard(boardId, id, props, priorities);
  } else {
    sender.stopAlterCard(boardId, id, props);
  }
}
