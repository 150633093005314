<script lang="ts" setup>
import { computed } from "vue";

import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import TeamDropdown from "@/components/StickyNote/components/TeamDropdown/TeamDropdown.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import { isActionVisible } from "../../utils";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() => isActionVisible("team", card, board.value));
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="team"
    :tooltip-text="$t('cardAction.team')"
    :aria-label="$t('label.actionMenu.team')"
    aria-controls="team-dropdown"
  >
    <SvgIcon name="org/team" width="20" height="20" />
    <template #dropdown><TeamDropdown id="team-dropdown" /></template>
  </ActionMenuItem>
</template>
