<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <div class="team-objectives" @keydown.esc="removeObjective">
    <div class="header" @click="editObjectives">
      <!-- eslint-enable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
      <h2 class="header-title h3">
        <SvgIcon
          name="board/objectives"
          width="1.25em"
          height="1.25em"
          aria-hidden="true"
        />
        <span>{{ $t("teamObjectives.teamObjectives") }}</span>
      </h2>
      <IconButton
        id="team-objectives-trigger"
        icon="arrow/expand"
        size="small"
        :tooltip="$t('teamObjectives.expand')"
        :aria-label="$t('label.teamObjectives.expand')"
      />
    </div>
    <div class="scrollable" @wheel="stopScrollPropagation" @scroll="onScroll">
      <div>
        <div class="objective-group-header">
          <h3 class="h4">{{ $t("objectives.committed") }}</h3>
          <span aria-hidden="true">
            <h3 class="link h4">{{ $t("general.links") }}</h3>
            <h3 :title="$t('objectives.businessValue')" class="h4">
              {{ $t("objectives.bv") }}
            </h3>
          </span>
        </div>
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
        <div
          v-for="(objective, index) in board.objectives"
          :key="objective.id"
          class="objective"
          @click="toggleObjective(objective.id)"
          @keypress.enter="toggleObjective(objective.id)"
          @keypress.space="toggleObjective(objective.id)"
          @blur="blurObjective"
        >
          <TeamObjectiveRow
            :index="index + 1"
            :objective="objective"
            :team="board.team"
            :selected="isSelected(objective.id)"
          />
        </div>
        <div />
        <div class="objective-group-header">
          <span>
            <h3 class="h4">{{ $t("objectives.uncommitted") }}</h3>
          </span>
          <span aria-hidden="true">
            <h3 class="link h4">{{ $t("general.links") }}</h3>
            <h3 :title="$t('objectives.businessValue')" class="h4">
              {{ $t("objectives.bv") }}
            </h3>
          </span>
        </div>
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
        <div
          v-for="(stretchObjective, index) in board.stretchObjectives"
          :key="stretchObjective.id"
          class="objective"
          @click="toggleObjective(stretchObjective.id)"
          @keypress.enter="toggleObjective(stretchObjective.id)"
          @keypress.space="toggleObjective(stretchObjective.id)"
          @blur="blurObjective"
        >
          <TeamObjectiveRow
            :index="index + 1"
            :objective="stretchObjective"
            :team="board.team"
            :selected="isSelected(stretchObjective.id)"
          />
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { toggleActions } from "@/action/toggleActions";
import TeamObjectivesModal from "@/components/modal/TeamObjectivesModal.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { cancelLongClick } from "@/components/utils/Gestures";
import ScrollSupport from "@/mixins/ScrollSupport";
import ShortcutUser from "@/mixins/ShortcutUser";
import { IdMap } from "@/model/baseTypes";
import { BoardCard } from "@/model/card";
import { Objective } from "@/model/objective";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";

import TeamObjectiveRow from "./TeamObjectiveRow.vue";

@Component({
  components: { SvgIcon, IconButton, TeamObjectiveRow },
})
export default class TeamObjectives extends mixins(
  ScrollSupport,
  ShortcutUser,
) {
  selectedObjectiveId = "";
  highlightedObjectives = new Array<string>();

  created() {
    this.globalActionShortcut(toggleActions.showTeamObjectives);
  }

  mounted() {
    this.adjustScrollbarWidth();
  }

  get cards(): IdMap<BoardCard> {
    return this.board.cards;
  }

  get masterCards() {
    return [useBoardStore().activeCard, useLinkStore().markingCardLinkedCards];
  }

  @Watch("masterCards")
  masterCardsChanged(masterCards: BoardCard[]) {
    this.highlightedObjectives = masterCards.flatMap(
      (card) => card?.data.objectives?.map((obj) => obj.id) || [],
    );
  }

  get board() {
    return useBoardsStore().boardByType("team");
  }

  onScroll() {
    cancelLongClick();
  }

  isSelected(objectiveId: Objective["id"]) {
    if (this.selectedObjectiveId === objectiveId) {
      return true;
    }
    return this.highlightedObjectives.includes(objectiveId);
  }

  editObjectives() {
    useModalStore().open(TeamObjectivesModal, {
      attrs: { boardId: this.board.id },
    });
  }

  toggleObjective(objectiveId: Objective["id"]) {
    if (this.selectedObjectiveId === objectiveId) {
      this.removeObjective();
    } else {
      this.selectedObjectiveId = objectiveId;
      const linkStore = useLinkStore();
      linkStore.markObjectiveLinkedCards(objectiveId);
    }
  }

  blurObjective(event: FocusEvent) {
    if ((event.relatedTarget as Element)?.classList.contains("objective")) {
      return;
    }
    this.removeObjective();
  }

  removeObjective() {
    if (this.selectedObjectiveId !== "") {
      useLinkStore().removeObjectiveLinkedMarks(this.selectedObjectiveId);
      this.selectedObjectiveId = "";
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/board";

.team-objectives {
  .header-title {
    font-size: 60% !important;
    display: inline-flex;
    align-items: center;
    gap: board.len(15px);
  }
}
</style>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.board-team {
  .team-objectives {
    padding: 0;
    display: flex;
    flex-direction: column;

    .objective-group-header {
      padding: board.len(12px) board.len(24px) 0 board.len(24px);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: colors-old.$text-primary-color;
        font-size: board.len(14px);
        font-weight: font.$weight-extra-bold;
      }

      .link {
        margin-right: board.len(38px);
      }
    }

    .header {
      width: 100%;
      color: colors-old.$text-primary-color;
      background-color: colors-old.$back-color;
      padding: board.len(6px) board.len(12px) board.len(6px) board.len(24px);
      border-bottom: board.len(3px) solid colors-a11y.$board-border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .base-button {
        font-size: inherit;
        padding: board.len(12px);

        svg {
          width: board.len(20px);
          height: board.len(20px);
        }
      }
    }

    .scrollable {
      position: relative;
      padding-bottom: board.len(12px);
    }

    .objective {
      cursor: pointer;
    }
  }
}
</style>
