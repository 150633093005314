<script setup lang="ts">
import { isArray, isString } from "lodash-es";
import { computed } from "vue";

import { Color } from "@/model/baseTypes";
import { fromCssColor, isWhiteColor, toCssColor } from "@/utils/color";

const props = defineProps<{
  color: Color | string;
  size?: "small" | "medium" | "large";
}>();
const cssColor = computed(() =>
  isArray(props.color) ? toCssColor(props.color) : props.color,
);
const border = computed(() => {
  const color = isString(props.color) ? fromCssColor(props.color) : props.color;
  return isWhiteColor(color);
});
</script>

<template>
  <span
    class="type-dot"
    :class="[size || 'small', { border }]"
    :style="{ backgroundColor: cssColor }"
  />
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.type-dot {
  display: inline-block;
  flex-shrink: 0;

  &.small {
    width: 0.6em;
    height: 0.6em;
    border-radius: 0.6em;
  }

  &.medium {
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }

  &.large {
    width: 34px;
    height: 34px;
    border-radius: 4px;
  }

  &.absolute {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    margin-left: 0.7em;
  }
}

.border {
  border: 1px solid colors-old.$icon-border-color;
}
</style>
