const styles = new Map<string, StyleInfo>();

interface StyleInfo {
  minFontSize: number;
  maxFontSize: number;
  lineHeightFactor: number;
  fontWeight: string;
  fontFamily: string;
}

export function getStyleInfo(elem: SVGElement | HTMLElement): StyleInfo {
  let measure = styles.get(elem.className);
  if (!measure) {
    const style = window.getComputedStyle(elem);
    const fontSize = elem.style.fontSize;
    elem.style.fontSize = "1px";
    const minFontSize = parseFloat(style.fontSize);
    elem.style.fontSize = "10000px";
    const maxFontSize = parseFloat(style.fontSize);
    elem.style.fontSize = fontSize;
    measure = {
      minFontSize,
      maxFontSize,
      lineHeightFactor:
        style.lineHeight === "normal"
          ? 1.2
          : parseFloat(style.lineHeight) / parseFloat(style.fontSize),
      fontWeight: style.fontWeight,
      fontFamily: style.fontFamily,
    };
    styles.set(elem.className, measure);
  }
  return measure;
}

export function clearStyles() {
  styles.clear();
}
