import { Directive } from "vue";

import { eventTarget } from "@/utils/dom/dom";

/**
 * Makes the element on the board scrollable by preventing the scroll event from bubbling up.
 *
 */
export function scrollableOnBoard(): Directive<HTMLElement> {
  function handleScroll(this: HTMLElement, event: Event) {
    event.stopPropagation();

    if (!isScrollable(eventTarget(event), this, event as WheelEvent)) {
      event.preventDefault();
    }
  }

  function handleArrowKeys(this: HTMLElement, event: KeyboardEvent) {
    const isArrowKey = [
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
    ].includes(event.key);

    if (isArrowKey) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  return {
    mounted(el) {
      el.addEventListener("scroll", handleScroll);
      el.addEventListener("wheel", handleScroll);
      el.addEventListener("keydown", handleArrowKeys);
    },

    unmounted(el) {
      el.removeEventListener("scroll", handleScroll);
      el.removeEventListener("wheel", handleScroll);
      el.removeEventListener("keydown", handleArrowKeys);
    },
  };
}

/**
 * Checks if an element is scrollable within a given maximum parent element.
 *
 * @param el - The element to check for scrollability.
 * @param maxParentElement - The maximum parent element within which to check for scrollability.
 * @param event - The wheel event that triggered the check.
 * @returns A boolean indicating whether the element is scrollable.
 */
const isScrollable = (
  el: HTMLElement | null,
  maxParentElement: HTMLElement,
  event: WheelEvent,
) => {
  let parent: HTMLElement | null = el;
  while (parent && parent !== maxParentElement) {
    if (parent.scrollHeight > parent.clientHeight) {
      const isScrollingDown = event.deltaY > 0;
      const isAtBottom =
        parent.scrollTop + parent.clientHeight >= parent.scrollHeight - 1;
      const isAtTop = parent.scrollTop < 1;
      if ((isScrollingDown && !isAtBottom) || (!isScrollingDown && !isAtTop)) {
        return true;
      }
    }
    parent = parent.parentElement;
  }
  return false;
};
