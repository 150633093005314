import { isBoolean, isInteger, isObject, isString } from "lodash-es";
import { defineStore } from "pinia";

import { sendSetting } from "@/composables/useEventBus";
import { StickyType } from "@/model/stickyType";
import { LocalStore } from "@/utils/LocalStore";

import { useBoardStore } from "./board";
import { useSessionStore } from "./session";

export const fonts = [
  "Mulish",
  "Caveat",
  "Natural",
  "Child",
  "Lefthand",
  "Bedtime",
] as const;

function isFont(name: unknown): name is (typeof fonts)[number] {
  return isString(name) && fonts.includes(name as any);
}

export type StickyNoteTextAlignment = "left" | "center" | "right";

export const textAlignmentOptions: Array<{
  value: StickyNoteTextAlignment;
  label: string;
}> = [
  { value: "left", label: /*$t*/ "settings.stickyNoteTextAlignment.left" },
  { value: "center", label: /*$t*/ "settings.stickyNoteTextAlignment.center" },
  { value: "right", label: /*$t*/ "settings.stickyNoteTextAlignment.right" },
];

function isAlignment(value: unknown): value is StickyNoteTextAlignment {
  return textAlignmentOptions.some((option) => option.value === value);
}

export interface ClientSettings {
  stickyFont: (typeof fonts)[number];
  wheelZoom: boolean;
  overload: boolean;
  permanentTeamLinks: boolean;
  permanentProgramLinks: boolean;
  initialLineupDistance: number;
  showStats: number;
  overviewScale: number;
  defaultStickyTypes: Record<string, string>;
  highContrastMode: boolean;
  panelWidths: Record<string, number>;
  textAlignment: StickyNoteTextAlignment;
}

export const useClientSettingsStore = defineStore("clientSettings", {
  state: () => loadSettings(),
  getters: {
    defaultStickyTypeId(state): string {
      return state.defaultStickyTypes[this.defaultStickyTypeKey];
    },
    defaultStickyTypeKey: () => {
      const board = useBoardStore().currentBoard();
      return (
        useSessionStore().session.current.id +
        "-" +
        board.type +
        "-" +
        (board.type === "flex" ? board.flexType.id : "")
      );
    },
    panelWidth(state) {
      return (name: string) => state.panelWidths[name];
    },
  },
  actions: {
    init() {
      this.$subscribe((mutation, state) => {
        saveSettings(state);
      });
    },
    loadSettings() {
      this.$patch(loadSettings());
    },
    toggleShowStats(level: number) {
      this.showStats = this.showStats !== level ? level : 0;
    },
    setDefaultStickyType(type?: StickyType) {
      if (type) {
        this.defaultStickyTypes[this.defaultStickyTypeKey] = type?.id;
      } else {
        delete this.defaultStickyTypes[this.defaultStickyTypeKey];
      }
    },
    setPanelWidth(name: string, width: number) {
      this.panelWidths[name] = width;
    },
  },
});

const settings: { [k in keyof ClientSettings]: LocalStore<any> } = {
  stickyFont: store("stickyFont", "Mulish", isFont),
  wheelZoom: store("wheelZoom", true, isBoolean),
  overload: store("overload", true, isBoolean),
  permanentTeamLinks: store("permanentTeamLinks", false, isBoolean),
  permanentProgramLinks: store("permanentProgramLinks", true, isBoolean),
  initialLineupDistance: store("initialLineupDistance", 110, isInteger),
  showStats: store("showStats", 0, isInteger),
  overviewScale: store("overviewScale", 6, isInteger),
  defaultStickyTypes: store("defaultStickyTypes", {}, isObject),
  // To meet accessibility requirements
  highContrastMode: store("highContrastMode", false, isBoolean),
  panelWidths: store("panelWidths", {}, isObject),
  textAlignment: store("textAlignment", "left", isAlignment),
};

function loadSettings(): ClientSettings {
  const res = {} as any;
  for (const prop in settings) {
    const key = prop as keyof ClientSettings;
    res[prop] = settings[key].load();
  }
  return res as ClientSettings;
}

function saveSettings(data: ClientSettings) {
  for (const prop in settings) {
    const key = prop as keyof ClientSettings;
    settings[key].save(data[key] as any);
  }
}

function store<T>(
  key: string,
  defaultVal: T,
  valid: (data: unknown) => boolean,
) {
  const store: LocalStore<T> = new LocalStore<T>(key, {
    sedes: {
      serialize: (value) => {
        sendSetting(key, value);
        return value;
      },
      deserialize: (data: unknown): T | undefined => {
        if (valid(data)) {
          return data as T;
        }
        store.save(defaultVal);
        return defaultVal;
      },
    },
  });
  return store;
}
